import { Component } from '@angular/core';

@Component({
  selector: 'app-profilo-risorse',
  templateUrl: './profilo-risorse.component.html',
  styleUrls: ['./profilo-risorse.component.scss']
})
export class ProfiloRisorseComponent {

}
