import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  currentUser: User | undefined = undefined;

  pages: Page[] = [
    { icon: 'home', name: 'Dashboard', path: 'app/dashboard', selected: false },
  ];

  version = 'v1.2.4';
  constructor(private _auth: AuthService, private router: Router) {
    this.currentUser = this._auth.currentUser.value;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects;

      this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);


      let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()) });
      if (el) el.selected = true;
    });
  }

  logout(event?: Event): void {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
        return;
    }
    event.preventDefault();
    this._auth.logout().subscribe((res) => {
      this._auth.currentUser.next(null);
      localStorage.removeItem('csrf');
      this.router.navigate(['/auth/login']);
    });
  }
}
