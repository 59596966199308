import { Component } from '@angular/core';

@Component({
  selector: 'app-opportunita-personali',
  templateUrl: './opportunita-personali.component.html',
  styleUrls: ['./opportunita-personali.component.scss']
})
export class OpportunitaPersonaliComponent {

}
