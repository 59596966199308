import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-section-page-mobile',
  templateUrl: './header-section-page-mobile.component.html',
  styleUrls: ['./header-section-page-mobile.component.scss']
})
export class HeaderSectionPageMobileComponent {
  // titolo sezione
  @Input() title: string = "Titolo";

  // descrizione sezione
  @Input() description: string = "Descrizione sezione";
}
