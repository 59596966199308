<!-- begin: Sidebar versione desktop -->
<div class="sidebar-container">
    <a class="logo" [routerLink]="'./dashboard'" href="javascript:void(0)">
        <img src="assets/img/sidebar_logo.svg" alt="Portale delle opportunità" />
        <h5 class="sitename m-0">Portale delle opportunità</h5>
    </a>
    <ng-container [ngTemplateOutlet]="menuSections"></ng-container>
</div>
<!-- end: Sidebar versione desktop -->

<!-- begin: Sidebar versione mobile -->
<nav class="navbar sidebar-container-mobile">
    <article class="sidebar-container-mobile__header d-flex flex-row justify-content-between align-items-center">
        <a class="logo" [routerLink]="'./dashboard'" href="javascript:void(0)">
            <img src="assets/img/sidebar_logo.svg" alt="Portale delle opportunità" />
            <h5 class="sitename-mobile">Portale delle opportunità</h5>
        </a>
        <a class="menu-cta" href="javascript:void(0)" (click)="toggleCollapsedForMobile($event)"
            (keypress)="toggleCollapsedForMobile($event)">
            <i class="icon-custom"
                [ngClass]="{'menu': isToggleCollapsedForMobile, 'close': !isToggleCollapsedForMobile}"></i>
        </a>
    </article>
    <article class="sidebar-container-mobile__menu" *ngIf="!isToggleCollapsedForMobile">
        <ng-container [ngTemplateOutlet]="menuSections"></ng-container>
    </article>
</nav>
<!-- end: Sidebar versione mobile -->

<ng-template #menuSections>
    <div *ngFor="let item of menuList" class="menu-item">
        <ng-container *ngIf="!item.isDropdown; else dropdownTemplate">
            <a *ngIf="item.admin == hide || item.admin == null" href="{{item.link}}"
                [ngClass]="(this.router.url == item.link || isIntoSublinkForAddActiveItem(item)) && this.openedMenu == null ? 'menu-link' : 'menu-link-disabled'"
                class="align-item-middle">
                <span *ngIf="item.name == 'Dashboard'" class="align-items-center d-flex gap-1"><img
                        [src]="'../../../assets/icons/dashboard-item-'  + ((this.router.url == item.link || isIntoSublinkForAddActiveItem(item)) && this.openedMenu == null ? 'active.svg' : 'disabled.svg') "
                        alt=""> {{item.name}}
                </span>

                <span *ngIf="item.name == 'Anagrafica'" class="align-items-center d-flex gap-1"><img
                        [src]="'../../../assets/icons/anagrafica-item-'  + ((this.router.url == item.link || isIntoSublinkForAddActiveItem(item)) && this.openedMenu == null ? 'active.svg' : 'disabled.svg') "
                        alt=""> {{item.name}}
                </span>
            </a>
        </ng-container>
        <ng-template #dropdownTemplate>
            <div *ngIf="!hide">
                <a class="justify-content-between"
                    [href]="(item?.dropdownItems?.[0]?.link) ? item.dropdownItems?.[0]?.link : null"
                    (click)="toggleDropdown(item.name)" [class.active]="isOpen(item)"
                    [ngClass]="isOpen(item) ? 'menu-link' : 'menu-link-disabled'">
                    <span class=" align-items-center d-flex gap-1"><img
                            [src]="'../../../assets/icons/user-circle-' + (isOpen(item) ? 'active.svg' : 'disabled.svg')"
                            alt=""> {{item.name}}</span>
                    <i class="pb-1 icon-custom"
                        [ngClass]="{'menu-arrow-up': isOpen(item), 'menu-arrow-down': !isOpen(item)}"></i>
                </a>
                <div *ngIf="isOpen(item)" class="menu-item dropdown-content">
                    <a *ngFor="let subItem of item.dropdownItems" href="{{subItem.link}}"
                        [ngClass]="this.router.url == subItem.link || isIntoSublinkForAddActiveItem(subItem) ? 'sub-menu-link' : 'sub-menu-link-disabled'">
                        {{ subItem.name }}
                    </a>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="logout-container">
        <a (click)="logout()"><i class="fa-solid fa-arrow-right-from-bracket"></i>
            Logout</a>
    </div>
</ng-template>