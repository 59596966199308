<div class="opportunita-personali-table px-4">
    <div class="row">
        <div class="px-0">
            <div class="opportunita-personali-table__header">
                <button class="go-back label gap-1 align-items-center d-flex" (click)="goTo('dashboard', $event)"
                    (keydown)="goTo('dashboard', $event)">
                    <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna alla dashboard
                </button>
            </div>
            <p class="title mb-3">Le tue opportunità</p>
            <form class="mb-4" [formGroup]="searchForm">
                <div class="row align-items-center">
                    <div class="col-md-4 d-flex">
                        <nz-input-group [nzPrefix]="prefixIconSearch" class="w-100">
                            <input type="text" nz-input placeholder="Cerca per nome..." formControlName="search" />
                        </nz-input-group>
                        <ng-template #prefixIconSearch>
                            <span nz-icon nzType="search"></span>
                        </ng-template>
                    </div>
                    <div class="col-md-3 d-flex">
                        <nz-select nzShowSearch nzAllowClear="true" nzPlaceHolder="Tipologia"
                            formControlName="tipologia" class="w-100">
                            <nz-option *ngFor="let typology of typologies" [nzLabel]="typology.label"
                                [nzValue]="typology.value"></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-md-3 d-flex">
                        <nz-select nzShowSearch nzAllowClear="true" nzPlaceHolder="Avanzamento"
                            formControlName="progresso" class="w-100">
                            <nz-option *ngFor="let prog of progress" [nzLabel]="prog.label"
                                [nzValue]="prog.value"></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-md-2">
                        <nz-select nzShowSearch nzAllowClear="true" nzPlaceHolder="Stato" formControlName="stato"
                            class="w-100">
                            <nz-option *ngFor="let status of status" [nzLabel]="status.label"
                                [nzValue]="status.value"></nz-option>
                        </nz-select>
                    </div>
                </div>
            </form>
        </div>
        <div class="approval-legend pb-2">
            <small class="label">
                <img class="icon-size" src="assets/icons/approved.svg" alt="">
                Approvata
            </small>
            <small class="label">
                <img class="icon-size2" src="assets/icons/rejected.svg" alt="">
                Non approvata
            </small>
        </div>
        <drw-data-table class="opportunity-table" [displayedColumns]="displayedColumns" [endpointDataSource]="endpoint"
            [actions]="true" [actionsButton]="actionsButton" [isFilter]="false" [endpointPdf]="endpointPdf"
            [search]="search" [enableNavigation]="true">
        </drw-data-table>
    </div>
</div>