import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CallsService } from "../../shared/services/calls.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ParticipationService } from 'src/app/shared/services/participation.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalParticipationNoteComponent } from 'src/app/eht-dashboard/modal-participation-note/modal-participation-note.component';
import { ModalShowDocumentationComponent } from 'src/app/components/modals/modal-show-documentation/modal-show-documentation.component';

@Component({
    selector: 'app-dettaglio-opportunita',
    templateUrl: './dettaglio-opportunita.component.html',
    styleUrls: ['./dettaglio-opportunita.component.scss']
})
export class DettaglioOpportunitaComponent implements OnInit, OnDestroy {
    opportunita: any;
    certificazioniDisplayedColumns: Array<string>;
    competenzeDisplayedColumns: Array<string>;
    risposteDisplayedColumns: Array<string>;
    callId: any = null;
    role_id: any = null;
    unsubscribe: Subject<void> = new Subject();
    actionsButton = { download: false, info: false, modify: false, trash: false, accept: true, refuse: true, upload: false }
    search = '';
    visualizzazione_eht: boolean = false;
    participationFlag: boolean = false;

    participationInfo!: { flag: boolean; is_accepted: number; nota: string };
    call_id: any = null;

    /*
    Colonne della datatable certificazioni
    */
    private risposteDataTableColumns: string[] = ['Azienda candidata', 'Data risposta', 'Orario risposta', 'Allegati', 'azioni'];
    private risposteDataTableColumnsForMobile: string[] = ['Azienda candidata', 'azioni'];

    constructor(
        private route: ActivatedRoute,
        private callsService: CallsService,
        private fileService: FileService,
        private authService: AuthService,
        private commonService: CommonService,
        protected router: Router,
        private modalService: NzModalService,
        private participationService: ParticipationService,
    ) {
        this.opportunita = {
            name: '',
            codice_iniziativa: '',
            start_date: new Date(),
            end_date: new Date(),
            upload_end_date: new Date(),
            upload_start_date: new Date(),
            tipologia: '',
            stato: '',
            info_partecipation_call: '',
            description: '',
            certifications: [],
            skills: [],
            responses: [],
            risposte_opportunita: [],
            files: [],
            mandatory_company_certifications: '',
            is_enrollable: null
        };

        this.certificazioniDisplayedColumns = ['Nome certificazione'];
        this.competenzeDisplayedColumns = ['Nome competenza'];
        this.risposteDisplayedColumns = [''];
        this.callId = this.route.snapshot.paramMap.get('opportunita_id');
    }

    ngOnInit(): void {
        this.authService.me()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (res: any) => {

                    if (res.role.role_id == 1) {
                        this.visualizzazione_eht = true;
                    }
                }
            });
        this.call_id = this.route.snapshot.paramMap.get('opportunita_id');
        this.getCall(this.call_id);
        this.role_id = this.authService.currentUser.value.roles[0].id;

        // controlla se l'utente si è già iscritto a questa call
        this.checkParticipation();

        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.risposteDisplayedColumns) !== JSON.stringify(this.risposteDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.risposteDisplayedColumns = JSON.parse(JSON.stringify(this.risposteDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.risposteDisplayedColumns) !== JSON.stringify(this.risposteDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.risposteDisplayedColumns = JSON.parse(JSON.stringify(this.risposteDataTableColumnsForMobile));
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getCall(id: string): void {
        this.callsService.showCall(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (res: any) => {
                    this.opportunita = res.data;

                    // DA RIMUOVERE: questa assegnazione rimuove il blocco delle partecipazioni alle aziende a cui mancano i documenti obbligatori
                    this.opportunita.mandatory_company_certifications = '';

                    switch (res.data.tipologia) {
                        case 0: {
                            this.opportunita.tipologia = 'Commessa'
                            break;
                        }
                        case 1: {
                            this.opportunita.tipologia = 'Ricerca e Sviluppo'
                            break;
                        }
                        case 2: {
                            this.opportunita.tipologia = 'Gara'
                            break;
                        }
                        default: {
                            this.opportunita.tipologia = 'Non definita'
                        }
                    }
                }
            });
    }

    participate(): void {
        this.callsService.storeParticipation({ call_id: this.callId })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (res: any): void => {
                    this.checkParticipation();
                    this.commonService.openToastAlert("Partecipazioine registrata", true, {
                        position: 'top-end',
                        icon: 'success',
                        iconColor: '#0dd30d',
                    });
                    this.ngOnInit();

                },
                error: (err: HttpErrorResponse) => {
                    this.commonService.openToastAlert(err?.message, true, {
                        position: 'top-end',
                        icon: 'error',
                        iconColor: '#f03506',
                    });
                }
            });
    }

    checkParticipation(): void {
        this.callsService.getParticipation(this.callId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
                next: (res: any): void => {
                    this.participationInfo = res?.data;
                    this.participationFlag = this.participationInfo?.flag || false;
                }
            });
    }

    goTo(uri: string, event: Event): void {
        if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
            event.preventDefault();
            this.router.navigate([uri]);
        }
    }

    openLink(row: any) {
        this.fileService.downloadFile(row.uid)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: (response) => {
                // Crea un URL per il blob
                const blob = new Blob([response], { type: response.type });
                const url = window.URL.createObjectURL(blob);

                // Crea un link temporaneo
                const a = document.createElement('a');
                a.href = url;

                // Estrai il nome del file dal backend o usa un nome predefinito
                let filename = row.name;
                a.download = filename;

                // Aggiungi e clicca il link per avviare il download
                document.body.appendChild(a);
                a.click();

                // Rimuovi il link e libera l'URL
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            },
            error: (error) => this.handleError(error)
          });
      }
    
      private handleError(error: any) {
        this.commonService.openToastAlert('Errore durante il download del file', true, {
          position: 'top-end',
          icon: 'error',
          iconColor: '#f03506',
        });
        console.error('Download failed:', error);
      }

    manageParticipation(event: any) {

        this.participationService.manageParticipation(event).subscribe({
            next: (res) => {
                this.getCall(this.call_id)
                this.commonService.openToastAlert(res.message, true, {
                    position: 'top-end',
                    icon: 'success',
                    iconColor: '#0dd30d',
                });

            },
            error: (err) => {
                this.getCall(this.call_id)
                this.commonService.openToastAlert(err.error.message, true, {
                    position: 'top-end',
                    icon: 'error',
                    iconColor: '#f03506',
                });
            }
        })

    }

    openNoteModal(element: any) {
        const modal = this.modalService.create({
            nzContent: ModalParticipationNoteComponent,
            nzData: element,
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '640px'
        });

        modal.afterClose.subscribe(result => {
            // Qui puoi gestire il valore di ritorno
            if (result === 0) {
                this.manageParticipation({ data: element, action: result })
            }
        });
    }

    openShowDocModal(element: any) {
        element.callId = this.callId;
        this.modalService.create({
            nzContent: ModalShowDocumentationComponent,
            nzData: element,
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '814px'
        });
    }
}
