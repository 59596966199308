import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from '../models/formatted-response';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const CallURL = `${environment.ApiURL}/calls/`;

@Injectable({
  providedIn: 'root'
})
export class CallsService {

  constructor(private http: HttpClient) { }

  getCalls(params?: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(CallURL + `getCall`, { params });
  }

  yourCall(): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(CallURL + `yourCall`);
  }

  storeCall(params: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(CallURL + `storeCall`, { params });
  }

  showCall(id: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(CallURL + `show/${id}`,);
  }

  updateCall(id: any, params: any): Observable<FormattedResponse<any>> {
    return this.http.patch<FormattedResponse<any>>(CallURL + `update/${id}`, params);
  }

  storeParticipation(params: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(CallURL + `participation`, params);
  }

  getParticipation(id: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(CallURL + `participation/${id}`);
  }

  deleteCall(id: any): Observable<FormattedResponse<any>> {
    return this.http.delete<FormattedResponse<any>>(CallURL + `deleteCall/${id}`);
  }

  callMatch(params: any, id: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(CallURL + `matching/${id}`, params);
  }

}
