import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-legenda-competenze',
  templateUrl: './legenda-competenze.component.html',
  styleUrls: ['./legenda-competenze.component.scss']
})
export class LegendaCompetenzeComponent {
  // mostra/nascondi il logo hig nell'alert (di default è nascosto)
  @Input() showHigLogo: boolean = false;
}
