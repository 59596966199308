import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpErrorResponse } from "@angular/common/http";
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { Settore } from 'src/app/shared/models/settore';
import { CertificationService } from 'src/app/shared/services/certification.service';
import { SkillService } from 'src/app/shared/services/skill.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-sezione-anagrafica',
    templateUrl: './sezione-anagrafica.component.html',
    styleUrls: ['./sezione-anagrafica.component.scss']
})
export class SezioneAnagraficaComponent {
    isAdmin: boolean = false;
    searchForm!: FormGroup;
    dynamicClass: string;
    displayedColumns: string[] = [];
    actionsButton = { download: false, info: false, modify: true, trash: true, accept: false, refuse: false, upload: false }
    displayedFilters = [
    ]
    endpoint: string = "getAziendeDatatable"
    role_id: any;
    unsubscribe: Subject<void> = new Subject();
    search: any;
    minNDipendenti: any = null;
    maxNDipendenti: any = null;
    settori: BehaviorSubject<Settore[]>;
    settori_selezionati: any[] = [];
    provinces: BehaviorSubject<any[]>;
    provinces_selezionati: any[] = [];
    documents: BehaviorSubject<any[]>;
    documents_selezionati: any[] = [];
    certifications: BehaviorSubject<any[]>;
    certifications_selezionati: any[] = [];
    skills: BehaviorSubject<any[]>;
    skills_selezionati: any[] = [];
    filtri_avanzati: boolean = false;
    infinity: number = Infinity;

    /*
    Colonne della datatable aziende
    */
    private companiesDataTableColumns: string[] = ['Ragione Sociale', 'Partita Iva', 'Numero Dipendenti', 'azioni'];
    private companiesDataTableColumnsForMobile: string[] = ['Ragione Sociale', 'azioni'];

    constructor(
        public authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private aziendeService: CompaniesService,
        private certificationsService: CertificationService,
        private skillService: SkillService,
        private commonService: CommonService
    ) {
        this.searchForm = this.fb.group({
            search: [null],
            minNDipendenti: [Number(this.minNDipendenti)],
            maxNDipendenti: [Number(this.maxNDipendenti)],
            provinces: [null],
            settori: [null],
            documents: [null],
            certifications: [null],
            skills: [null],
            skillLow: [null],
            skillMid: [null],
            skillHigh: [null]
        });

        this.isAdmin = (this.authService.currentUser.value.roles['0'].id == 1);
        this.dynamicClass = this.isAdmin ? 'col-8' : 'col-12';
        this.role_id = this.authService.currentUser.value.roles[0].id;
        this.settori = new BehaviorSubject<Settore[]>([]);
        this.provinces = new BehaviorSubject<any[]>([]);
        this.documents = new BehaviorSubject<any[]>([]);
        this.certifications = new BehaviorSubject<any[]>([]);
        this.skills = new BehaviorSubject<any[]>([]);

        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.companiesDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.companiesDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.companiesDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.companiesDataTableColumnsForMobile));
                }
            });
    }

    ngOnInit() {
        this.search = {};
        this.searchForm.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
        )
            .subscribe((values: any) => {
                this.search = values;
            });

        this.loadSettori();
        this.loadProvinces();
        this.loadDocuments();
        this.loadCertifications();
        this.loadSkills();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    goTo(uri: string, event?: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        this.router.navigate([uri]);
    }

    onEditedElement(value: any) {
        this.router.navigate(['anagrafica/azienda/' + value['id']]);
    }

    onDeleteElement(value: any) {
        this.aziendeService.destroyAzienda(value).subscribe({
            next: (res: any): void => {
                this.commonService.openToastAlert(res?.message, true, {
                    position: 'top-end',
                    icon: 'success',
                    iconColor: '#0dd30d',
                });
                this.aziendeService.updateDataTable();
            },
            error: (err: HttpErrorResponse) => {
                this.commonService.openToastAlert(err?.message, true, {
                    position: 'top-end',
                    icon: 'error',
                    iconColor: '#ff0000',
                });

            }
        })
    }

    /**
     * Non permette a minNDipendenti di superare maxNDipendenti
     * @param number current value of the minimum number of employees
     *  */
    onValue1Change(value: number): void {
        if (this.searchForm.controls['maxNDipendenti'].value != null && this.searchForm.controls['maxNDipendenti'].value != '' && value > this.searchForm.controls['maxNDipendenti'].value) {
            this.searchForm.controls['minNDipendenti'].patchValue(Number(this.searchForm.controls['maxNDipendenti'].value));
        }
    }
    /**
     * Non permette a maxNDipendenti di scendere sotto minNDipendenti
     * @param number current value of the maximum number of employees
     *  */
    onValue2Change(value: number): void {
        if (this.searchForm.controls['minNDipendenti'].value && this.searchForm.controls['minNDipendenti'].value != '') {
            if (value < this.searchForm.controls['minNDipendenti'].value) {
                this.searchForm.controls['maxNDipendenti'].patchValue(Number(this.searchForm.controls['minNDipendenti'].value));
            }
        } else if (value && value < 1) {
            this.searchForm.controls['maxNDipendenti'].patchValue(1);
        }
    }

    /**
     * Casts settori as FormControl
     * @return FormControl
     */
    get settoriControl(): FormControl {
        return this.searchForm.get('settori') as FormControl;
    }

    /**
     * Casts provinces as FormControl
     * @return FormControl
     */
    get provincesControl(): FormControl {
        return this.searchForm.get('provinces') as FormControl;
    }

    /**
     * Casts documents as FormControl
     * @return FormControl
     */
    get documentsControl(): FormControl {
        return this.searchForm.get('documents') as FormControl;
    }

    /**
     * Casts certifications as FormControl
     * @return FormControl
     */
    get certificationsControl(): FormControl {
        return this.searchForm.get('certifications') as FormControl;
    }

    /**
     * Casts skills as FormControl
     * @return FormControl
     */
    get skillsControl(): FormControl {
        return this.searchForm.get('skills') as FormControl;
    }

    /**
     * Load all settori from the database
     * @return void
     *  */
    private loadSettori() {
        this.aziendeService.getSettori().subscribe({
            next: (res: any): void => {
                this.settori.next(res.data);
            }
        });
    }

    /**
     * Load all provinces from the database
     * @return void
     *  */
    private loadProvinces(): void {
        this.aziendeService.getProvinces().subscribe({
            next: (res: any): void => {
                let aux: any[] = [];
                res.data.forEach((element: string) => {
                    aux.push({ id: element, name: element });
                });
                this.provinces.next(aux);
            }
        });
    }

    /**
     * Load all documents from the database
     * @return void
     *  */
    private loadDocuments() {
        this.certificationsService.getAvailableCertification().subscribe({
            next: (res: any): void => {
                let aux = res.data;
                aux.forEach((element: any) => {
                    element.name = element.Nome;
                });
                this.documents.next(aux);
            }
        });
    }

    /**
     * Load all certifications from the database
     * @return void
     *  */
    private loadCertifications() {
        this.certificationsService.getAvailableCertificationResources().subscribe({
            next: (res: any): void => {
                let aux = res.data;
                aux.forEach((element: any) => {
                    element.name = element.Nome;
                });
                this.certifications.next(aux);
            }
        });
    }

    /**
     * Load all skills from the database
     * @return void
     *  */
    private loadSkills() {
        this.skillService.getStaticCompetenze().subscribe({
            next: (res: any): void => {
                this.skills.next(res.result);
            }
        });
    }

    /**
     * @param id id of the element to remove from data
     * @param event event that trigger the callback
     * @param data array to update
     * @return array
     */
    changeSelection(id: string, event: Event, data: any) {
        event.preventDefault();
        return data.filter((selectedId: string) => selectedId !== id);
    }

    /**
     * @param data array to check
     * @param updatedData array to update
     * @return array
     */
    getSelection(data: any, updatedData: any) {
        return data.value.filter((selected: any) => updatedData.includes(selected.id))
            .sort((a: any, b: any) => (a.Nome || a.name).localeCompare((b.Nome || b.name)));
    }

}
