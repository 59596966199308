import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<any> = new BehaviorSubject(null);

  generateVerificationCode(email: string) {
    return this.http.post<FormattedResponse<any>>(`${environment.ApiURL}/auth/mail/code/generate`, { email: email });
  }

  login(credentials: Credentials) {
    return this.http.post<any>(`${environment.ApiURL}/login`, credentials);
  }

  recover(mail: any) {
    return this.http.post<any>(`${environment.ApiURL}/recover`, mail);
  }

  modifyPassword(passwords: any) {
    return this.http.post<any>(`${environment.ApiURL}/modify`, passwords);
  }

  logout() {
    return this.http.get<FormattedResponse<any>>(`${environment.ApiURL}/logout`);
  }

  me() {
    return this.http.get<any>(`${environment.ApiURL}/me`);
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('token');

    return user ? true : false;
  }

  generateCode(email: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.ApiURL}/public/recover/generateCode`, { params: { email } });
  }

  confirmCode(email: string, code: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.ApiURL}/public/recover/confirmCode`, { params: { email, code } });
  }

  passwordRecovery(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.ApiURL}/recover/password`, credentials);
  }

  getUserEmail(): string {
    return localStorage.getItem('email') ?? localStorage.getItem('emailPasswordRecovery')!;
  }

  setUserEmail(email: string) {
    localStorage.setItem('emailPasswordRecovery', email);
  }

  getCurrentUser(user: User) {
    this.currentUser.next(user);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.ApiURL}/me`, { context: context });
  }

  getRole(params: any): Observable<any> {
    return this.http.get<any>(`${environment.ApiURL}/getRole`, { params });
  }
}
