import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule } from './components/ng-zorro-antd/ng-zorro-antd.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { InterceptorService } from "./shared/helpers/interceptor.service";
import { ComponentsModule } from './components/components.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthService } from './shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { LoginComponent } from './auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfiloAziendaComponent } from './profilo/profilo-azienda/profilo-azienda.component';
import { ProfiloRisorseComponent } from './profilo/profilo-risorse/profilo-risorse.component';
import { CompetenzeComponent } from './profilo/competenze/competenze.component';
import { CertificazioniComponent } from './profilo/certificazioni/certificazioni.component';
import { ModalCertAziendaComponent } from './profilo/certificazioni/modal-cert-azienda/modal-cert-azienda.component';
import { DatiGeneraliComponent } from './profilo/dati-generali/dati-generali.component';
import { FileTwoTone } from '@ant-design/icons-angular/icons';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { DashboardOpportunitaComponent } from './dashboard/dashboard-opportunita/dashboard-opportunita.component';
import { DashboardAsideComponent } from './dashboard/dashboard-aside/dashboard-aside.component';
import { ModalCertRisorseComponent } from './profilo/certificazioni/modal-cert-risorse/modal-cert-risorse.component';
import { ModalCompetenzeComponent } from './profilo/competenze/modal-competenze/modal-competenze.component';
import { DettaglioOpportunitaComponent } from './dashboard/dettaglio-opportunita/dettaglio-opportunita.component';
import { InsertCallComponent } from './eht-dashboard/insert-call/insert-call.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OpportunitaPersonaliComponent } from './profilo/opportunita-personali/opportunita-personali.component';
import { OpportunitaPersonaliTableComponent } from './profilo/opportunita-personali/opportunita-personali-table/opportunita-personali-table.component';
import { OpportunitaPersonaliAsideComponent } from './profilo/opportunita-personali/opportunita-personali-aside/opportunita-personali-aside.component';
import { RecoveryPswComponent } from './auth/recovery/recovery-psw/recovery-psw.component';
import { HeaderSectionPageMobileComponent } from './shared/components/mobile/header-section-page-mobile/header-section-page-mobile.component';
import { HeaderSectionPageComponent } from './shared/components/header-section-page/header-section-page.component';
import { LegendaCompetenzeComponent } from './profilo/competenze/legenda-competenze/legenda-competenze.component';
import { SezioneAnagraficaComponent } from './eht-anagrafica/sezione-anagrafica/sezione-anagrafica.component';
import { InsertAziendaComponent } from './eht-anagrafica/insert-azienda/insert-azienda.component';
import { ProdottiComponent } from './profilo/prodotti/prodotti.component';
import { ProdottiDettaglioComponent } from './profilo/prodotti-dettaglio/prodotti-dettaglio.component';
import { ModalParticipationNoteComponent } from './eht-dashboard/modal-participation-note/modal-participation-note.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DocumentazioneAziendaComponent } from './dashboard/documentazione-azienda/documentazione-azienda.component';

const icons = [FileTwoTone];

export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeAppFactory(auth: AuthService, router: Router, aRoute: ActivatedRoute): () => Observable<any> {
  // Get logged user if exists
  return () => auth.getUser(true)
    .pipe(
      tap(response => {
        auth.currentUser.next(response);
      }),
      catchError((error: any) => {
        if (!window.location?.toString()?.includes('/login') && error?.status === 401) {
          auth.currentUser.next(null);
          router.navigate(['/login']);
        }
        return of(error);
      }),
    );
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ProfiloAziendaComponent,
    ProfiloRisorseComponent,
    CompetenzeComponent,
    CertificazioniComponent,
    ModalCertAziendaComponent,
    DatiGeneraliComponent,
    DashboardOpportunitaComponent,
    DashboardAsideComponent,
    ModalCertRisorseComponent,
    ModalCompetenzeComponent,
    DettaglioOpportunitaComponent,
    InsertCallComponent,
    OpportunitaPersonaliComponent,
    OpportunitaPersonaliTableComponent,
    OpportunitaPersonaliAsideComponent,
    RecoveryPswComponent,
    HeaderSectionPageMobileComponent,
    HeaderSectionPageComponent,
    LegendaCompetenzeComponent,
    SezioneAnagraficaComponent,
    InsertAziendaComponent,
    ProdottiComponent,
    ProdottiDettaglioComponent,
    ModalParticipationNoteComponent,
    DocumentazioneAziendaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NzTabsModule,
    FormsModule,
    AngularEditorModule,
    NgZorroAntdModule,
    MatProgressSpinnerModule,
    ComponentsModule,
    SweetAlert2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, Router, ActivatedRoute],
      multi: true,
    },
    { provide: NZ_ICONS, useValue: icons }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
