<div class="container-fluid prodotti-dettaglio">
    <div class="prodotti-dettaglio__header">
        <button class="go-back label gap-1 align-items-center d-flex" (click)="goTo($event)" (keydown)="goTo($event)">
            <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna indietro
        </button>
    </div>

    <form class="prodotti-dettaglio__form-content" [formGroup]="dataForm">

        <div class="row align-items-center mb-3 prodotti__header">
            <!-- Testo -->
            <div class="col-md-9 header-box-text">
                <p class="prodotto-content-header">{{pageTitle}}</p>
            </div>

            <!-- Pulsante -->
            <div class="col-md-3 d-flex justify-content-end header-box-cta" *ngIf="visualizzazione_eht">
                <button (click)="downloadZip($event)" type="button" style="font-size:14px" class="btn btn-outline-primary"><img src="/assets/icons/download.svg" alt=""> Scarica Allegati</button>
            </div>
        </div>




        <p class="campi-obbligatori" *ngIf="!visualizzazione_eht">* Campi obbligatori</p>

        <div class="row mb-4 wrapper-nome-prodotto element-wrapper">
            <label class="input-label" for="name">
                Nome prodotto <span *ngIf="!visualizzazione_eht">*</span>
            </label>

            <input nz-input placeholder="Inserisci qui" type="text" formControlName="name" *ngIf="!visualizzazione_eht"
                [ngClass]="{'input-error': !dataForm.controls['name'].valid && dataForm.controls['name'].touched}"
                style="border-radius: 8px;" />
            <input nz-input [placeholder]="this.dataForm.controls['name'].value" type="text" *ngIf="visualizzazione_eht"
                [ngClass]="{'input-error': !dataForm.controls['name'].valid && dataForm.controls['name'].touched}"
                style="border-radius: 8px;" [disabled]="true" />
            <div class="text-sm text-danger" style="font-size: 11px;"
                *ngIf="dataForm.controls['name'].touched && dataForm.controls['name'].hasError('required')">
                {{"Il nome del prodotto è richiesto" | translate}}
            </div>
        </div>
        <div class="row mb-4 element-wrapper">
            <app-multiselect
                [disable]="visualizzazione_eht"
                [data]="settori.value"
                [dataControl]="settoriControl"
                [(selected_data)]="settori_selezionati"
                [icon]="'icon_sector.svg'"
                [label]="'Settori di interesse'"
                [placeholder]="'Inserisci qui'"
            ></app-multiselect>
        </div>

        <div class="mb-4" >
            <label class="input-label" for="name">
                Descrizione del prodotto <span *ngIf="!visualizzazione_eht">*</span>
            </label>
            <angular-editor formControlName="description" [config]="editorConfig" [hidden]="visualizzazione_eht"
                style="border-radius: 8px;"></angular-editor>
            <div class="text-sm text-danger" style="font-size: 11px;" [hidden]="visualizzazione_eht"
                *ngIf="dataForm.controls['description'].touched && dataForm.controls['description'].hasError('required')">
                {{"La descrizione è richiesta" | translate}}
            </div>
            <div [hidden]="!visualizzazione_eht" [innerHtml]="this.dataForm.controls['description'].value"></div>
        </div>


        <nz-upload nzType="drag" nzListType="picture" [nzFileList]="fileList" nzLimit="0" [nzMultiple]="true" [nzAccept]="nzAccept" [hidden]="visualizzazione_eht"
            [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="{ showRemoveIcon: true, showPreviewIcon: false }"
            (nzChange)="handleChange($event)" style="border-radius: 8px;">
            <img class="mb-3 cloud-img" src="assets/icons/cloud-add.svg" alt="cloud">
            <div class="ant-upload-text title upload-title">Scegli o trascina i file qui</div>
            <div class="ant-upload-hint upload-subtitle">Puoi caricare soltanto file
                <ng-container *ngFor="let ext of fileExtensions.value">{{ext['label']}}, </ng-container>
                non superiore a 50 MB
             </div>
            <button class="btn btn-primary choose-file-btn m-auto">Scegli un file</button>
        </nz-upload>
    </form>

    <div class="prodotti-dettaglio__cta-box" [hidden]="visualizzazione_eht">
        <button [disabled]="dataForm.invalid" type="submit" (click)="save()" class="btn btn-primary">Salva</button>
    </div>
</div>
