<div class="opportunita-personali">
    <div nz-row [nzGutter]="24" class="px-0">
        <app-opportunita-personali-table nz-col [nzSpan]="24" class="px-0"></app-opportunita-personali-table>
    </div>
    <!-- nascosto finchè non verrà abilitato in un secondo momento -->
    <div nz-row [nzGutter]="24" class="px-0" *ngIf="false">
        <app-opportunita-personali-table nz-col [nzSpan]="17" class="px-0"></app-opportunita-personali-table>
        <app-opportunita-personali-aside nz-col [nzSpan]="7" class="px-0"></app-opportunita-personali-aside>
    </div>
</div>
