import { Component, Inject, OnInit } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, lastValueFrom } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-modal-show-documentation',
  templateUrl: './modal-show-documentation.component.html',
  styleUrls: ['./modal-show-documentation.component.scss']
})
export class ModalShowDocumentationComponent implements OnInit {
  displayedColumns = ['Documentazione', 'Tipologia', 'Allegati'];
  endpointPdf: string = 'pdf'
  endpoint: string = "documentazione/getDocumentazione"
  params: any = [];
  search: any;
  unsubscribe: Subject<void> = new Subject();
  isLoading: boolean = false;

  constructor(
    private modal: NzModalRef,
    private fileService: FileService,
    @Inject(NZ_MODAL_DATA) public data: any,) { }

  ngOnInit(): void {
    //preparo i parametri da passare alla datatable per recuperare la documentazione
    this.params.push({ 'call_id': this.data.callId, 'azienda_id': this.data.company_id });
  }

  handleCancel(): void {
    this.modal.destroy();
  }

  downloadAllFiles(event?: Event): void {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.isLoading = true;
    lastValueFrom(this.fileService.downloadAllFiles({ call_id: this.data.callId }, { azienda_id: this.data.company_id }))
      .then((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'files.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error: any) => {
        console.error('Download error:', error);
      })
      .finally(() => this.isLoading = false);
  }
}
