<div class="container-fluid pb-0 d-flex flex-column">
  <div class="row">
    <!-- Sezione "Le tue opportunità" -->
    <div class="col-12 mb-1">
      <nz-card class="card-all">
        <div class="card-body">
          <div class="justify-content-between align-items-center d-flex mb-0">
            <h5 class="aside-title all-opp">Le tue opportunità</h5>
            <h5 class="small all-opp" style="cursor: pointer;" *ngIf="opportunities?.length != 0" style="text-decoration: underline;" (click)="goTo('le-tue-opportunita', null, $event)" (keydown)="goTo('le-tue-opportunita', null, $event)">Visualizza tutte</h5>
          </div>
          <div class="opportunities-container" *ngIf="opportunities?.length != 0; else noOpp">
            <ng-container
              *ngFor="let opportunity of opportunities?.slice(currentOpportunityIndex, currentOpportunityIndex + 2)">
              <nz-card class="small mt-3 container-fluid no-padding">
                <h5 class="aside-title">{{opportunity.name}}</h5>
                <div class="d-flex justify-content-between align-items-center flex-wrap gap-1">

                  <!-- begin IN ATTESA DI MODIFICA BE (rimuovere ngIf non appena si deve implementare) -->
                  <div *ngIf="false" [ngClass]="opportunity.type == 'Approvata' ? 'approval' : 'waiting'" class="p-1 d-flex align-items-center">
                    <img [src]="opportunity.type == 'Approvata' ? 'assets/icons/approved.svg' : 'assets/icons/waiting.svg'" alt="">
                    <span class="mx-1 mb-0" style="white-space: normal;">{{opportunity.type}}</span>
                  </div>
                  <!-- end IN ATTESA DI MODIFICA BE (rimuovere ngIf non appena si deve implementare) -->

                  <!-- begin ELIMINARE SOPRA DOPO MODIFICA -->
                  <div class="p-1 d-flex align-items-center waiting">
                    <img [src]="'assets/icons/waiting.svg'" alt="">
                    <span class="mx-1 mb-0" style="white-space: normal;">In attesa</span>
                  </div>
                  <!-- end ELIMINARE SOPRA DOPO MODIFICA -->

                  <button class="personal-show ml-auto" nz-button nzSize="small" nzType="default"
                    (click)="goTo('opportunita/', opportunity.id)">
                    <img src="assets/icons/blue_eye.svg" alt="">
                  </button>
                </div>
                <div class="card-details">
                  <div class="d-flex flex-wrap">
                    <img [src]="opportunity.stato_image" alt="">
                    <p class="card-date status">{{opportunity.stato}}</p>
                    <p class="card-date">{{opportunity.start_date | date:'dd/MM/yyyy'}} - {{opportunity.end_date |
                      date:'dd/MM/yyyy'}}</p>
                  </div>
                  <p class="card-category m-0">{{opportunity.tipologia}}</p>
                </div>
              </nz-card>
            </ng-container>
          </div>
        </div>
        <ng-template #noOpp class="no-content">
          <h5 class="aside-title all-opp m-auto no-content"> Non partecipi a nessuna opportunità</h5>
        </ng-template>
        <div *ngIf="opportunities?.length != 0" class="navigation-arrows justify-content-end gap-3">
          <button nz-button nzType="default" (click)="prev()" [disabled]="currentOpportunityIndex === 0"
            [ngStyle]="{'background-color': (currentOpportunityIndex === 0) ? '#360E8B' : '', 'color': (currentOpportunityIndex !== 0) ? '#360E8B' : ''}">&lt;</button>
          <button nz-button nzType="default" (click)="next()"
            [disabled]="currentOpportunityIndex >= opportunities?.length - 2"
            [ngStyle]="{'background-color': (currentOpportunityIndex >= opportunities?.length - 2) ? '#360E8B' : '', 'color': (currentOpportunityIndex < opportunities?.length - 2) ? '#360E8B' : ''}">&gt;</button>
        </div>
      </nz-card>
    </div>
  </div>

  <div class="row">
    <!-- Sezione "Nuove opportunità" -->
    <div class="col-12">
      <nz-card class="card-new mb-0">
        <div class="card-body">
          <h5 class="aside-title new-opp mb-0">Nuove opportunità</h5>
          <div class="opportunities-container" *ngIf="newOpportunities?.length != 0; else noNewOpp">
            <ng-container
              *ngFor="let opportunity of newOpportunities?.slice(currentNewOpportunityIndex, currentNewOpportunityIndex + 1)">
              <nz-card class="small mt-3 container-fluid no-padding">
                <h5 class="aside-title">{{opportunity.name}}</h5>
                <div class="card-details">
                  <div class="d-flex flex-wrap">
                    <img [src]="opportunity.stato_image" alt="">
                    <p class="card-date status">{{opportunity.stato}}</p>
                    <p class="card-date">{{opportunity.start_date | date:'dd/MM/yyyy'}} - {{opportunity.end_date |
                      date:'dd/MM/yyyy'}}</p>
                  </div>
                  <p class="card-category m-0">{{opportunity.tipologia}}</p>
                  <div class="navigation-arrows justify-content-between gap-3">
                    <button class="btn-primary d-flex justify-content-center" nz-button nzSize="large" nzType="primary"
                      (click)="goTo('opportunita/', opportunity.id)">
                      <img src="assets/icons/eye.svg" alt="">
                    </button>
                    <button class="btn-show" nz-button nzSize="large" nzType="default">
                        <img src="assets/icons/star_empty.svg" alt="">
                    </button>
                  </div>
                </div>
              </nz-card>
            </ng-container>
          </div>
          <ng-template #noNewOpp>
            <h5 class="aside-title new-opp no-new-content"> Nessuna nuova opportunità</h5>
          </ng-template>
          <div *ngIf="newOpportunities?.length != 0" class="navigation-arrows justify-content-end gap-3">
            <button nz-button nzType="default" (click)="prevPersonal()" [disabled]="currentNewOpportunityIndex === 0"
              [ngStyle]="{'background-color': (currentNewOpportunityIndex === 0) ? '#EEE8FD' : '', 'color': (currentNewOpportunityIndex !== 0) ? '#360E8B' : ''}">&lt;</button>
            <button nz-button nzType="default" (click)="nextPersonal()"
              [disabled]="currentNewOpportunityIndex >= newOpportunities?.length - 1"
              [ngStyle]="{'background-color': (currentNewOpportunityIndex >= newOpportunities?.length - 1) ? '#EEE8FD' : '', 'color': (currentNewOpportunityIndex < newOpportunities?.length - 1) ? '#360E8B' : ''}">&gt;</button>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
</div>
