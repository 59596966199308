<div class="insert-azienda">
    <div class="insert-azienda__header">
        <button class="go-back label gap-1 align-items-center d-flex" (click)="goTo('anagrafica', $event)"
            (keydown)="goTo('anagrafica', $event)">
            <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna indietro
        </button>
    </div>

    <section class="insert-azienda__tab-section">
        <nz-tabset class="tab-content">
            <nz-tab nzTitle="Dati generali">
                <form [formGroup]="datiGeneraliForm" (ngSubmit)="onSubmit()" class="input-group">

                    <div nz-col [nzSpan]="24">
                        <p *ngIf="this.azienda_id == null" class="call-header">Inserisci una
                            nuova azienda</p>
                        <div *ngIf="this.azienda_id != null" class="header-info-box">
                            <h2 class="header-info-box__title">{{ company['ragione_sociale'] }}</h2>
                            <p class="header-info-box__description">Data di ultimo aggiornamento: {{
                                company['updated_at'] |
                                date:'dd/MM/yyyy HH:mm' }}</p>
                        </div>
                        <p class="campi-obbligatori">* Campi obbligatori</p>
                    </div>

                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="ragione_sociale" class="form-label">Ragione Sociale *</label>
                            <input nz-input class="form-control" type="text" id="ragione_sociale"
                                formControlName="ragione_sociale" placeholder="Inserisci qui"
                                [nzStatus]="datiGeneraliForm.get('ragione_sociale')?.invalid && datiGeneraliForm.get('ragione_sociale')?.touched ? 'error' : ''">
                            <div *ngIf="datiGeneraliForm.get('ragione_sociale')?.invalid && datiGeneraliForm.get('ragione_sociale')?.touched"
                                class="mb-3 mt-0 alert-form">
                                La ragione sociale è richiesta
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="email" class="form-label">E-mail *</label>
                            <input nz-input class="form-control" type="email" id="email" formControlName="email"
                                placeholder="Inserisci qui"
                                [nzStatus]="datiGeneraliForm.get('email')?.invalid && datiGeneraliForm.get('email')?.touched ? 'error' : ''">
                            <div *ngIf="datiGeneraliForm.get('email')?.invalid && datiGeneraliForm.get('email')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('email')?.errors?.['required']">L'e-mail è
                                    richiesta</div>
                                <div *ngIf="datiGeneraliForm.get('email')?.errors?.['email']">L'e-mail non è in un
                                    formato valido</div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="partita_iva" class="form-label">Partita IVA *</label>
                            <input nz-input class="form-control" type="text" id="partita_iva"
                                formControlName="partita_iva" placeholder="Inserisci qui"
                                [nzStatus]="datiGeneraliForm.get('partita_iva')?.invalid && datiGeneraliForm.get('partita_iva')?.touched ? 'error' : ''">
                            <div *ngIf="datiGeneraliForm.get('partita_iva')?.invalid && datiGeneraliForm.get('partita_iva')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('partita_iva')?.errors?.['required']">La partita
                                    IVA è richiesta</div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="codice_fiscale" class="form-label">Codice fiscale</label>
                            <input nz-input class="form-control" type="text" id="codice_fiscale"
                                formControlName="codice_fiscale" placeholder="Inserisci qui">
                            <div *ngIf="datiGeneraliForm.get('codice_fiscale')?.invalid && datiGeneraliForm.get('codice_fiscale')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('codice_fiscale')?.errors?.['required']">La partita
                                    IVA è richiesta
                                </div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="numero_dipendenti" class="form-label">Numero dipendenti *</label>
                            <input nz-input class="form-control" type="number" id="numero_dipendenti"
                                formControlName="numero_dipendenti" placeholder="Inserisci qui">
                            <div *ngIf="datiGeneraliForm.get('numero_dipendenti')?.invalid && datiGeneraliForm.get('numero_dipendenti')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('numero_dipendenti')?.errors?.['required']">Il
                                    numero di dipendenti è
                                    richiesto</div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24">
                            <div class="col">
                                <h3 class="section_title">Sede legale</h3>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="indirizzo" class="form-label">Indirizzo</label>
                            <input nz-input class="form-control" type="text" id="indirizzo"
                                formControlName="indirizzo_sede_legale" placeholder="Inserisci qui">
                            <div *ngIf="datiGeneraliForm.get('indirizzo_sede_legale')?.invalid && datiGeneraliForm.get('indirizzo_sede_legale')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('indirizzo_sede_legale')?.errors?.['required']">
                                    L'indirizzo è richiesto
                                </div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label for="provincia" class="form-label">Nazione</label>
                            <input nz-input class="form-control" type="text" id="provincia" formControlName="nazione"
                                placeholder="Inserisci qui" readonly>
                            <div *ngIf="datiGeneraliForm.get('nazione')?.invalid && datiGeneraliForm.get('nazione')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('nazione')?.errors?.['required']">La provincia è
                                    richiesta</div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="8">
                            <label for="comune" class="form-label">Comune</label>
                            <input nz-input class="form-control" type="text" id="comune"
                                formControlName="comune_sede_legale" placeholder="Inserisci qui"
                                [nzAutocomplete]="auto">
                            <nz-autocomplete #auto (selectionChange)="onOptionSelected($event)">
                                <nz-auto-option *ngFor=" let option of comuni" [nzValue]="option">
                                    {{ option }}
                                </nz-auto-option>
                            </nz-autocomplete>
                            <div *ngIf="datiGeneraliForm.get('comune_sede_legale')?.invalid && datiGeneraliForm.get('comune_sede_legale')?.touched"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('comune_sede_legale')?.errors?.['required']">Il
                                    comune è richiesto
                                </div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="8">
                            <label for="provincia" class="form-label">Provincia</label>
                            <input nz-input class="form-control" type="text" id="provincia"
                                formControlName="provincia_sede_legale" placeholder="Inserisci qui" readonly>
                            <div *ngIf="datiGeneraliForm.get('provincia_sede_legale')?.invalid && datiGeneraliForm.get('provincia_sede_legale')?.touched"
                                class="mb-3 mt-0">
                                <div *ngIf="datiGeneraliForm.get('provincia_sede_legale')?.errors?.['required']">La
                                    provincia è
                                    richiesta</div>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="8">
                            <label for="cap" class="form-label">CAP</label>
                            <input nz-input class="form-control" type="text" id="cap" formControlName="cap_sede_legale"
                                placeholder="Inserisci qui">
                            <div *ngIf="datiGeneraliForm.get('cap_sede_legale')?.invalid && datiGeneraliForm.get('cap_sede_legale')?.touched"
                                class="mb-3 mt-0">
                                <div *ngIf="datiGeneraliForm.get('cap_sede_legale')?.errors?.['required']">Il CAP è
                                    richiesto</div>
                            </div>
                        </div>

                        <!-- MULTISELECT SETTORI -->
                        <div nz-col [nzSpan]="24">
                            <div class="col">
                                <h3 class="section_title">Settori di interesse</h3>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="24">
                            <app-multiselect [data]="settori.value" [dataControl]="settoriControl"
                                [(selected_data)]="settori_selezionati" [icon]="'icon_sector.svg'"></app-multiselect>
                        </div>

                        <div nz-col [nzSpan]="24">
                            <div class="col">
                                <h3 *ngIf="this.azienda_id == null" class="section_title">Dati di accesso
                                </h3>
                                <h3 *ngIf="this.azienda_id != null" class="section_title">Modifica dati di
                                    accesso</h3>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label *ngIf="this.azienda_id == null" for="indirizzo" class="form-label">Password
                                *</label>
                            <label *ngIf="this.azienda_id != null" for="indirizzo" class="form-label">Nuova
                                Password</label>
                            <input (ngModelChange)="setValidators($event)" nz-input class="form-control" type="text"
                                id="indirizzo" formControlName="password" [placeholder]="placeholderString"
                                [nzStatus]="datiGeneraliForm.get('password')?.invalid && datiGeneraliForm.get('password')?.touched ? 'error' : ''">
                            <div>
                                <ng-container
                                    *ngIf="(datiGeneraliForm.get('password')?.invalid && datiGeneraliForm.get('password')?.touched)">
                                    <div class="alert-form"
                                        *ngIf="datiGeneraliForm.get('password')?.errors?.['required']">• Campo
                                        obbligatorio</div>
                                </ng-container>

                                <ng-container *ngIf="datiGeneraliForm.get('password')?.value != null">
                                    <div class="alert-form"
                                        [ngClass]="datiGeneraliForm.get('password')?.errors?.['minlength'] ? 'validation-error' : 'validation-success'">
                                        • La password deve essere lunga almeno 8 caratteri</div>
                                    <div class="alert-form"
                                        [ngClass]="datiGeneraliForm.get('password')?.errors?.['uppercase'] ? 'validation-error' : 'validation-success'">
                                        • Deve contenere almeno una lettera maiuscola</div>
                                    <div class="alert-form"
                                        [ngClass]="datiGeneraliForm.get('password')?.errors?.['lowercase'] ? 'validation-error' : 'validation-success'">
                                        • Deve contenere almeno una lettera minuscola</div>
                                    <div class="alert-form"
                                        [ngClass]="datiGeneraliForm.get('password')?.errors?.['number'] ? 'validation-error' : 'validation-success'">
                                        • Deve contenere almeno un numero</div>
                                    <div class="alert-form"
                                        [ngClass]="datiGeneraliForm.get('password')?.errors?.['symbol'] ? 'validation-error' : 'validation-success'">
                                        • Deve contenere un carattere speciale: $&#64;*!%?&</div>
                                </ng-container>
                            </div>
                        </div>

                        <div nz-col [nzSpan]="24" [nzMd]="12">
                            <label *ngIf="this.azienda_id == null" for="indirizzo" class="form-label">Conferma
                                Password *</label>
                            <label *ngIf="this.azienda_id != null" for="indirizzo" class="form-label">Conferma
                                Password</label>
                            <input nz-input class="form-control" type="text" id="indirizzo" formControlName="confirm"
                                [placeholder]="placeholderString" (ngModelChange)="passwordCheckhValidator($event)"
                                [nzStatus]="datiGeneraliForm.get('confirm')?.invalid && datiGeneraliForm.get('confirm')?.touched ? 'error' : ''">
                            <div *ngIf="(datiGeneraliForm.get('confirm')?.invalid && datiGeneraliForm.get('confirm')?.touched) || checkPassword == false"
                                class="mb-3 mt-0 alert-form">
                                <div *ngIf="datiGeneraliForm.get('confirm')?.errors?.['required']">• Campo
                                    obbligatorio</div>
                                <div *ngIf="!checkPassword">• Le due password non coincidono</div>
                            </div>
                        </div>

                        <div nz-col [nzOffset]="20" [nzSpan]="24" class="d-flex justify-content-end m-0 mt-4 save-cta">
                            <button type="submit" [disabled]="datiGeneraliForm.invalid"
                                class="btn btn-primary">Salva</button>
                        </div>
                    </div>
                </form>
            </nz-tab>
            <nz-tab nzTitle="Certificazioni" *ngIf="azienda_id!=null">
                <div class="certification-section">
                    <app-certificazioni [customPagination]="5" [azienda_id]="azienda_id" [eht_admin]="true"
                        [eht_endpoint]="'azienda/certificazioni/dataTable'"></app-certificazioni>
                </div>
                <div class="certification-section">
                    <app-certificazioni [customPagination]="5" [azienda_id]="azienda_id" [eht_admin]="true"
                        [eht_endpoint]="'risorse/certificazioni/index'"></app-certificazioni>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Prodotti" *ngIf="azienda_id!=null">
                <app-prodotti [azienda_id]="azienda_id" [eht_admin]="true"></app-prodotti>
            </nz-tab>
            <nz-tab nzTitle="Competenze" *ngIf="azienda_id!=null">
                <app-competenze [azienda_id]="azienda_id" [eht_admin]="true"></app-competenze>
            </nz-tab>
        </nz-tabset>
    </section>
</div>