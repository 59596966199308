<div class="modal-show-documentation container">
    <div class="modal-show-documentation__header">
        <h1 class="title">Allegati dell'azienda {{data['Azienda']}}</h1>
        <button type="button" class="btn btn-outline-primary" (click)="downloadAllFiles($event)"
            (keydown)="downloadAllFiles($event)">Scarica tutti</button>
    </div>

    <form>
        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <drw-data-table class="modal-show-documentation__table" [displayedColumns]="displayedColumns"
                    [endpointDataSource]='endpoint' [additionalParams]='params' [actions]="false"
                    [actionsButton]='{ download: true, info: false, modify: false, trash: true, accept: false, refuse: false, upload: true }'
                    [isFilter]="false" [endpointPdf]="endpointPdf" [search]="search" [flagZip]="'documentazione'">
                </drw-data-table>
            </app-pcall-loader>
        </div>
        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" type="button" (click)="handleCancel()"
                [nzLoading]="isLoading">Chiudi</button>
        </div>
    </form>
</div>