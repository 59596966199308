<div class="profilo-risorse">
    <!-- begin: Sezione hero header mobile (escluso tablet) -->
    <app-header-section-page-mobile [title]="'Risorse'"
        [description]="'In questa sezione è possibile modificare le informazioni delle tue risorse.'"></app-header-section-page-mobile>
    <!-- end: Sezione hero header mobile (escluso tablet) -->

    <section class="profilo-risorse__tab-section">
        <app-header-section-page [title]="'Risorse'"
            [description]="'In questa sezione è possibile modificare le informazioni delle tue risorse.'"></app-header-section-page>
        <nz-tabset class="tab-content">
            <nz-tab nzTitle="Certificazioni">
                <app-certificazioni></app-certificazioni>
            </nz-tab>
            <nz-tab nzTitle="Competenze">
                <app-competenze></app-competenze>
            </nz-tab>
        </nz-tabset>
    </section>
</div>
