import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { CallsService } from 'src/app/shared/services/calls.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-opportunita-personali-table',
    templateUrl: './opportunita-personali-table.component.html',
    styleUrls: ['./opportunita-personali-table.component.scss']
})
export class OpportunitaPersonaliTableComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = [];
    actionsButton = { download: false, info: true, modify: false, trash: false, accept: false, refuse: false, upload: false  };

    typologies = [
        // { label: 'Ricerca e Sviluppo', value: 'Ricerca e Sviluppo' },
        { label: 'Gara', value: 'Gara' },
        { label: 'Commessa', value: 'Commessa' }
    ];

    progress = [
        { label: 'Approvata', value: 'Approvata' },
        { label: 'In attesa di approvazione', value: 'In attesa di approvazione' },
        { label: 'Non approvata', value: 'Non approvata' }
    ];

    status = [
        { label: 'Aperta', value: 'Aperta' },
        { label: 'Chiusa', value: 'Chiusa' }
    ];

    unsubscribe: Subject<void> = new Subject();
    endpointPdf: string = 'pdf'
    endpoint: string = "typology/index"
    search: any;
    searchForm!: FormGroup;

    /*
    Colonne della datatable opportunità
    */
    private opportunityDataTableColumns: string[] = ['Nome opportunità', 'Tipologia', 'Data inizio partecipazione', 'Data fine partecipazione', 'azioni'];
    private opportunityDataTableColumnsForMobile: string[] = ['Nome opportunità', 'azioni'];

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private callService: CallsService,
        private commonService: CommonService
    ) { 
        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.opportunityDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.opportunityDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.opportunityDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.opportunityDataTableColumnsForMobile));
                }
            });
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            search: [null],
            tipologia: [null],
            progresso: [null],
            stato: [null],
            user_call: [true]
        });
        this.search = this.searchForm.value;
        this.endpoint = "calls/getCall";
        this.searchForm.valueChanges
            .pipe(
                debounceTime(1000),  // Imposta il delay in millisecondi
                distinctUntilChanged(),  // Evita duplicati consecutivi
                takeUntil(this.unsubscribe)  // Interrompe l'osservazione quando il componente viene distrutto
            )
            .subscribe(value => {
                this.search = this.searchForm.value;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    goTo(uri: string, event : Event)
    { if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
        event.preventDefault();
        this.router.navigate([uri]);
      }
    }
}
