<div class="profilo-azienda">
  <!-- begin: Sezione hero header mobile (escluso tablet) -->
  <app-header-section-page-mobile [title]="'Azienda'"
    [description]="'In questa sezione è possibile modificare le informazioni della tua azienda.'"></app-header-section-page-mobile>
  <!-- end: Sezione hero header mobile (escluso tablet) -->

  <section class="profilo-azienda__tab-section">
    <app-header-section-page [title]="'Azienda'"
      [description]="'In questa sezione è possibile modificare le informazioni della tua azienda.'"></app-header-section-page>
    <nz-tabset class="tab-content" [nzSelectedIndex]="current_tab">
      <nz-tab nzTitle="Dati generali">
        <app-dati-generali></app-dati-generali>
      </nz-tab>
      <nz-tab nzTitle="Certificazioni">
        <app-certificazioni></app-certificazioni>
      </nz-tab>
      <nz-tab nzTitle="Prodotti">
        <app-prodotti></app-prodotti>
      </nz-tab>
    </nz-tabset>
  </section>
</div>
