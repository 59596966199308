import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-recovery-psw',
  templateUrl: './recovery-psw.component.html',
  styleUrls: ['./recovery-psw.component.scss']
})
export class RecoveryPswComponent implements OnInit {

  formCreateRecover: FormGroup;
  formCreateModify: FormGroup;
  uuid!: string | null;
  banner!: string;
  title!: string;
  subtitle!: string;
  templateRecoveryPsw: boolean = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.formCreateRecover = new FormGroup({
      mail: new FormControl(null, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),

    });

    this.formCreateModify = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')
      ]),
      confirm: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{7,}')
      ]),
      uuid: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.paramMap.get('uuid');

    //setto l'immagine del banner se ci troviamo nel form richiedi cambio password o modifica password
    if (this.uuid == null) {
      this.title = "Recupera la tua password"
      this.subtitle = "Inserisci nel campo qui sotto il tuo indirizzo e-mail. Ti verrà inviata una comunicazione con i passi da seguire per reimpostare la tua password."
      this.banner = '../../../assets/img/cerotto.svg'
    } else {
      this.title = "Inserisci la nuova password"
      this.subtitle = "La nuova password deve essere lunga minimo 8 caratteri e deve contenere almeno un numero, una lettera maiuscola, una minuscola e un carattere speciale ($@$!%*?&)"
      this.banner = '../../../assets/img/banner-modifica-password.svg'
    }

  }

  recover() {


    this.formCreateModify.patchValue({
      uuid: this.uuid
    });

    //Caso invio mail per recupero
    if (this.uuid == null) {
      if (this.formCreateRecover.valid) {
        this.authService.recover(this.formCreateRecover.value).subscribe({
          next: (res) => {
            this.templateRecoveryPsw = false;

            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 10000);
          },
          error: (err) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#ff0000',
            });
          }
        });

        return;


      } else if (this.uuid == null) {
        this.commonService.openToastAlert("La mail inserita non è valida", true, {
          position: 'top-end',
          icon: 'error',
          iconColor: '#ff0000',
        });

        return;
      }
    }

    //Caso modifica password
    if (this.uuid != null) {
      if (this.formCreateModify.valid) {

        //errore password diverse
        if (this.formCreateModify.controls['password'].value !== this.formCreateModify.controls['confirm'].value) {
          this.commonService.openToastAlert("Le due password non coincidono", true, {
            position: 'top-end',
            icon: 'error',
            iconColor: '#ff0000',
          });
        }

        this.authService.modifyPassword(this.formCreateModify.value).subscribe({
          next: (res) => {

            localStorage.removeItem('recover');

            this.commonService.openToastAlert(res?.message, true, {
              position: 'top-end',
              icon: 'success',
              iconColor: '#0dd30d',
            });

            this.formCreateModify.reset();

            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          },
          error: (err) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#ff0000',
            });
          }
        });
      } else {

        if (this.formCreateModify.controls['password'].value !== this.formCreateModify.controls['confirm'].value) {
          this.commonService.openToastAlert("Le due password non coincidono", true, {
            position: 'top-end',
            icon: 'error',
            iconColor: '#ff0000',
          });

          return;
        }

        this.commonService.openToastAlert("La password non è conforme alle specifiche richieste", true, {
          position: 'top-end',
          icon: 'error',
          iconColor: '#ff0000',
        });
      }
    }

  }

  navigateTo() {
    this.router.navigate(['/login']);
  }

}


