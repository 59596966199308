import { Injectable } from '@angular/core';
import { Menu } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private mainMenu: Menu[] = [];

  constructor() { 
    this.setMainMenu();
  }

  getMainMenu(): Menu[] {
    return this.mainMenu;
  }

  /**
   * Imposto il menu della sidebar
   */
  private setMainMenu(): void {
    this.mainMenu = [
      
      // la key admin serve per far visualizzare la voce nella sidebar se l'utente è admin (true) oppure no
      { name: 'Dashboard', icon: 'dashboard-item-active', link: '/dashboard' , admin: null},
      { name: 'Anagrafica', icon: 'dashboard-item-active', link: '/anagrafica', sublinkForAddActiveItem: ['/anagrafica/azienda'], admin: true },
      {
        name: 'Profilo', icon: 'user_circle_disabled', link: '/profilo/azienda', sublinkForAddActiveItem: ['/profilo/azienda', '/profilo/risorse', '/profilo/azienda/prodotto'], admin: false, isDropdown: true, dropdownItems:
          [
            { name: 'Azienda', link: '/profilo/azienda', sublinkForAddActiveItem: ['/profilo/azienda/prodotto'] },
            { name: 'Risorse', link: '/profilo/risorse' }
          ]
      },
    ];
  }
}
