<div class="modal-cert-risorse container">
    <h1 class="title">{{action}} certificazione</h1>
    <p class="campi-obbligatori">* Campi obbligatori</p>
    <form [formGroup]="certificationResourcesForm">
        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <div class="d-flex flex-column">
                    <div class="alert-hig alert-hig--success" *ngIf="certificationResourcesFlag">
                        <div class="alert-hig__logo"></div>
                        <div class="alert-hig__content">
                            <p>La certificazione “{{certificationResourcesForm.get('nome')?.value}}” è stata aggiunta
                                come
                                certificazione personalizzata</p>
                        </div>
                    </div>
                    <label class="label" for="id_certificazione">Certificazione*</label>
                    <nz-select [nzDisabled]="this.data.update" class="w-100" nzShowSearch nzAllowClear="false"
                        nzPlaceHolder="Seleziona una certificazione" formControlName="id_certificazione"
                        (nzOnSearch)="handleSearchChange($event)"
                        (ngModelChange)="saveNewResourcesCertification($event)"
                        [nzFilterOption]="filterOptionResources">
                        <nz-option *ngFor="let certification of availableCertification" [nzLabel]="certification.Nome"
                            [nzValue]="certification.id"></nz-option>
                        <nz-option *ngIf="searchTermResources != ''" [nzValue]="-1" [nzCustomContent]="true"
                            [nzLabel]="searchTermResources">
                            <div [innerHTML]="customLabel"></div>
                        </nz-option>
                    </nz-select>
                    <div *ngIf="certificationResourcesForm.get('id_certificazione')?.invalid && certificationResourcesForm.get('id_certificazione')?.touched; else Span"
                        class="mb-2 mt-0 camp-validation">
                        <div *ngIf="certificationResourcesForm.get('id_certificazione')?.errors?.['required']">La
                            certificazione è
                            richiesta</div>
                    </div>
                    <ng-template #Span>
                        <span class=" mb-4"></span>
                    </ng-template>
                </div>

                <div class="d-flex flex-column">
                    <label class="label" for="numero_risorse">Numero di Risorse Competenti*</label>
                    <input nz-input class="form-control" type="number" id="basse" formControlName="numero_risorse"
                        [ngClass]="{'input-error': !certificationResourcesForm.controls['numero_risorse'].valid && certificationResourcesForm.controls['numero_risorse'].touched}"
                        [min]="1" placeholder="Inserisci qui" min="1">
                    <div *ngIf="certificationResourcesForm.get('numero_risorse')?.invalid && certificationResourcesForm.get('numero_risorse')?.touched; else Span"
                        class="mb-2 mt-0 camp-validation">
                        <div *ngIf="certificationResourcesForm.controls['numero_risorse'].value < 1">Inserire almeno una
                            risorsa
                        </div>
                    </div>
                    <ng-template #Span>
                        <span class=" mb-4"></span>
                    </ng-template>
                </div>

                <div class="mb-0">
                    <nz-upload nzType="drag" nzListType="picture" [nzFileList]="fileList" nzAccept=".pdf" nzLimit="0"
                        [nzMultiple]="true" [nzBeforeUpload]="beforeUpload"
                        [nzShowUploadList]="{ showRemoveIcon: true, showPreviewIcon: true }"
                        (nzChange)="handleChange($event)">
                        <img class="mb-3" src="assets/icons/cloud-add.svg" style="font-size: 27px;" alt="cloud">
                        <div class="ant-upload-text title upload-title">Scegli o trascina i file qui</div>
                        <div class="ant-upload-hint upload-subtitle">Puoi caricare soltanto file PDF non superiori a 50
                            MB
                        </div>
                        <button class="button choose-file-btn" nz-button>Scegli file</button>
                    </nz-upload>
                </div>
            </app-pcall-loader>
        </div>

        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" [disabled]="certificationResourcesForm.invalid"
                [nzLoading]="isLoading" (click)="sendFile()">Salva</button>
            <button nz-button nzType="default" type="button" (click)="handleCancel()"
                [nzLoading]="isLoading">Chiudi</button>
        </div>
    </form>
</div>