import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { DataTableComponent } from './data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { CollapseComponent } from './collapse/collapse.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BaseComponent } from './base/base.component';
import { MultiSelectComponent } from './multiselect/multiselect.component';
import { ModalUploadDocumentationComponent } from './modals/modal-upload-documentation/modal-upload-documentation.component';
import { ModalCreateDocumentationComponent } from './modals/modal-create-documentation/modal-create-documentation.component';
import { ModalShowDocumentationComponent } from './modals/modal-show-documentation/modal-show-documentation.component';
import { PcallLoaderComponent } from '../shared/components/pcall-loader/pcall-loader.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    MultiSelectComponent,
    ModalUploadDocumentationComponent,
    ModalCreateDocumentationComponent,
    ModalShowDocumentationComponent,
    PcallLoaderComponent
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,

    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    NgZorroAntdModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    MultiSelectComponent,
    PcallLoaderComponent
  ]
})
export class ComponentsModule { }
