<div class="opportunita-personali-aside container-fluid pb-0 d-flex flex-column">
    <div class="row">
      <!-- Sezione "Le tue opportunità" -->
      <div class="col-12 mb-1">
        <nz-card class="card-approved">
          <div class="card-body">
            <div class="justify-content-between align-items-center mb-0">
              <h5 class="aside-title text-white">Richieste approvate</h5>
              <h5 class="small text-white" *ngIf="opportunities?.length != 0">
                Complimenti! Hai ricevuto l’approvazione per queste richieste
            </h5>
            </div>
            <div class="opportunities-container" *ngIf="opportunities?.length != 0; else noOpp">
              <ng-container
                *ngFor="let opportunity of opportunities?.slice(currentOpportunityIndex, currentOpportunityIndex + 1)">
                <nz-card class="small mt-3 container-fluid no-padding">
                  <h5 class="aside-title">{{opportunity.name}}</h5>
                  <div class="d-flex justify-content-between align-items-center flex-wrap gap-1">

                    <!-- begin IN ATTESA DI MODIFICA BE (rimuovere ngIf non appena si deve implementare) -->
                    <div *ngIf="false" [ngClass]="opportunity.type == 'Approvata' ? 'approval' : 'waiting'" class="p-1 d-flex align-items-center">
                      <img [src]="opportunity.type == 'Approvata' ? 'assets/icons/approved.svg' : 'assets/icons/waiting.svg'" alt="">
                      <span class="mx-1 mb-0" style="white-space: normal;">{{opportunity.type}}</span>
                    </div>
                    <!-- end IN ATTESA DI MODIFICA BE (rimuovere ngIf non appena si deve implementare) -->

                    <!-- begin ELIMINARE SOPRA DOPO MODIFICA -->
                    <div class="p-1 d-flex align-items-center approval">
                      <img [src]="'assets/icons/approval.svg'" alt="">
                      <span class="mx-1 mb-0" style="white-space: normal;">Approvata</span>
                    </div>
                    <!-- end ELIMINARE SOPRA DOPO MODIFICA -->

                    <button class="personal-show ml-auto" nz-button nzSize="small" nzType="default"
                      (click)="goTo('opportunita/', opportunity.id)">
                      <img src="assets/icons/blue_eye.svg" alt="">
                    </button>
                  </div>
                  <div class="card-details">
                    <div class="d-flex flex-wrap">
                      <img [src]="opportunity.stato_image" alt="">
                      <p class="card-date status">{{opportunity.stato}}</p>
                      <p class="card-date">{{opportunity.start_date | date:'dd/MM/yyyy'}} - {{opportunity.end_date |
                        date:'dd/MM/yyyy'}}</p>
                    </div>
                    <p class="card-category m-0">{{opportunity.tipologia}}</p>
                  </div>
                </nz-card>
              </ng-container>
            </div>
          </div>
          <ng-template #noOpp class="no-content">
            <h5 class="aside-title text-white m-auto no-content"> Nessuna opportunità approvata di recente</h5>
          </ng-template>
          <div *ngIf="opportunities?.length != 0" class="navigation-arrows justify-content-end gap-3">
            <button nz-button nzType="default" (click)="prev()" [disabled]="currentOpportunityIndex === 0"
              [ngStyle]="{'background-color': (currentOpportunityIndex === 0) ? '#7A46EC' : '', 'color': (currentOpportunityIndex !== 0) ? '#360E8B' : ''}">&lt;</button>
            <button nz-button nzType="default" (click)="next()"
              [disabled]="currentOpportunityIndex >= opportunities?.length - 1"
              [ngStyle]="{'background-color': (currentOpportunityIndex >= opportunities?.length - 1) ? '#7A46EC' : '', 'color': (currentOpportunityIndex < opportunities?.length - 1) ? '#360E8B' : ''}">&gt;</button>
          </div>
        </nz-card>
      </div>
    </div>

    <div class="row">
      <!-- Sezione "Nuove opportunità" -->
      <div class="col-12">
        <nz-card class="card-drift mb-0">
          <div class="card-body">
            <h5 class="aside-title text-white mb-0">Bozze</h5>
            <div class="col-md-12 d-flex mt-4 mb-1">
                <nz-input-group [nzPrefix]="prefixIconSearch" class="w-100">
                    <input type="text" nz-input placeholder="Cerca tra le bozze..." formControlName="search" />
                </nz-input-group>
                <ng-template #prefixIconSearch>
                    <span nz-icon nzType="search"></span>
                </ng-template>
            </div>
            <div class="opportunities-container" *ngIf="newOpportunities?.length != 0; else noNewOpp">
              <ng-container
                *ngFor="let opportunity of newOpportunities?.slice(currentNewOpportunityIndex, currentNewOpportunityIndex + 2)">
                <nz-card class="small mt-3 container-fluid no-padding">
                  <h5 class="aside-title">{{opportunity.name}}</h5>
                  <div class="d-flex flex-wrap justify-content-between">
                  <div class="card-details">
                    <div class="d-flex flex-wrap">
                      <img [src]="opportunity.stato_image" alt="">
                      <p class="card-date status">{{opportunity.stato}}</p>
                      <p class="card-date">{{opportunity.start_date | date:'dd/MM/yyyy'}} - {{opportunity.end_date |
                        date:'dd/MM/yyyy'}}</p>
                    </div>
                    <p class="card-category m-0">{{opportunity.tipologia}}</p>
                    <div class="navigation-arrows justify-content-between gap-3">
                    </div>
                  </div>
                  <button class="personal-show ml-auto" nz-button nzSize="small" nzType="default"
                      (click)="goTo('opportunita/', opportunity.id)">
                      <img src="assets/icons/blue_eye.svg" alt="">
                    </button>
                </div>
                </nz-card>
              </ng-container>
            </div>
            <ng-template #noNewOpp>
              <h5 class="aside-title text-white no-new-content"> Nessuna nuova opportunità</h5>
            </ng-template>
            <div *ngIf="newOpportunities?.length != 0" class="navigation-arrows justify-content-end gap-3">
              <button nz-button nzType="default" (click)="prevPersonal()" [disabled]="currentNewOpportunityIndex === 0"
                [ngStyle]="{'background-color': (currentNewOpportunityIndex === 0) ? '#360E8B' : '', 'color': (currentNewOpportunityIndex !== 0) ? '#360E8B' : ''}">&lt;</button>
              <button nz-button nzType="default" (click)="nextPersonal()"
                [disabled]="currentNewOpportunityIndex >= newOpportunities?.length - 2"
                [ngStyle]="{'background-color': (currentNewOpportunityIndex >= newOpportunities?.length - 2) ? '#360E8B' : '', 'color': (currentNewOpportunityIndex < newOpportunities?.length - 2) ? '#360E8B' : ''}">&gt;</button>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
