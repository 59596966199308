<div class="competenze">
    <div class="row align-items-center competenze__header">
        <!-- Testo -->
        <div class="col-md-10 header-box-text">
            <p class="header-box-text__title" *ngIf="!eht_admin">Le tue competenze acquisite</p>
            <p class="header-box-text__title" *ngIf="eht_admin">Competenze acquisite delle risorse</p>
            <p class="header-box-text__description">Data di ultimo aggiornamento: {{lastUpdate}}</p>
        </div>

        <!-- Pulsante -->
        <div class="col-md-2 d-flex justify-content-end header-box-cta" *ngIf="!eht_admin">
            <button type="button" class="btn btn-outline-primary" (click)="onAddElement()">Aggiungi</button>
        </div>
    </div>

    <form [formGroup]="searchForm" class="competenze__content-form mb-3">
        <nz-input-group [nzPrefix]="prefixIconSearch">
            <input type="text" nz-input placeholder="Cerca competenza..." formControlName="search" />
        </nz-input-group>
        <ng-template #prefixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </form>

    <app-legenda-competenze></app-legenda-competenze>

    <drw-data-table *ngIf="eht_admin"
        class="competenze__table"
        [displayedColumns]="displayedColumns"
        [additionalParams]="params.data"
        [endpointDataSource]='endpoint'
        [method]="'get'"
        [actions]="true"
        [actionsButton]=actions
        [isFilter]="false"
        (editedElement)="onEditedElement($event)"
        (deletedElement)="onDeletedElement($event)"
        [search]="search"
        (lastUpdate)="lastElement($event)"
    ></drw-data-table>

    <drw-data-table *ngIf="!eht_admin"
        class="competenze__table"
        [displayedColumns]="displayedColumns"
        [endpointDataSource]='endpoint'
        [method]="'get'"
        [actions]="true"
        [actionsButton]=actions
        [isFilter]="false"
        (editedElement)="onEditedElement($event)"
        (deletedElement)="onDeletedElement($event)"
        [search]="search"
        (lastUpdate)="lastElement($event)"
    ></drw-data-table>
</div>