import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { FormattedResponse } from "../models/formatted-response.model";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  getFileExtensions(flag: any): Observable<FormattedResponse> {
    return this.http.get<any>(`${environment.ApiURL}/filestorage/getAcceptedExtensions/${flag}`);
  }

  downloadZip(id: any, flag: any): Observable<Blob> {
    // Crea un oggetto FormData
    const formData = new FormData();
    formData.append('id', id.id);
    formData.append('flag', flag.flag);

    // Invia la richiesta POST
    return this.http.post(`${environment.ApiURL}/filestorage/downloadFiles`, formData, { responseType: 'blob' });
  }

  downloadAllFiles(call_id: any, azienda_id: any): Observable<Blob> {
    // Crea un oggetto FormData
    const formData = new FormData();
    formData.append('call_id', call_id.call_id);
    formData.append('azienda_id', azienda_id.azienda_id);

    // Invia la richiesta POST
    return this.http.post(`${environment.ApiURL}/filestorage/downloadAllFiles`, formData, { responseType: 'blob' });
  }

  downloadFile(id: any): Observable<Blob> {
    return this.http.post(`${environment.ApiURL}/filestorage/download`, { id: id }, { responseType: 'blob' });
  }


}
