import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Menu } from 'src/app/shared/models/menu.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MenuService } from 'src/app/shared/services/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  isToggleCollapsedForMobile: boolean = true;
  openedMenu: string | null = null;
  hide: boolean = false;
  menuList: Menu[] = [];

  // Variabile dedicata alla gestione dei subscribe al destroy
  private unsubscribe$ = new Subject<void>();

  constructor(
    public router: Router,
    public authService: AuthService,
    private _menuService: MenuService
  ) {
    this.hide = (this.authService.currentUser.value.roles['0'].id == 1);
    this.menuList = this._menuService.getMainMenu();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleDropdown(menuName: string) {
    if (this.openedMenu === menuName) {
      // Chiude il menu se già aperto
      this.openedMenu = null;
    } else {
      // Apri il menu specifico
      this.openedMenu = menuName;
    }
  }

  /**
   * Ritorna un booleano che indica se si ci trova in un url tipo "sublink" in modo da poter tenere
   * evidenziata la voce del menu
   * 
   * @param menuItem Item del menu
   * @returns 
   */
  isIntoSublinkForAddActiveItem(menuItem?: Menu): boolean {
    return menuItem?.sublinkForAddActiveItem?.some((path: string) => this.router.url.startsWith(path)) || false;
  }

  isOpen(menuItem?: Menu): boolean {
    return (this.router.url == '/profilo/azienda' || this.router.url == '/profilo/risorse' || this.isIntoSublinkForAddActiveItem(menuItem)) ? true : this.openedMenu === menuItem?.name;
  }

  logout() {
    this.authService.logout().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (res) => {
        /*
        Prima di eseguire altre operazioni, rimuovo il backdrop impostato (solo su sidebar in mobile)
        */
        const pageBodyContent: HTMLElement | null = document.getElementById('page-body-elm');
        if (pageBodyContent?.classList?.contains('sidebar-mobile-backdrop')) {
          pageBodyContent?.classList.remove('sidebar-mobile-backdrop');
        }

        /*
        Eseguo le altre operazioni
        */
        this.authService.currentUser.next(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('recover');
        this.router.navigate(['/login']);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  toggleCollapsedForMobile(event?: any): void {
    if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
      this.isToggleCollapsedForMobile = !this.isToggleCollapsedForMobile;

      const pageBodyContent: HTMLElement | null = document.getElementById('page-body-elm');
      if (!this.isToggleCollapsedForMobile && !pageBodyContent?.classList?.contains('sidebar-mobile-backdrop')) {
        pageBodyContent?.classList.add('sidebar-mobile-backdrop');
      } else if (this.isToggleCollapsedForMobile) {
        pageBodyContent?.classList.remove('sidebar-mobile-backdrop');
      }
    }
  }

}
