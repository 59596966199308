<div class="mat-data-table container-fluid px-0 pt-0">
    <div class="mat-data-table__filters d-flex flex-row justify-content-between mb-3">
        <mat-form-field type MatFormFieldAppearance="'fill' | 'outline';" *ngIf="isFilter">
            <mat-label>{{"FILTER" | translate}}</mat-label>
        </mat-form-field>
        <div class="d-flex align-items-center">
        </div>
    </div>

    <div class="mat-data-table__content">
        <app-pcall-loader [showLoader]="isLoading">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                class="justify-content-center" [fixedLayout]="fixedLayout">
                <!-- Columns -->
                <ng-container *ngFor="let column of displayedColumns; let i = index" [matColumnDef]="column"
                    [sticky]="(innerStickyDisplayedColumnsList.length) ? innerStickyDisplayedColumnsList.includes(column?.trim()?.toLowerCase() || '') : false">
                    <th [ngClass]="{'th-hidden': column == 'azioni'}" mat-header-cell *matHeaderCellDef mat-sort-header
                        [disabled]="!sortableHeader(column)">
                        {{ capitalizeFirstLetter(column) }}
                    </th>
                    <!-- Serve ad allineare a sinistra il contenuto di tutte le prime colonne -->
                    <td mat-cell *matCellDef="let row" [ngClass]="(i === 0)?('text-start'):('')">
                        <div *ngIf="column == 'Allegato'">
                            <p style="margin: 0;" *ngIf="row[column] == 'Aperta'" class="d-flex align-items-center">
                                <img class="mb-0" src="assets/icons/green_dot.svg" alt="" style="margin-right: 0.3rem;">
                            </p>
                            <p style="margin: 0;" *ngIf="row[column] == 'Chiusa'" class="d-flex align-items-center">
                                <img src="assets/icons/red_dot.svg" alt="" style="margin-right: 0.3rem;">
                            </p>

                            <a *ngIf="actionsButton.download && row.file_storage_id != null; else noFile"
                                (click)="openLink(row)" target="_blank"><img src="/assets/icons/download.svg"
                                    alt=""></a>

                            <ng-template #noFile>
                                <p class="no-file">Nessun allegato</p>
                            </ng-template>
                        </div>
                        <div *ngIf="column == 'Allegati'">
                            <div class="pdf" *ngIf="actionsButton?.download && row?.allegati_flag?.length >= 1"
                                (click)="downloadZip(row, $event)" (keypress)="downloadZip(row, $event)">
                                <img src="/assets/icons/download.svg" alt="">
                            </div>
                            <div class="pdf" *ngIf="row.visualizza_allegati" (click)="showDocModal(row, $event)"
                                (keydown)="showDocModal(row, $event)">
                                <img src="assets/icons/eye.svg" alt=""> <u style="color:#360E8B">Visualizza allegati</u>
                            </div>
                            <div class="d-flex flex-row justify-content-center"
                                *ngIf="row?.allegati_flag?.length < 1 && !row?.visualizza_allegati">
                                <p class="no-file">Nessun file</p>
                            </div>
                        </div>
                        <div *ngIf="column == 'Template'">
                            <div class="pdf" (click)="exportData('template', row.template, $event)"
                                (keypress)="exportData('template', row.template, $event)"
                                *ngIf="actionsButton.download && row.template != null; else noTemplate">
                                <img src="/assets/icons/download.svg" alt="Icona download">
                            </div>
                            <ng-template #noTemplate>
                                <p class="no-file">Nessun template</p>
                            </ng-template>
                        </div>

                        <div *ngIf="column == 'Azienda candidata'">
                            <a aria-label="Dettaglio azienda candidata" href="azienda/{{row.company_id}}"
                                [innerHTML]="row[column]"></a>
                            <div class="pdf pdf--candidate-company-mobile" (click)="showDocModal(row, $event)"
                                (keydown)="showDocModal(row, $event)" *ngIf="row.visualizza_allegati">
                                <img src="assets/icons/eye.svg" alt=""> <u style="color:#360E8B">Visualizza allegati</u>
                            </div>
                        </div>

                        <div *ngIf="actions && column == 'azioni'; else noActions">
                            <div class="d-flex flex-row justify-content-center std-actions">
                                <!-- begin: Pulsante di upload per sezione documentazione azienda  -->
                                <button *ngIf="actionsButton.upload && row.allegati_flag.length < 1"
                                    (click)="openUploadModal(row, $event)" [disabled]="!row.can_upload"
                                    class="btn btn-outline-primary margin-actions-button">
                                    <img src="assets/icons/upload.svg" alt="">
                                </button>

                                <button *ngIf="actionsButton.upload && row.allegati_flag.length >= 1"
                                    [disabled]="!row.can_upload"
                                    class="btn btn-primary margin-actions-button custom-button"
                                    (click)="openUploadModal(row, $event)">
                                    <img src="assets/icons/pencil_white.svg" alt="">
                                </button>
                                <!-- end: Pulsante di upload per sezione documentazione azienda  -->

                                <button (click)="navigateTo(row)" *ngIf="actionsButton.info"
                                    class="btn btn-outline-primary margin-actions-button custom-button">
                                    <img src="assets/icons/blue_eye.svg" alt="">
                                </button>
                                <button *ngIf="actionsButton.modify"
                                    class="btn btn-primary margin-actions-button custom-button"
                                    (click)="editElement(row)">
                                    <img src="assets/icons/pencil_white.svg" alt="">
                                </button>
                                <button *ngIf="actionsButton.trash"
                                    [disabled]="(row.Obbligatoria && row.Obbligatoria == 'Si') || (row.Tipologia && row.Tipologia == 'Obbligatoria') || (row.can_upload == false)"
                                    class="btn custom-btn-danger custom-button" [swal]="{
                                        title: title,
                                        showCancelButton: true,
                                        confirmButtonColor: '#dd6b55',
                                        cancelButtonText: cancelButton,
                                        confirmButtonText: confirmButton,
                                        customClass: {
                                            title: 'delete-confirmation-swal-title',
                                            confirmButton: 'delete-confirmation-swal-btn-primary',
                                            cancelButton: 'delete-confirmation-swal-btn-outline-primary'
                                        },
                                        imageUrl: '/assets/icons/confirmation-delete.svg',
                                        imageWidth: 96,
                                        imageHeight: 96
                                    }" (confirm)="deleteElement(row.id)">
                                    <img src="assets/icons/bin_white.svg" alt="">
                                </button>

                                <!-- begin: Pulsanti gestione adesione aziendale  -->
                                <button class="btn btn-primary full-btn" type="button"
                                    (click)="manageResponse(row, 1, $event)" (keydown)="manageResponse(row, 1, $event)"
                                    *ngIf="actionsButton.accept && row.Status == 'In Attesa'">
                                    Accetta
                                </button>
                                <button class="btn btn-primary-refuse full-btn" type="button"
                                    (click)="openModal(row, '', $event)" (keydown)="openModal(row, '', $event)"
                                    *ngIf="actionsButton.refuse && row.Status == 'In Attesa'">
                                    Rifiuta
                                </button>
                                <span *ngIf="actionsButton.accept && row.Status == 'Rifiutata'"><img
                                        src="assets/icons/X.svg" alt=""> <b style="color:#B13E3E">Rifiutata </b> <u
                                        class="d-inline-block d-md-inline" style="cursor: pointer;"
                                        (click)="openModal(row, 'eht', $event)"
                                        (keydown)="openModal(row, 'eht', $event)"><img src="assets/icons/eye.svg"
                                            alt="">Visualizza note</u></span>
                                <span *ngIf="actionsButton.accept && row.Status == 'Accettata'"><b
                                        style="color:#00A155"><img src="assets/icons/V.svg" alt=""> Accettata</b></span>
                                <!-- end: Pulsanti gestione adesione aziendale -->


                            </div>
                        </div>
                        <ng-template #noActions>
                            <div [ngSwitch]="column">
                                <div *ngSwitchCase="'Stato'">
                                    <p class="d-flex align-items-center m-0 justify-content-center"
                                        *ngIf="column == 'Stato'">
                                        <img *ngIf="row[column] == 'Aperta'" src="assets/icons/green_dot.svg" alt=""
                                            style="display: flex; margin-right: 0.3rem;">
                                        <img *ngIf="row[column] == 'Chiusa'" src="assets/icons/red_dot.svg" alt=""
                                            style="display: flex; margin-right: 0.3rem;">
                                        {{row[column]}}
                                    </p>
                                </div>
                                <div *ngSwitchDefault>
                                    <ng-container
                                        *ngIf="column === 'Nome certificazione' || column === 'Nome opportunità' || column === 'Nome competenza'">
                                        <div [innerHTML]="row[column]"></div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="column !== 'Nome certificazione' && column !== 'Nome opportunità' && column !== 'Nome competenza'">
                                        <div *ngIf="startsWithData(column) && isValidDate(row[column]); else notADate">
                                            {{row[column] | date:'dd/MM/yyyy'}}
                                        </div>
                                        <ng-template #notADate>
                                            <div
                                                *ngIf="row[column] && column !== 'Azienda candidata'; else emptyColumn">
                                                <div [innerHTML]="sanitizeHtml(row[column])"></div>
                                            </div>
                                            <ng-template #emptyColumn>
                                                <div *ngIf="startsWithData(column)">
                                                    <span style="color:#9090A2">Non specificata</span>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </ng-container>
                                </div>

                            </div>

                        </ng-template>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="100%">
                        <p class="text-center mt-3">{{'NO_DATA_FOUND' | translate}}</p>
                    </td>
                </tr>
            </table>
        </app-pcall-loader>
    </div>

    <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
        [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        aria-label="Select page">
    </mat-paginator>
</div>