import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent {
  @Input() position: 'left' | 'right' = 'right';
  @Input() isColorCollapse: boolean = false
  @Input() header = '';
  @Input() nzGhost = true;
  @Input() headerArray = new Array();
  @Input() active = true;

  isPanelCollapsed: boolean = true;


  constructor() { }

  togglePanelCollapse(event?: Event): void {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.isPanelCollapsed = !this.isPanelCollapsed;
  }

}
