<div class="prodotti">
    <div class="row align-items-center mb-3 prodotti__header">
        <!-- Testo -->
        <div class="col-md-10 header-box-text">
            <p class="header-box-text__title" *ngIf="!eht_admin">I tuoi prodotti</p>
            <p class="header-box-text__title" *ngIf="eht_admin">Prodotti</p>
            <p class="header-box-text__description"> Data di ultimo aggiornamento: {{lastUpdate}}</p>
        </div>

        <!-- Pulsante -->
        <div class="col-md-2 d-flex justify-content-end header-box-cta" *ngIf="!eht_admin">
            <button type="button" class="btn btn-outline-primary" (click)="goToNewProductSection($event)"
                (keydown)="goToNewProductSection($event)">Aggiungi</button>
        </div>
    </div>

    <form [formGroup]="searchForm" class="prodotti__content-form">
        <nz-input-group [nzPrefix]="prefixIconSearch">
            <input type="text" nz-input placeholder="Cerca prodotto..." formControlName="search" />
        </nz-input-group>
        <ng-template #prefixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </form>

    <drw-data-table class="prodotti__table" [displayedColumns]="displayedColumns" [endpointDataSource]='endpoint'
        [additionalParams]='params.data' [actions]="true" [isFilter]="false" [flagZip]="'products'"
        [endpointPdf]="endpointPdf" [actionsButton]="actions" [enableNavigation]="true"
        (editedElement)="goToEditProductSection($event)" (deletedElement)="onDeletedElement($event)"
        (lastUpdate)="lastElement($event)" [search]="search" [method]="'get'"></drw-data-table>
</div>