import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { finalize, Subject, takeUntil, BehaviorSubject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { DocumentazioneService } from 'src/app/shared/services/documentazione.service';
import { FileService } from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-modal-upload-documentation',
  templateUrl: './modal-upload-documentation.component.html',
  styleUrls: ['./modal-upload-documentation.component.scss']
})
export class ModalUploadDocumentationComponent {

  fileList: NzUploadFile[] = [];
  fileListRender: NzUploadFile[] = [];
  files: any[] = [];
  filesCount: number = 0;
  search: string = "";
  isLoading: boolean = false;
  unsubscribe: Subject<void> = new Subject();
  disableButton: boolean = true;
  update: boolean = false;
  fileExtensions = new BehaviorSubject<any[]>([]);
  nzAccept: string = '';

  // serve a controllare l'attuale stringa scritta nella barra di ricerca della select Certificazione
  searchTermResources: string = '';

  // serve ad aggiungere l'option personalizzata dopo le operazioni di filtro di nz-select
  filteredCertification: any[] = [];

  constructor(
    private modal: NzModalRef,
    private docService: DocumentazioneService,
    private commonService: CommonService,
    private fileService: FileService,
    @Inject(NZ_MODAL_DATA) public data: any,
  ) { }

  ngOnInit(): void {

    if (this.data.allegati_flag.length > 0) {
      this.fileList = this.data.allegati_flag;
      this.files = this.data.allegati_flag;
      this.filesCount = this.data.allegati_flag.length;
      this.update = true;
      this.disableButton = false;
    }

    //Recupero le estensioni accettate dal BE per il caricamento files
    this.isLoading = true;
    this.fileService.getFileExtensions('documentazione_flag')
      .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
      .subscribe({
        next: (res: any): void => {
          this.fileExtensions.next(res.data);

          if (this.fileExtensions.value && this.fileExtensions.value.length > 0) {
            // Crea una stringa da tutte le estensioni
            this.nzAccept = this.fileExtensions.value.map(ext => '.' + ext.extension).join(', ');
          } else {
            this.nzAccept = '.pdf'
          }

        }
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Caricamento file dalla modale di caricamento
  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    // Inizializza la variabile di ritorno
    let canUpload = true;

    // Aggiungi solo il nuovo file all'array fileList
    this.fileList = this.fileList.concat(file);
    this.filesCount++;

    // Processa solo il nuovo file
    const reader = new FileReader();
    reader.readAsDataURL(file as any);  // Assicurati che il cast sia appropriato
    reader.onload = () => {
      this.files.push({ base64: reader.result, name: file.name });
    };

    // Imposta canUpload su false per prevenire ulteriori upload automatici
    canUpload = false;

    //Attiva il pulsante "salva"
    this.disableButton = false;

    // Ritorna il valore di canUpload
    return canUpload;
  };

  // Cancellazione file dalla modale inserimento
  handleChange(info: { file: NzUploadFile, fileList: NzUploadFile[] }): void {

    this.files = this.files.filter(file => file.name !== info.file.name);
    this.fileList = this.fileList.filter(file => file.name !== info.file.name);
    this.filesCount--;

    //Disabilito il pulsante salva se non ci sono file caricati
    if (this.files.length == 0) {
      this.disableButton = true;
    }
  }



  sendFile(): void {

    const paramsToSend: any = {
      data: this.files,
      id_documentazione_call: this.data.id
    }

    if (this.update) {
      this.isLoading = true;

      this.docService.updateDocumentazione(this.data.id, paramsToSend)
        .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
        .subscribe({
          next: (res) => {
            this.commonService.openToastAlert("Documentazione modificata", true, {
              position: 'top-end',
              icon: 'success',
              iconColor: '#0dd30d',
            });

            this.docService.updateDataTable();
            this.modal.destroy()
          },
          error: (err: any) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#f03506',
            });
          }
        });
    } else {
      this.isLoading = true;

      this.docService.storeDocumentazione(paramsToSend)
        .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
        .subscribe({
          next: (res) => {
            this.commonService.openToastAlert("Documentazione salvata", true, {
              position: 'top-end',
              icon: 'success',
              iconColor: '#0dd30d',
            });

            this.docService.updateDataTable();
            this.modal.destroy();
          },
          error: (err: any) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#f03506',
            });
          }
        });
    }
  }

  handleCancel(): void {
    this.modal.destroy();
  }

}
