import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Settore } from 'src/app/shared/models/settore';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { ProdottoService } from 'src/app/shared/services/prodotto.service';
import { FileService } from 'src/app/shared/services/file.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/shared/services/common.service';
const uri = 'profilo/azienda';

@Component({
  selector: 'app-prodotti-dettaglio',
  templateUrl: './prodotti-dettaglio.component.html',
  styleUrls: ['./prodotti-dettaglio.component.scss']
})
export class ProdottiDettaglioComponent implements OnInit {
    prodottoId: string | null;
    aziendaId: string | null;
    visualizzazione_eht: boolean = false;
    dataForm: FormGroup;
    pageTitle: string;
    settori_selezionati: any[] = [];
    settori: BehaviorSubject<Settore[]>;
    fileList: NzUploadFile[] = [];
    files: any[] = [];
    nzAccept: string = '';
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '168px',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        defaultParagraphSeparator: '',
        defaultFontName: 'Sora',
        toolbarHiddenButtons: [
            ['insertImage', 'insertVideo', 'fontSize', 'fontName', 'backgroundColor']
        ]
    }
    fileExtensions = new BehaviorSubject<any[]>([]);

    constructor(
        private fb: FormBuilder,
        private prodottoService: ProdottoService,
        private route: ActivatedRoute,
        private router: Router,
        private companiesService: CompaniesService,
        private fileService: FileService,
        private commonService: CommonService
    ) {
        this.prodottoId = this.route.snapshot.paramMap.get('prodotto_id');
        this.aziendaId = this.route.snapshot.paramMap.get('azienda_id');
        this.pageTitle = (this.prodottoId === null) ? ('Aggiungi prodotto') : ('Modifica prodotto');
        this.pageTitle = (this.aziendaId === null) ? this.pageTitle : ('Dettaglio prodotto');
        this.settori = new BehaviorSubject<Settore[]>([]);

        this.dataForm = this.fb.group({
            name: new FormControl(null, Validators.required),
            description: new FormControl(null, Validators.required),
            settori: new FormControl([]),
            files: new FormControl(null)
        });
    }

    ngOnInit(): void {
        //Recupero tutti i settori
        this.companiesService.getSettori().subscribe({
            next: (res: any): void => {
                this.settori.next(res.data);
            }
        });

        
        if(this.prodottoId != null) {
            this.prodottoService.showProdotto(this.prodottoId).subscribe((res:any) => {
                this.dataForm.patchValue(res.data);
                this.settori_selezionati = res.data.settori;
                this.fileList = res.data.files;
                this.files = res.data.files;
            });
        }
        
        //Visualizzazione prodotto lato EHT
        if(this.aziendaId != null){
            this.visualizzazione_eht = true;
        }

        //Recupero le estensioni accettate dal BE per il caricamento files
        this.fileService.getFileExtensions('prodotti_flag').subscribe({
            next: (res: any): void => {
                this.fileExtensions.next(res.data);

                if (this.fileExtensions.value && this.fileExtensions.value.length > 0) {
                    // Crea una stringa da tutte le estensioni
                    this.nzAccept = this.fileExtensions.value.map(ext => '.' + ext.extension).join(', ');
                  } else {
                    this.nzAccept = '.pdf'
                  }
            }
        });
    }

    get settoriControl(): FormControl {
        return this.dataForm.get('settori') as FormControl;
    }

    goTo(event: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        localStorage.setItem('current_tab', '2');

        if(this.visualizzazione_eht){
            this.router.navigate(['anagrafica/azienda/' + this.route.snapshot.paramMap.get('azienda_id')]);
        }else{
            this.router.navigate([uri]);
        }  
    }

    changeSelection(selectedId: number, event?: Event): void {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        const index = this.settori_selezionati.indexOf(selectedId);
        if (index !== -1) {
            this.settori_selezionati.splice(index, 1);
        }
    }

    beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
        this.fileList = this.fileList.concat(file);

        // Processa solo il nuovo file
        const reader = new FileReader();
        reader.readAsDataURL(file as any);  // Assicurati che il cast sia appropriato
        reader.onload = () => {
        this.files.push({ base64: reader.result, name: file.name });
        };

        return false;  // Prevent automatic upload
    };

    // Cancellazione file dalla modale inserimento
    handleChange(info: { file: NzUploadFile, fileList: NzUploadFile[] }): void {
        this.files = this.files.filter(file => {
            // Rimuovi l'estensione dal nome del file corrente
            const baseName = file.name.split('.')[0];
            // Rimuovi l'estensione dal nome del file da confrontare
            const infoBaseName = info.file.name.split('.')[0];
            return baseName !== infoBaseName;
        });
        this.fileList = this.fileList.filter(file => file.name !== info.file.name);
    }

    save(): void {
        if (this.dataForm.valid) {
            if (this.prodottoId == null) {
                this.dataForm.patchValue({
                    settori: this.settoriControl.value,
                    files: this.files
                });

                this.prodottoService.storeProdotto(this.dataForm.value).subscribe({
                    next: (): void => {
                        this.commonService.openToastAlert("Prodotto creato", true, {
                            position: 'top-end',
                            icon: 'success',
                            iconColor: '#0dd30d',
                        });

                        localStorage.setItem('current_tab', '2');
                        this.router.navigate([uri]);
                        this.dataForm.reset();
                        this.fileList = [];
                    },
                    error: (err: HttpErrorResponse) => {
                        let title: string;

                        if (typeof err.message === 'string') {
                            title = err.message;
                        } else if (typeof err.message === 'object' && err.message !== null) {
                            // Handling if message is an object with arrays of strings
                            title = Object.values(err.message)
                                .flatMap((messages) => messages)
                                .join(', ');
                        } else {
                            title = 'Unknown error';
                        }

                        this.commonService.openToastAlert(title, true, {
                            position: 'top-end',
                            icon: 'error',
                            iconColor: '#f03506',
                        });
                    }
                });
            } else {
                this.dataForm.patchValue({
                    files: this.files
                });
                this.prodottoService.updateProdotto(this.prodottoId, this.dataForm.value).subscribe({
                    next: (): void => {
                        this.commonService.openToastAlert("Prodotto modificato", true, {
                            position: 'top-end',
                            icon: 'success',
                            iconColor: '#0dd30d',
                        });

                        localStorage.setItem('current_tab', '2');
                        this.router.navigate([uri]);

                    },
                    error: (err: HttpErrorResponse) => {
                        let title: string;

                        if (typeof err.message === 'string') {
                            title = err.message;
                        } else if (typeof err.message === 'object' && err.message !== null) {
                            // Handling if message is an object with arrays of strings
                            title = Object.values(err.message)
                                .flatMap((messages) => messages)
                                .join(', ');
                        } else {
                            title = 'Unknown error';
                        }

                        this.commonService.openToastAlert(title, true, {
                            position: 'top-end',
                            icon: 'error',
                            iconColor: '#f03506',
                        });
                    }
                });
            }
        } else {
            this.commonService.openToastAlert("Compila tutti i campi", true, {
                position: 'top-end',
                icon: 'error',
                iconColor: '#f03506',
            });
        }
    }

    downloadZip(event?: any): void {

        if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
          lastValueFrom(this.fileService.downloadZip({ id: this.prodottoId}, {flag: 'products'}))
            .then((blob: Blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'files.zip';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch((error: any) => {
              console.error('Download error:', error);
            });
        }
      }

    /**
     *
     * @param data // indicare l'array principale che viene ciclato
     * @param updatedData  // indicare l'array d'appoggio di cui si vuole fare l'update (settori_selezionati)
     * @returns
     */
    getSelection(data: any, updatedData: any) {
        return data.value.filter((selected: any) => updatedData.includes(selected.id))
        .sort((a: any, b: any) => (a.Nome || a.name).localeCompare((b.Nome || b.name)));
    }

}
