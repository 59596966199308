import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pcall-loader',
  templateUrl: './pcall-loader.component.html',
  styleUrls: ['./pcall-loader.component.scss']
})
export class PcallLoaderComponent {
  // mostra/nascondi loader spinner (di default è nasconsto)
  @Input() showLoader: boolean = false;
}
