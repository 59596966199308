import { Component, OnDestroy, OnInit } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as moment from 'moment';
import { SkillService } from 'src/app/shared/services/skill.service';
import { Subject, takeUntil } from 'rxjs';
import { Skill } from 'src/app/shared/models/skill.model';
import { Certification } from 'src/app/shared/models/certification';
import { CertificationService } from 'src/app/shared/services/certification.service';
import { CallsService } from 'src/app/shared/services/calls.service';
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { Settore } from 'src/app/shared/models/settore';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-insert-call',
  templateUrl: './insert-call.component.html',
  styleUrls: ['./insert-call.component.scss']
})
export class InsertCallComponent implements OnInit, OnDestroy {

  fileList: NzUploadFile[] = [];
  dataForm: FormGroup;
  files: any[] = [];
  skillsList: Skill[] = [];
  skillsParamsForDatatable: [] = [];
  certificationsList: Certification[] = [];
  certificationsParamsForDatatable: [] = [];
  matchingCount: number = 0;
  matchingCount2: number = 0;
  stickyDisplayedColumns: string[] = ['Ragione sociale'];
  displayedColumns: string[] = ['Ragione sociale'];
  displayedColumns2: string[] = ['Ragione sociale'];
  endpoint: string = "calls/getMatchingCerts"
  endpoint2: string = "calls/getMatchingSkills"
  callId: any = null;
  title: string = 'Inserisci una nuova opportunità';
  settoriList: Settore[] = [];
  updateSkills: any[] = [];
  updateSettori: any[] = [];
  updateCertifications: any[] = [];
  settori_selezionati: any[] = [];
  certifications_selezionati: any[] = [];
  skills_selezionati: any[] = [];

  tipologie = [
    { key: 0, value: "Commessa" },
    { key: 2, value: "Gara" },
  ]

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '168px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: 'Sora',
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo', 'fontSize', 'fontName', 'backgroundColor']
    ]
  }

  // proprietà dedicata alla gestione dei subscribe al destroy
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private skillService: SkillService,
    private certificationService: CertificationService,
    private callService: CallsService,
    private route: ActivatedRoute,
    private router: Router,
    private companiesService: CompaniesService,
    private commonService: CommonService
  ) {
    this.dataForm = this.fb.group({
      nome: new FormControl(null, Validators.required),
      codice: new FormControl(null, Validators.required),
      application_date: new FormControl(null, Validators.required),
      upload_date: new FormControl(null, Validators.required),
      tipologia: new FormControl(null, Validators.required),
      regole: new FormControl(null, Validators.required),
      descrizione: new FormControl(null, Validators.required),
      skills: new FormControl(null),
      certifications: new FormControl(null),
      settori: new FormControl([]),
      files: new FormControl(null),
    });
    this.callId = this.route.snapshot.paramMap.get('id')
  }

  ngOnInit(): void {
    //Recupero tutti i settori
    this.companiesService.getSettori()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.settoriList = res?.data || [];
        }
      });

    //Recupero tutte le competenze
    this.skillService.getStaticCompetenze()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.skillsList = res?.result || [];
        }
      });

    //Recupero tutte le certificazioni
    this.certificationService.getStaticCertificazioni()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.certificationsList = res?.result || [];
        }
      });

    if (this.callId != null) {
      this.title = 'Modifica opportunità';

      this.callService.showCall(this.callId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res: any): void => {
            const files = res.data.files.map((file: any) => ({
              uid: `${file.uid}`,
              name: file.name,
              status: 'done',
              url: file.url
            }));

            this.dataForm.patchValue({
              nome: res.data.name,
              codice: res.data.codice_iniziativa,
              application_date: res.data.application_date,
              upload_date: res.data.upload_date,
              tipologia: res.data.tipologia,
              regole: res.data.info_partecipation_call,
              descrizione: res.data.description,
              skills: res.data.skills.map((sk: any) => String(sk.id)),
              certifications: res.data.certifications.map((cr: any) => String(cr.id)),
              settori: res.data.settori.map((ss: any) => Number(ss.id)),
              files: files,
            });

            this.settori_selezionati = this.dataForm.get('settori')?.value;

            this.fileList = files;
            this.files = files;

            this.updateSkills = this.dataForm.get('skills')?.value;
            this.updateCertifications = this.dataForm.get('certifications')?.value;
            this.updateSettori = this.dataForm.get('settori')?.value;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    this.fileList = this.fileList.concat(file);
    const reader = new FileReader();
    reader.readAsDataURL(file as any);  // Assicurati che il cast sia appropriato
    reader.onload = () => {
      this.files.push({ base64: reader.result, name: file.name });
    };

    return false;  // Prevent automatic upload
  };

  handleChange(info: { file: NzUploadFile, fileList: NzUploadFile[] }): void {
    this.fileList = this.fileList.filter(file => file.name !== info.file.name);
    this.files = this.files.filter(file => file.name !== info.file.name);

  }

  save(): void {

    if (this.dataForm.valid && this.callId == null) {
      this.dataForm.patchValue({
        application_date: [moment(this.dataForm.get('application_date')?.value[0]).format('YYYY-MM-DD'), moment(this.dataForm.get('application_date')?.value[1]).format('YYYY-MM-DD')],
        upload_date: [moment(this.dataForm.get('upload_date')?.value[0]).format('YYYY-MM-DD'), moment(this.dataForm.get('upload_date')?.value[1]).format('YYYY-MM-DD')],
        files: this.files
      });

      this.callService.storeCall(this.dataForm.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res: any): void => {
            this.commonService.openToastAlert('Opportunità creata', true, {
              position: 'top-end',
              icon: 'success',
              iconColor: '#0dd30d',
            });

            this.router.navigate(['dashboard']);
            this.dataForm.reset();
            this.fileList = [];
          },
          error: (err: HttpErrorResponse) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#f03506',
            });
          }
        });
    } else if (this.dataForm.valid && this.callId != null) {

      this.dataForm.patchValue({
        application_date: [moment(this.dataForm.get('application_date')?.value[0]).format('YYYY-MM-DD'), moment(this.dataForm.get('application_date')?.value[1]).format('YYYY-MM-DD')],
        upload_date: [moment(this.dataForm.get('upload_date')?.value[0]).format('YYYY-MM-DD'), moment(this.dataForm.get('upload_date')?.value[1]).format('YYYY-MM-DD')],
        files: this.files
      });

      this.callService.updateCall(this.callId, this.dataForm.value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res: any): void => {
            this.commonService.openToastAlert('Opportunità modificata', true, {
              position: 'top-end',
              icon: 'success',
              iconColor: '#0dd30d',
            });
            this.router.navigate(['dashboard']);
          },
          error: (err: HttpErrorResponse) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#f03506',
            });
          }
        });

    }
    else {
      this.commonService.openToastAlert('Compila tutti i campi', true, {
        position: 'top-end',
        icon: 'error',
        iconColor: '#f03506',
      });
    }
  }

  goTo(uri: string, event: Event) {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.router.navigate([uri]);
  }

  onCertificationsChange($event: any) {
    this.callService.callMatch($event, 0)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.displayedColumns = res.data.displayColumns;
          this.displayedColumns.unshift('Ragione sociale');
          this.certificationsParamsForDatatable = res.data.ids;
          this.matchingCount = res.total;
        }
      });
  }

  onSkillsChange($event: any) {
    this.callService.callMatch($event, 1)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.displayedColumns2 = res.data.displayColumns;
          this.displayedColumns2.unshift('Ragione sociale');
          this.skillsParamsForDatatable = res.data.ids;
          this.matchingCount2 = res.total;
        }
      });
  }

  get settoriControl(): FormControl {
    return this.dataForm.get('settori') as FormControl;
  }

  get certificationsControl(): FormControl {
    return this.dataForm.get('certifications') as FormControl;
  }

  get skillsControl(): FormControl {
    return this.dataForm.get('skills') as FormControl;
  }

  /**
   * Non permette a application_date di superare upload_date
   * @param number current value of the minimum number of employees
   *  */
  onValue1Change(event: any) {
    if (event) {
      // permette un controllo solo per data, ignorando l'ora
      const end1 = event[1] ? new Date(event[1]).setHours(0, 0, 0, 0) : null;
      const start2 = this.dataForm.controls['upload_date'].value?.[0] ? new Date(this.dataForm.controls['upload_date'].value[0]).setHours(0, 0, 0, 0) : null;

      if (end1 && start2 && end1 >= start2) {
        this.dataForm.controls['upload_date'].setErrors({ invalidRange: true });
      } else {
        this.dataForm.controls['upload_date'].setErrors(null);
      }
    }
  }

  /**
   * Non permette a upload_date di scendere sotto application_date
   * @param number current value of the maximum number of employees
   *  */
  onValue2Change(event: any) {
    if (event) {
      // permette un controllo solo per data, ignorando l'ora
      const start2 = event[0] ? new Date(event[0]).setHours(0, 0, 0, 0) : null;
      const end1 = this.dataForm.controls['application_date'].value?.[1] ? new Date(this.dataForm.controls['application_date'].value[1]).setHours(0, 0, 0, 0) : null;

      if (end1 && start2 && end1 >= start2) {
        this.dataForm.controls['upload_date'].setErrors({ invalidRange: true });
      } else {
        this.dataForm.controls['upload_date'].setErrors(null);
      }
    }
  }
}
