import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss']
})
export class MultiSelectComponent {
    private _selected_data: any[] = [];

    @Input() data: any[] = [];
    @Input() dataControl!: FormControl<any[]>;
    @Input() label: string | null = null;
    @Input() placeholder: string | null = null;
    @Input() disable: boolean = false;
    @Input() icon: string | null = null;
    @Input() name_key: string = 'name';

    @Output() selected_dataChange = new EventEmitter<any[]>();

    @Input()
    get selected_data(): any[] {
        return this._selected_data;
    }
    set selected_data(value: any[]) {
        this._selected_data = value;
    }

    getSelection(data:  any[], selected_data:  any[]) {
        return data.filter((el:  any) => selected_data.includes(el.id));
    }

    changeSelection(id: number, event?: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        this.selected_data = this.selected_data.filter(selectedId => selectedId !== id);
    }

    onSelectionChange(selected: any[]) {
        this.selected_data = selected;

        // lancio l'emit ritornando il "_selected_data" aggiornato ad ogni "change" del model "selected_data" agganciato alla multiselect
        setTimeout(() => this.selected_dataChange.emit(this._selected_data));
    }
}
