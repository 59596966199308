import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CertificationService } from 'src/app/shared/services/certification.service';
import * as moment from 'moment';
import { finalize, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-modal-cert-risorse',
  templateUrl: './modal-cert-risorse.component.html',
  styleUrls: ['./modal-cert-risorse.component.scss']
})
export class ModalCertRisorseComponent implements OnInit, OnDestroy {

  certificationResourcesForm: FormGroup;
  fileList: NzUploadFile[] = [];
  fileListRender: NzUploadFile[] = [];
  availableCertification: any;
  reset: boolean = false;
  files: any[] = [];
  action!: string;
  filesCount: number = 0;
  search: string = "";
  newCertificationId: number | null = null;
  certificationResourcesFlag: boolean = false;
  oldCertificationName: string = '';
  isLoading: boolean = false;
  unsubscribe: Subject<void> = new Subject();
  customLabel: string = '';

  // serve a controllare l'attuale stringa scritta nella barra di ricerca della select Certificazione
  searchTermResources: string = '';

  // serve ad aggiungere l'option personalizzata dopo le operazioni di filtro di nz-select
  filteredCertification: any[] = [];

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private certService: CertificationService,
    private commonService: CommonService,
    @Inject(NZ_MODAL_DATA) public data: any,
    @Inject(NZ_MODAL_DATA) public update: boolean = false
  ) {
    this.certificationResourcesForm = this.fb.group({
      id_certificazione: new FormControl(null, Validators.required),
      numero_risorse: new FormControl(null, Validators.required),
      data_di_rilascio: new FormControl(null),
      data_fine_validita: new FormControl(null),
      nome: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.action = this.data.data == '' ? 'Aggiungi' : 'Modifica';
    this.getAvailableCertification(this.data.data.id_certificazione ?? '');

    if (this.data.update) {
      this.certificationResourcesForm.patchValue({
        id_certificazione: this.data.data.id_certificazione,
        numero_risorse: this.data.data.Risorse,
        data_di_rilascio: this.data.data.start_date,
        data_fine_validita: this.data.data.end_date
      })

      this.fileList = this.data.data.files;
      this.files = this.data.data.files;
      this.filesCount = this.data.data.files.length;
    }

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  getAvailableCertification(id_selected_certification: string): void {
    this.isLoading = true;
    this.certService.getAvailableCertificationResources({ selectedCert: id_selected_certification })
      .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
      .subscribe({
        next: (res) => {
          this.availableCertification = res.data;
        }
      });
  }

    handleSearchChange(value: string): void {
        this.searchTermResources = value;
        this.customLabel = `<span class="custom-label">${this.searchTermResources} (Personalizzata)</span>`;
    }

  // Caricamento file dalla modale di caricamento
  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    // Inizializza la variabile di ritorno
    let canUpload = true;

    // Verifica se il numero massimo di file è stato raggiunto
    if (this.filesCount === this.certificationResourcesForm.controls['numero_risorse'].value) {
      this.commonService.openToastAlert("Puoi caricare un numero di file pari al numero di risorse indicato", true, {
        position: 'top-end',
        icon: 'error',
        iconColor: '#f03506',
      });
      
      // Imposta canUpload su false
      canUpload = false;
    } else {
      // Aggiungi solo il nuovo file all'array fileList
      this.fileList = this.fileList.concat(file);
      this.filesCount++;

      // Processa solo il nuovo file
      const reader = new FileReader();
      reader.readAsDataURL(file as any);  // Assicurati che il cast sia appropriato
      reader.onload = () => {
        this.files.push({ base64: reader.result, name: file.name });
      };

      // Imposta canUpload su false per prevenire ulteriori upload automatici
      canUpload = false;
    }

    // Ritorna il valore di canUpload
    return canUpload;
  };

  // Cancellazione file dalla modale inserimento
  handleChange(info: { file: NzUploadFile, fileList: NzUploadFile[] }): void {

    this.files = this.files.filter(file => file.name !== info.file.name);
    this.fileList = this.fileList.filter(file => file.name !== info.file.name);
    this.filesCount--;
  }



  sendFile(): void {
    if (this.filesCount > this.certificationResourcesForm.controls['numero_risorse'].value) {
      this.commonService.openToastAlert("Puoi caricare un numero di file pari al numero di risorse indicato", true, {
        position: 'top-end',
        icon: 'error',
        iconColor: '#f03506',
      });

      return;
    }

    if (this.certificationResourcesForm.valid) {
      const paramsToSend: any = {
        data: this.files,
        numero_risorse: this.certificationResourcesForm.get('numero_risorse')?.value,
        id_certificazione: this.certificationResourcesForm.get('id_certificazione')?.value,
        data_di_rilascio: this.certificationResourcesForm.get('data_di_rilascio')?.value != null ? moment(this.certificationResourcesForm.get('data_di_rilascio')?.value,).format('YYYY-MM-DD') : null,
        data_fine_validita: this.certificationResourcesForm.get('data_fine_validita')?.value != null ? moment(this.certificationResourcesForm.get('data_fine_validita')?.value).format('YYYY-MM-DD') : null
      }

      if (this.data.update) {
        this.isLoading = true;
        this.certService.updateResourceCertification(this.data.data.id, paramsToSend)
          .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
          .subscribe({
            next: (res) => {
              this.commonService.openToastAlert("Certificazione modificata", true, {
                position: 'top-end',
                icon: 'success',
                iconColor: '#0dd30d',
              });

              this.certService.updateDataTable();
              this.modal.destroy()
            },
            error: (err: any) => {
              this.commonService.openToastAlert(err?.message, true, {
                position: 'top-end',
                icon: 'error',
                iconColor: '#f03506',
              });
            }
          });
      } else {
        paramsToSend.nome = this.certificationResourcesForm.get('nome')?.value;
        this.isLoading = true;
        this.certService.addResourceCertification(paramsToSend)
          .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
          .subscribe({
            next: (res) => {
              this.commonService.openToastAlert("Certificazione inserita", true, {
                position: 'top-end',
                icon: 'success',
                iconColor: '#0dd30d',
              });

              this.certService.updateDataTable();
              this.modal.destroy();
            },
            error: (err: any) => {
              this.commonService.openToastAlert(err?.message, true, {
                position: 'top-end',
                icon: 'error',
                iconColor: '#f03506',
              });
            }
          });
        }
    } else {
      this.commonService.openToastAlert("Compila tutti i campi", true, {
        position: 'top-end',
        icon: 'error',
        iconColor: '#f03506',
      });
    }
  }

  handleCancel(): void {
    this.modal.destroy();
  }

    saveNewResourcesCertification(event: any): void {
        if(event === -1 && !this.certificationResourcesFlag) {
            this.certificationResourcesFlag = true;
            this.certificationResourcesForm.patchValue({
                id_certificazione: -1,
                nome: this.searchTermResources
            });
        } else if(event != -1) {
            this.certificationResourcesFlag = false;
        }
    }

    filterOptionResources = (input: string, option: any): boolean => {
        if (option.nzLabel === null) {
            if (this.searchTermResources !== input) {
                this.searchTermResources = input;
            }
            return true;
        }
        return option.nzLabel?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    }
}
