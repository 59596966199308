<div class="login">
    <div class="row mx-0 px-0">
        <div class="login__box-logo col-12 col-lg-4 px-0"></div>
        <div class="login__box-form col-12 col-lg-8 px-0">
            <form [formGroup]="formCreate">
                <div class="box-form-content">
                    <div class="box-form-content__header">
                        <p class="header-title">Portale delle opportunità</p>
                        <p class="header-subtitle">Ciao! accedi al portale</p>
                    </div>
                    <div class="box-form-content__form">
                        <div class="box-input-email">
                            <input type="email" class="form-control" placeholder="Email" formControlName="mail" required
                                [ngClass]="{'input-error': !formCreate.controls['mail'].valid && formCreate.controls['mail'].touched}">
                            <div class="input-error-text"
                                *ngIf="formCreate.controls['mail'].touched && formCreate.controls['mail'].hasError('required')">
                                {{"campo obbligatorio" | translate}}
                            </div>
                            <div class="input-error-text" *ngIf="formCreate.controls['mail'].hasError('pattern')">
                                {{"email non valida" | translate}}
                            </div>
                        </div>
                        <div class="box-input-pwd">
                            <input type="password" class="form-control" placeholder="Password" formControlName="password"
                                required
                                [ngClass]="{'input-error': !formCreate.controls['password'].valid && formCreate.controls['password'].touched}">
                            <div class="input-error-text"
                                *ngIf="formCreate.controls['password'].touched && formCreate.controls['password'].hasError('required')">
                                {{"campo obbligatorio" | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-form-cta">
                    <button type="button" class="box-form-cta__submit call-button" (click)="login()">Accedi</button>
                    <div class="box-form-cta__box-forgot-pwd text-center">
                        <a href="/recupero-password" (click)="setRedirect()" class="text-muted">Password dimenticata</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>