import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DocumentazioneService } from 'src/app/shared/services/documentazione.service';
import { ModalUploadDocumentationComponent } from 'src/app/components/modals/modal-upload-documentation/modal-upload-documentation.component';
import { ModalCreateDocumentationComponent } from 'src/app/components/modals/modal-create-documentation/modal-create-documentation.component';

@Component({
  selector: 'app-documentazione-azienda',
  templateUrl: './documentazione-azienda.component.html',
  styleUrls: ['./documentazione-azienda.component.scss']
})
export class DocumentazioneAziendaComponent implements OnInit, OnDestroy {

  /**
    * Id della call
    */
  @Input() callId!: number;

  /**
    * Id dell'azienda visualizzata lato eht
    */
  @Input() azienda_id!: string | null;

  searchForm!: FormGroup;
  displayedColumns: any[] = ['Documentazione', 'Tipologia', 'Allegati'];
  endpointPdf: string = 'pdf'
  endpoint: string = "documentazione/getDocumentazione"
  params: any = [];
  search: any;
  unsubscribe: Subject<void> = new Subject();
  canUpload: boolean = false;

  constructor(
    private docService: DocumentazioneService,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['']
    });

    //mi sottoscrivo a search per gestire la barra di ricerca
    this.searchForm.get('search')?.valueChanges
      .pipe(
        debounceTime(1000),  // Imposta il delay in millisecondi
        distinctUntilChanged(),  // Evita duplicati consecutivi
        takeUntil(this.unsubscribe)  // Interrompe l'osservazione quando il componente viene distrutto
      )
      .subscribe(value => {
        this.onSearch(value);
      });

    //preparo i parametri da passare alla datatable per recuperare la documentazione
    this.params.push({ 'call_id': this.callId, 'azienda_id': null });

    //recupero il flag per gestire il pulsante di caricamento documentazione facoltativa
    this.checkUploadData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSearch(searchValue: string): void {
    this.search = searchValue;
  }

  //Controlla se è possibile caricare la documenetazione. Ritorna un flag che gestisce il pulsante di upload
  checkUploadData(): void {
    this.docService.checkUploadData(this.callId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any): void => {
          this.canUpload = res.data
        }
      });
  }

  openDocModal(element: any): void {
    this.modalService.create({
      nzContent: ModalUploadDocumentationComponent,
      nzData: element,
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: '640px'
    });
  }

  //Apre la modale di creazione documentazione facoltativa
  openModal(event?: Event): void {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.modalService.create({
      nzContent: ModalCreateDocumentationComponent,
      nzData: this.callId,
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: '640px'
    });
  }

  deletedElement(element: any) {
    this.docService.deleteDocumentazioneFacoltativa(element)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any): void => {
          this.docService.updateDataTable();
          this.commonService.openToastAlert(res.message, true, {
            position: 'top-end',
            icon: 'success',
            iconColor: '#0dd30d',
          });
        },
        error: (error) => {
          this.commonService.openToastAlert(error?.message, true, {
            position: 'top-end',
            icon: 'error',
            iconColor: '#ff0000',
          });
        }
      });
  }
}
