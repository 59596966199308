import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from 'src/environments/environment';

const ParticipationURL = `${environment.ApiURL}/participation/`;

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {
  

  constructor(private http: HttpClient) { }

  // accetta/rifiuta la partecipazione di un'azienda ad una call
  manageParticipation(params: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(ParticipationURL + 'manageParticipation', params);
  }

  saveParticipationNote(params: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(ParticipationURL + 'storeNote', params);
  }

  getParticipationNote(id: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(ParticipationURL + 'getNote/' + id);
  }

}
