import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, finalize, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';
import { CertificationService } from 'src/app/shared/services/certification.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ProdottoService } from 'src/app/shared/services/prodotto.service';
import { CompaniesService } from 'src/app/shared/services/companies.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileService } from 'src/app/shared/services/file.service';
import { DocumentazioneService } from 'src/app/shared/services/documentazione.service';
@Component({
  selector: 'drw-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnDestroy {
  /**
   * Mostra/nasconde il loader della datatable (di default a false)
   */
  @Input() isLoading: boolean = false;

  /**
   * L'array dei nomi delle colonne da mostrare nella tabella.
   */
  @Input() displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];

  /**
   * L'array dei nomi delle colonne da mostrare in "sticky".
   * NB: Si basa sullo stesso array "displayedColumns" ma con la differenza che si devono indicare i nomi delle
   * colonne che devono essere in "sticky"
   */
  @Input() set stickyDisplayedColumns(stickyDisplayedColumnsList: string[]) {
    this.innerStickyDisplayedColumnsList = stickyDisplayedColumnsList?.map((column: string) => column?.trim()?.toLowerCase()) || [];
  };

  /**
   * EndPoint della chiamata API per popolare la tabella.
   */
  @Input() endpointDataSource!: string | Array<any>;

  /**
   * Mi dice il metodo della chiamata
   */
  @Input() method: 'post' | 'get' | 'put' | 'delete' = 'post';

  /**
   * Flag booleano che permette di rendere visibile la colonna 'azioni'.
   */
  @Input() actions: boolean = false;

  /**
   * È un oggetto con dei valori booleani che permettono di far visualizzare a schermo i diversi bottoni azioni.
   */
  @Input() actionsButton = { download: true, info: true, modify: true, trash: true, accept: false, refuse: false, upload: false };

  /**
   * Flag booleano che permette di visualizzare il campo di ricerca.
   */
  @Input() isFilter: boolean = false;

  /**
   * Flag booleano che permette di attivare l'ordinamento della tabella.
   */
  @Input() isSortable: boolean = false;

  /**
   * Array di colonne che possono essere soggette ad ordinamento.
   */
  @Input() sortableColumns: string[] = [];

  /**
   * EndPoint della chiamata API per la stampa in formato PDF.
   */
  @Input() endpointPdf!: string;

  /**
   * EndPoint della chiamata API per l'export in formato EXCEL.
   */
  @Input() endpointExcel!: string;

  /**
   * Variabile che permette di ricaricare le colonne.
   */
  @Input() renderColumns!: BehaviorSubject<string>;

  /**
   * Se utilizzare un layout di tabella fisso. L'abilitazione
   * di questa opzione applicherà larghezze di colonna coerenti
   * e ottimizzerà il rendering degli stili permanenti per le tabelle native.
   */
  @Input() fixedLayout: boolean = false;

  @Input() enableNavigation: boolean = false;


  /**
   * Evento che passa la data dell'ultimo aggiornamento.
   */
  @Output() lastUpdate: EventEmitter<any> = new EventEmitter<any>();

  /**
 * Evento che passa il conteggio delle proprie opportunità.
 */
  @Output() countPersonalCall: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id dell'elemento da modificare.
   */
  @Output() editedElement: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id dell'elemento da eliminare.
   */
  @Output() deletedElement: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id della proposta da rifiutare.
   */
  @Output() openNoteModal: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa i dati per aprire la modale di upload file per documentazione.
   */
  @Output() openDocModal: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa i dati per aprire la modale di visualizzazione file per documentazione.
   */
  @Output() openShowDocModal: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Evento che passa l'id della partecipazione da accettare/rifiutare.
   */
  @Output() manageParticipation: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Parametri aggiuntivi da inviare nella request'.
   */
  @Input() additionalParams: [] = [];

  /**
   * Flag booleano che permette al be di capire qualche batch_id utilizzare.
   */
  @Input() flagZip: string = '';

  /**
 * Variabile custom per cambiare il numero di pagine iniziali.
 */
  @Input() customPageSize: number = 0;

  dataSource!: any;

  // * Paginator
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  showFirstLastButtons: boolean = true;
  imgSrc: string = '';

  @Input() search: any = "";
  // * Testi eliminazione elemento
  title!: string;
  text!: string;
  cancelButton!: string;
  confirmButton!: string;

  functionActivated = false;

  innerStickyDisplayedColumnsList: string[] = [];

  private unsubscribe: Subject<void> = new Subject();
  private reloadDatatable!: Subscription;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private certificationService: CertificationService,
    private paginatorIntl: MatPaginatorIntl,
    private prodottoService: ProdottoService,
    private companiesService: CompaniesService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private docService: DocumentazioneService
  ) {
    this.translate.get(['DELETE_CONFIRMATION.TITLE', 'DELETE_CONFIRMATION.TEXT', 'DELETE_CONFIRMATION.CONFIRM', 'DELETE_CONFIRMATION.DISMISS']).subscribe(messages => {
      this.confirmButton = messages['DELETE_CONFIRMATION.CONFIRM'];
      this.cancelButton = messages['DELETE_CONFIRMATION.DISMISS'];
    });
  }

  ngOnInit(): void {

    if (this.customPageSize != 0) {
      this.pageSize = this.customPageSize;
    }

    this.reloadDatatable = this.certificationService.reload$.subscribe(() => {
      this.getData();
    });
    this.reloadDatatable = this.docService.reload$.subscribe(() => {
      this.getData();
    });
    this.reloadDatatable = this.prodottoService.reload$.subscribe(() => {
      this.getData();
    });
    this.reloadDatatable = this.companiesService.reload$.subscribe(() => {
      this.getData();
    });
    this.paginatorIntl.itemsPerPageLabel = 'Elementi per pagina';
    this.paginatorIntl.previousPageLabel = 'Pagina precedente';
    this.paginatorIntl.nextPageLabel = 'Pagina successiva';
    this.paginatorIntl.firstPageLabel = 'Prima pagina';
    this.paginatorIntl.lastPageLabel = 'Ultima pagina';
    this.title = 'Vuoi davvero\neliminare l’elemento?';

    this.paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 di ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} di ${length}`;
    };

    if (this.actions && !this.displayedColumns.includes('azioni')) {
      this.displayedColumns.push('azioni');
    }

    this.renderColumns?.subscribe((value: string) => {
      if (value) this.getData();
    });
  }

  ngOnChanges(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigateTo(row: any) {
    if (this.enableNavigation) {
      this.router.navigate([row.url]); //row.url da mappare BE
    }
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData({ skip: this.pageIndex * this.pageSize, take: this.pageSize })
    return event;
  }
  getData(params?: any) {
    if ((typeof this.endpointDataSource) === 'string') {
      // searchColumns: 'all': implementare passare il parametro searchColumns da dove viene richiamata la datatable
      if (this.search) {
        params = {
          search: this.search,
          data: this.additionalParams
        };
      }
      else if (this.additionalParams.length > 0) {
        params = {
            data: this.additionalParams
         };

      }
      if(params){
        params.skip = this.pageIndex * this.pageSize;
        params.take = this.pageSize;
      }

      switch (this.method) {
        case 'get': {
          this.isLoading = true;
          this.http.get<FormattedResponse<any>>(`${environment.ApiURL}/${this.endpointDataSource}`, { params })
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
              next: (response) => {
                try {
                  this.dataSource = response?.data ? JSON.parse(JSON.stringify(response.data)) : null;

                  if (response.last) this.lastUpdate.emit(response.last);

                  this.length = response.total ?? 0;
                } catch (e) {
                  console.error(e);
                }
              }
            });
          break;
        }
        case 'put': {
          this.isLoading = true;
          this.http.put<FormattedResponse<any>>(`${environment.ApiURL}/${this.endpointDataSource}`, params)
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
              next: (response) => {
                this.dataSource = response?.data ? JSON.parse(JSON.stringify(response.data)) : null;

                if (response.last) this.lastUpdate.emit(response.last);

                this.length = response.total ?? 0;
              }
            });
          break;
        }
        case 'delete': {
          this.isLoading = true;
          this.http.delete<FormattedResponse<any>>(`${environment.ApiURL}/${this.endpointDataSource}`)
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
              next: (response) => {
                this.dataSource = response?.data ? JSON.parse(JSON.stringify(response.data)) : null;

                if (response.last) this.lastUpdate.emit(response.last);

                this.length = response.total ?? 0;
              }
            });
          break;
        }
        default: {
          this.isLoading = true;
          this.http.post<FormattedResponse<any>>(`${environment.ApiURL}/${this.endpointDataSource}`, params)
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
              next: (response) => {
                this.dataSource = response?.data ? JSON.parse(JSON.stringify(response.data)) : null;

                if (response.last) this.lastUpdate.emit(response.last);
                this.countPersonalCall.emit(response.count_personal_call);
                this.length = response.total ?? 0;
              }
            });
        }
      }
    } else {
      this.length = <any>this.endpointDataSource.length;
      this.dataSource = this.endpointDataSource.slice(params ? params?.skip : 0, (params) ? (params?.skip + params?.take) : 10);
    }
  }

  editElement(element: any) {
    this.editedElement.emit(element);
  }

  deleteElement(id: any) {
    this.deletedElement.emit(id);
  }

  sortData(sort: Sort) {
    this.getData({ skip: this.pageIndex * this.pageSize, take: this.pageSize, sort: sort });
  }

  sortableHeader(headerName?: string): boolean {
    if (this.isSortable && headerName != 'azioni') return true;
    if (this.sortableColumns.findIndex(element => element == headerName) != -1) return true;
    return false;
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  startsWithData(column: string): boolean {
    return column.startsWith('Data');
  }

  openLink(row: any) {
    this.certificationService.downloadFile(row.file_storage.id).subscribe({
      next: (response) => {

        // Crea un URL per il blob
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);

        // Crea un link temporaneo
        const a = document.createElement('a');
        a.href = url;

        // Estrai il nome del file dal backend o usa un nome predefinito
        let filename = row.file_storage.path;
        a.download = filename;

        // Aggiungi e clicca il link per avviare il download
        document.body.appendChild(a);
        a.click();

        // Rimuovi il link e libera l'URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.error('Download failed:', error);
      }
    });
  }

  exportData(type: 'excel' | 'pdf' | 'template', file: any, event?: any): void {
    if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
      switch (type) {
        case 'excel':
          this.http.get(`${environment.ApiURL}/${this.endpointExcel}`);
          break;
        case 'pdf':
          lastValueFrom(this.certificationService.dowloadSingleFile(`${environment.ApiURL}/${this.endpointPdf}/${file.file_storage_id}`))
            .then((blob: Blob) => {
              const url = window.URL.createObjectURL(blob);
              window.open(url, '_blank');
            });
          break;
        case 'template': {
          window.open(environment.ApiStorage + '/' + file, '_blank');
          break;
        }
        default:
          console.log('Formato non corretto');
          break;
      }
    }
  }

  downloadZip(row: any, event?: any): void {

    if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
      lastValueFrom(this.fileService.downloadZip({ id: row.id }, { flag: this.flagZip }))
        .then((blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'files.zip';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error: any) => {
          console.error('Download error:', error);
        });
    }
  }

  isValidDate(dateString: string): boolean {
    const date = Date.parse(dateString);
    return !isNaN(date);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  //accetta o rifiuta l'adesione di un'azienda ad una opportunità
  manageResponse(row: any, action: any, event?: Event) {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.manageParticipation.emit({ data: row, action: action });
  }

  //Apre la modale di note lato eht/azienda
  openModal(element: any, flag: any, event?: Event) {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    element.flag = flag;
    this.openNoteModal.emit(element);
  }

  //Apre la modale di caricamento file per la documentazione (lato azienda)
  openUploadModal(row: any, event?: Event) {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.openDocModal.emit(row);
  }

  showDocModal(row: any, event?: Event) {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return;
    }
    event.preventDefault();
    this.openShowDocModal.emit(row);
  }
}
