<!-- begin: Loader pagina -->
<div *ngIf="loading" class="loading-container flex-content-center flex-center">
  <nz-spin [nzSize]="'large'" [nzIndicator]="spinIndicator" nzSimple class="m-auto"></nz-spin>
  <ng-template #spinIndicator>
    <img src="assets/img/loader-spinner.gif" alt="Loader" />
  </ng-template>
</div>
<!-- end: Loader pagina -->

<div id="page-body-elm" class="page-body flex-column justify-content-between"
  [ngClass]="{'d-flex' : components.sidebar, 'd-block' : !components.sidebar}">
  <div *ngIf="components.sidebar; else noSidebar" class="d-flex" [class.flex-row]="!(isMobileWithTablet$ | async)"
    [class.flex-column]="(isMobileWithTablet$ | async)">
    <app-sidebar *ngIf="components.sidebar"></app-sidebar>
    <div class="page-body__content d-flex flex-column flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </div>
  <ng-template #noSidebar>
    <div class="page-body__content page-body__content--no-sidebar">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</div>