import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CallsService } from 'src/app/shared/services/calls.service';

@Component({
  selector: 'app-opportunita-personali-aside',
  templateUrl: './opportunita-personali-aside.component.html',
  styleUrls: ['./opportunita-personali-aside.component.scss']
})
export class OpportunitaPersonaliAsideComponent {
    opportunities: any;
    newOpportunities: any;
    currentOpportunityIndex: number = 0;
    currentNewOpportunityIndex: number = 0;
    currentOpportunity: any;

    constructor(private router: Router, private callService: CallsService) { }

    ngOnInit(): void {
        this.yourCall();
    }

    yourCall(): void {
        this.callService.yourCall().subscribe({
            next: (res) => {
                this.opportunities = res.data.personal_calls;
                this.newOpportunities = res.data.last_calls;
                console.log(this.opportunities);
                console.log(this.newOpportunities);
            }
        });
    }

    goTo(uri: string, id?: any) {
        id ? this.router.navigate([uri + id]) : this.router.navigate([uri]);
    }

    prev() {
        if (this.currentOpportunityIndex > 0) {
            this.currentOpportunityIndex -= 1;
        }
    }

    next() {
        if (this.currentOpportunityIndex < this.opportunities.length - 1) {
            this.currentOpportunityIndex += 1;
        }
    }

    prevPersonal() {
        if (this.currentNewOpportunityIndex > 0) {
            this.currentNewOpportunityIndex -= 2;
        }
    }

    nextPersonal() {
        if (this.currentNewOpportunityIndex < this.newOpportunities.length - 2) {
            this.currentNewOpportunityIndex += 2;
        }
    }
}
