import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Credentials } from 'src/app/shared/models/credentials.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {

  formCreate: FormGroup;

  // Variabile dedicata alla gestione dei subscribe al destroy
  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.formCreate = new FormGroup({
      password: new FormControl(null, Validators.required),
      mail: new FormControl(null, [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setRedirect(): void {
    localStorage.setItem('recover', 'true');
  }

  login(): void {
    const credentials: Credentials = { 'email': '', 'password': '' };
    Object.assign(credentials, { 'email': this.formCreate.get('mail')?.value })
    Object.assign(credentials, { 'password': this.formCreate.get('password')?.value })

    if (this.formCreate.valid) {
      this.authService.login(credentials)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            this.router.navigate(['/dashboard']);
            // TODO this.toastService.generateToast({ icon: 'success', title: null, text: 'Login Effettuato' })
            localStorage.setItem("user_id", res.id)
            localStorage.setItem("token", res.token.original.access_token)
            this.authService.getUser(true)
            this.authService.currentUser.next(res);

          },
          error: (err) => {
            this.commonService.openToastAlert(err?.message, true, {
              position: 'top-end',
              icon: 'error',
              iconColor: '#ff0000',
            });
          }
        });
    }
  }
}
