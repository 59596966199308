<div class="multiselect">
    <label class="input-label" for="data" *ngIf="label">{{label}}</label>
    <div class="select-container">
        <nz-select [nzDisabled]="disable" style="overflow-x: auto; white-space: nowrap;" [formControl]="dataControl" [nzMaxTagCount]="0"
            nzMode="multiple" [nzPlaceHolder]="placeholder" [ngClass]="{
                'input-error': !dataControl.valid && dataControl.touched
                }" [(ngModel)]="selected_data" [nzAllowClear]="true" (ngModelChange)="onSelectionChange($event)">
            <nz-option *ngFor="let el of data" [nzLabel]="el[name_key]" [nzValue]="el.id"></nz-option>
        </nz-select>
    </div>
    <div class="text-sm text-danger" style="font-size: 11px;"
        *ngIf="dataControl.touched && dataControl.hasError('required')">
        {{"Indicare almeno un elemento" | translate}}
    </div>
    <div class="d-flex flex-wrap gap-2">
        <div class="alert d-flex chip-box m-0 mt-2 gap-3 align-items-center"
            *ngFor="let el of getSelection(data, selected_data)" role="alert">
            <img *ngIf="icon" class="box_alt" src="assets/icons/{{icon}}" alt="">
            <p class="chips m-0">{{ el[name_key] }}</p>
            <button [disabled]="disable" class="custom-button box" type="button" (click)="changeSelection(el.id, $event)"
            (keydown)="changeSelection(el.id, $event)">
                <img [hidden]="disable" src="assets/icons/delete_X.svg" alt="">
            </button>
        </div>
    </div>
</div>
