import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-section-page',
  templateUrl: './header-section-page.component.html',
  styleUrls: ['./header-section-page.component.scss']
})
export class HeaderSectionPageComponent {
  // titolo sezione
  @Input() title: string = "Titolo";

  // descrizione sezione
  @Input() description: string = "Descrizione sezione";
}
