import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';

const AziendaURL = `${environment.ApiURL}/azienda/certificazioni/`;
const RisorseURL = `${environment.ApiURL}/risorse/certificazioni/`;

@Injectable({
    providedIn: 'root'
})
export class CertificationService {

    private reloadSubject = new Subject<void>();

    reload$ = this.reloadSubject.asObservable();

    constructor(private http: HttpClient) { }

    updateDataTable() {
        this.reloadSubject.next();
    }

    // inizio API Azienda
    getAvailableCertification(params?: any): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(AziendaURL + `getAvailableCertification`, params);
    }

    addAziendaCertification(params: any): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(AziendaURL + `store`, params);
    }

    updateAziendaCertification(id: any, params: any,): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(AziendaURL + `update/${id}`, params);
    }

    dowloadSingleFile(endpoint: string): Observable<Blob> {
        const headers = new HttpHeaders();
        return this.http.get<Blob>(endpoint, { headers, responseType: 'blob' as 'json' });
    }

    deleteCertAziendale(id: string): Observable<FormattedResponse<any>> {
        return this.http.delete<FormattedResponse<any>>(AziendaURL + `destroy/${id}`);
    }
    // fine API Azienda


    // API Risorse
    deleteCertRisorse(id: string): Observable<FormattedResponse<any>> {
        return this.http.delete<FormattedResponse<any>>(RisorseURL + `destroy/${id}`);
    }

    addResourceCertification(params: any): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(RisorseURL + `store`, params);
    }

    updateResourceCertification(id: any, params: any,): Observable<FormattedResponse<any>> {
        return this.http.patch<FormattedResponse<any>>(RisorseURL + `update/${id}`, params);
    }

    getAvailableCertificationResources(params?: any): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(RisorseURL + `getAvailableCertificationResources`, params);
    }

    downloadFile(id: any): Observable<Blob> {
        return this.http.post(`${AziendaURL}download`, { id: id }, { responseType: 'blob' });
    }

    getStaticCertificazioni(): Observable<FormattedResponse<any>> {
        return this.http.get<FormattedResponse<any>>(RisorseURL + `staticCertificazioni`);
    }

    //Funzioni

    addNewCertification(newCertificationId: any, availableCertification: any, certificationForm: any, name: any) {

        // Rimuove la precedente certificazione inserita
        if (newCertificationId !== null) {
            availableCertification = availableCertification.filter((cert: any) => cert.id !== newCertificationId);
        }
        // Aggiunge la certificazione nuova all'array di certificazioni
        const newCertification = {
            id: -1,
            Nome: name
        };
        availableCertification.push(newCertification);
        newCertificationId = newCertification.id;

        return { newCertificationId, availableCertification, certificationForm };
    }
}
