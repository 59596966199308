<div class="container modal-competenze">
    <h1 class="title">{{action}} Competenza</h1>
    <p class="campi-obbligatori">* Campi obbligatori</p>
    <form [formGroup]="skillsForm" (ngSubmit)="(nzModalData === null)?(onCreate()):(onUpdate())">
        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="24" class="mt-3">
                        <label class="label" for="competenza">Competenza</label>
                        <nz-select class="w-100" nzShowSearch nzAllowClear="false"
                            nzPlaceHolder="Seleziona una competenza" formControlName="competenza">
                            <nz-option *ngFor="let skill of (availableSkills | async)" [nzLabel]="skill.name"
                                [nzValue]="skill.id"></nz-option>
                        </nz-select>
                    </div>
                    <div nz-col [nzSpan]="24" class="mt-4">
                        <p class="section_title">Indicare il numero di risorse per livello di competenza:</p>
                    </div>

                    <app-legenda-competenze class="w-100"></app-legenda-competenze>

                    <div nz-col [nzSpan]="24" [nzMd]="8">
                        <label for="basse" class="label">Bassa *</label>
                        <input nz-input class="form-control" type="number" id="basse" formControlName="basse"
                            placeholder="Inserisci qui" min="0">
                        <div *ngIf="skillsForm.get('basse')?.invalid && skillsForm.get('basse')?.touched"
                            style="color:red" class="mb-3 mt-0">
                            <div *ngIf="skillsForm.get('basse')?.errors?.['required']">Il numero di risorse con
                                competenza
                                bassa
                                è richiesto
                            </div>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="24" [nzMd]="8">
                        <label for="medie" class="label">Media *</label>
                        <input nz-input class="form-control" type="number" id="medie" formControlName="medie"
                            placeholder="Inserisci qui" min="0">
                        <div *ngIf="skillsForm.get('medie')?.invalid && skillsForm.get('medie')?.touched"
                            style="color:red" class="mb-3 mt-0">
                            <div *ngIf="skillsForm.get('medie')?.errors?.['required']">Il numero di risorse con
                                competenza
                                media
                                è richiesto</div>
                        </div>
                    </div>
                    <div nz-col [nzSpan]="24" [nzMd]="8">
                        <label for="alte" class="label">Alta *</label>
                        <input nz-input class="form-control" type="number" id="alte" formControlName="alte"
                            placeholder="Inserisci qui" min="0">
                        <div *ngIf="skillsForm.get('alte')?.invalid && skillsForm.get('alte')?.touched"
                            style="color:red" class="mb-3 mt-0">
                            <div *ngIf="skillsForm.get('alte')?.errors?.['required']">Il numero di risorse con
                                competenza
                                alta è
                                richiesto</div>
                        </div>
                    </div>
                </div>
            </app-pcall-loader>
        </div>

        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" [disabled]="skillsForm.invalid"
                [nzLoading]="isLoading">Salva</button>
            <button nz-button nzType="default" type="button" (click)="handleCancel()"
                [nzLoading]="isLoading">Chiudi</button>
        </div>
    </form>
</div>