<div class="documentazione-azienda">
    <div class="container-fluid px-0 col-lg-12 col-sm-12 col-md-12 pb-0 pt-0">
        <div class="row align-items-center documentazione-azienda__header">
            <!-- Testo -->
            <div class="col-md-8 header-box-text">
                <p class="header-box-text__title">Documentazione Allegata</p>
            </div>

            <!-- Pulsante -->
            <div class="col-md-4 d-flex justify-content-end header-box-cta">
                <button type="button" class="btn btn-outline-primary" (click)="openModal($event)"
                    (keydown)="openModal($event)" [disabled]="!canUpload">Aggiungi documentazione facoltativa</button>
            </div>
        </div>

        <form [formGroup]="searchForm" class="documentazione-azienda__content-form mt-2 mb-2">
            <nz-input-group [nzPrefix]="prefixIconSearch">
                <input type="text" nz-input placeholder="Cerca documentazione..." formControlName="search" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>
        </form>
    </div>

    <drw-data-table class="documentazione-azienda__table" [displayedColumns]="displayedColumns"
        [endpointDataSource]='endpoint' [additionalParams]='params' [actions]="true"
        [actionsButton]='{ download: true, info: false, modify: false, trash: true, accept: false, refuse: false, upload: true }'
        [isFilter]="false" [endpointPdf]="endpointPdf" [search]="search" [flagZip]="'documentazione'"
        (openDocModal)="openDocModal($event)" (deletedElement)="deletedElement($event)">
    </drw-data-table>
</div>