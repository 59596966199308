<div class="modal-create-documentation container">
    <h1 class="title">Aggiungi documentazione facoltativa</h1>
    <form [formGroup]="docForm">

        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <div class="d-flex flex-column">
                    <label class="label" for="nome">Nome documentazione</label>
                    <input nz-input class="form-control" type="text" id="basse" formControlName="nome"
                        placeholder="Inserisci qui">
                </div>
            </app-pcall-loader>
        </div>

        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" [disabled]="buttonDisabled" [nzLoading]="isLoading"
                (click)="save()">Salva</button>
            <button nz-button nzType="default" type="button" [nzLoading]="isLoading"
                (click)="handleCancel()">Chiudi</button>
        </div>
    </form>
</div>