import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CallsService } from 'src/app/shared/services/calls.service';
import { HttpErrorResponse } from "@angular/common/http";
import { CertificationService } from 'src/app/shared/services/certification.service';
import { CommonService } from 'src/app/shared/services/common.service';
@Component({
    selector: 'app-dashboard-opportunita',
    templateUrl: './dashboard-opportunita.component.html',
    styleUrls: ['./dashboard-opportunita.component.scss']
})
export class DashboardOpportunitaComponent implements OnInit, OnDestroy {
    isAdmin: boolean = false;
    dynamicClass: string;
    displayedColumns: string[] = [];
    actionsButton = { download: false, info: true, modify: false, trash: false, accept: false, refuse: false, upload: false  };
    displayedFilters = [
        'Servizio', 'Data', 'Modalità'
    ];

    typologies = [
        { label: 'Commessa', value: 'Commessa' },
        { label: 'Gara', value: 'Gara' }
        // { label: 'Ricerca e Sviluppo', value: 'Ricerca e Sviluppo' }
    ];

    status = [
        { label: 'Aperta', value: 'Aperta' },
        { label: 'Chiusa', value: 'Chiusa' }
    ];

    unsubscribe: Subject<void> = new Subject();
    endpointPdf: string = 'pdf'
    endpoint: string = "typology/index"
    search: any;
    searchForm!: FormGroup;
    role_id: any;
    countPersonalCall= new BehaviorSubject<number>(0);

    /*
    Colonne della datatable opportunità
    */
    private opportunityDataTableColumns: string[] = ['Nome opportunità', 'Tipologia', 'Data inizio partecipazione', 'Data fine partecipazione', 'Stato', 'azioni'];
    private opportunityDataTableColumnsForMobile: string[] = ['Nome opportunità', 'azioni'];

    constructor(
        public authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private callService: CallsService,
        private certService: CertificationService,
        private commonService: CommonService) {
        this.isAdmin = (this.authService.currentUser?.value?.roles?.['0']?.id == 1);
        this.dynamicClass = this.isAdmin ? 'col-8' : 'col-12';
        this.role_id = this.authService.currentUser?.value?.roles?.[0]?.id;

        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.opportunityDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.opportunityDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.opportunityDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.opportunityDataTableColumnsForMobile));
                }
            });
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            search: [null],
            tipologia: [null],
            stato: [null]
        });
        if (this.role_id == 1) {
            this.actionsButton = { download: false, info: true, modify: true, trash: true, accept: false, refuse: false, upload: false  }
        }
        this.endpoint = "calls/getCall";
        this.searchForm.valueChanges.pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe)
            )
            .subscribe(value => {
                this.search = this.searchForm.value;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    } 

    countPersonalCallElement(value: any) {
        this.countPersonalCall.next(value);

    }

    goTo(uri: string, event : Event)
    { if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
        event.preventDefault();
        this.router.navigate([uri]);
      }
    }

    editCall($event: any) {
        this.router.navigate(['modifica-opportunita/' + $event['id']]);
    }

    deleteCall($event: any) {
        this.callService.deleteCall($event).pipe(takeUntil(this.unsubscribe)).subscribe({
            next: (res: any): void => {
                this.commonService.openToastAlert(res?.message, true, {
                    position: 'top-end',
                    icon: 'success',
                    iconColor: '#0dd30d',
                });
                this.certService.updateDataTable();

            },
            error: (err: HttpErrorResponse) => {
                this.commonService.openToastAlert(err?.message, true, {
                    position: 'top-end',
                    icon: 'error',
                    iconColor: '#0dd30d',
                });
            }
        });
    }
}
