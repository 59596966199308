import { Component, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Skill } from "../../../shared/models/skill.model";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { BehaviorSubject, finalize, Subject, takeUntil } from "rxjs";
import { SkillService } from "../../../shared/services/skill.service";
import { HttpErrorResponse } from "@angular/common/http";
import { CertificationService } from "../../../shared/services/certification.service";
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
    selector: 'app-modal-competenze',
    templateUrl: './modal-competenze.component.html',
    styleUrls: ['./modal-competenze.component.scss']
})
export class ModalCompetenzeComponent implements OnDestroy {
    skillsForm: FormGroup;
    availableSkills: BehaviorSubject<Skill[]>;
    action! : string;
    unsubscribe: Subject<void> = new Subject();
    isLoading: boolean = false;

    readonly nzModalData: any = inject(NZ_MODAL_DATA);

    constructor(
        private fb: FormBuilder,
        private modal: NzModalRef,
        private certService: CertificationService,
        private skillService: SkillService,
        private commonService: CommonService
    ) {
        if (this.nzModalData === null) {
            this.action = 'Aggiungi';
            this.skillsForm = this.fb.group({
                competenza: ['', Validators.required],
                basse: [0, Validators.required],
                medie: [0, Validators.required],
                alte: [0, Validators.required]
            });
        } else {
            this.action = 'Modifica';
            this.skillsForm = this.fb.group({
                competenza: [this.nzModalData.competenza_id[0], Validators.required],
                basse: [this.nzModalData.basse, Validators.required],
                medie: [this.nzModalData.medie, Validators.required],
                alte: [this.nzModalData.alte, Validators.required]
            });
        }
        this.availableSkills = new BehaviorSubject<Skill[]>([]);
        this.getStaticSkills();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onCreate(): void {
        this.isLoading = true;
        this.skillService.addSkillToOwnCompany(this.skillsForm.value)
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
                next: (res) => {
                    this.commonService.openToastAlert(res?.message, true, {
                        position: 'top-end',
                        icon: 'success',
                        iconColor: '#0dd30d'
                    });
                    this.handleCancel();
                },
                error: (err: HttpErrorResponse) => {
                    this.commonService.openToastAlert(err?.message, true, {
                        position: 'top-end',
                        icon: 'error',
                        iconColor: '#ff0000',
                    });
                }
            });
    }

    onUpdate() {
        this.isLoading = true;
        this.skillService.update(this.nzModalData.id, this.skillsForm.value)
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
                next: (res) => {
                    this.commonService.openToastAlert(res?.message, true, {
                        position: 'top-end',
                        icon: 'success',
                        iconColor: '#0dd30d'
                    });
                    this.handleCancel();
                },
                error: (err: HttpErrorResponse) => {
                    this.commonService.openToastAlert(err?.message, true, {
                        position: 'top-end',
                        icon: 'error',
                        iconColor: '#ff0000',
                    });
                }
            });
    }

    handleCancel(): void {
        this.certService.updateDataTable();
        this.modal.destroy();
    }

    getStaticSkills(): void {
        this.isLoading = true;
        this.skillService.getStaticCompetenze()
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
                next: (res: any): void => {
                    this.availableSkills.next(res.result);
                }
            });
    }
}
