import { CertificationService } from 'src/app/shared/services/certification.service';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NzModalService, ModalOptions } from 'ng-zorro-antd/modal';
import { ModalCertAziendaComponent } from './modal-cert-azienda/modal-cert-azienda.component';
import { ModalCertRisorseComponent } from './modal-cert-risorse/modal-cert-risorse.component';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
    selector: 'app-certificazioni',
    templateUrl: './certificazioni.component.html',
    styleUrls: ['./certificazioni.component.scss']
})
export class CertificazioniComponent implements OnInit, OnDestroy {
    /*
    questo componente verrà richiamato sia dal profilo-risorse che da profilo-azienda che condividono il componente certificazioni
    ricevere in input un flag per capire chi lo sta richiamando e di conseguenza effettuare una get BE
    per recuperare le competenze (azienda/risorse) e valorizzare correttamente la datatable
    */

    /**
    * Id dell'azienda visualizzata lato eht
    */
    @Input() azienda_id!: string | null;

    /**
     * Se è true, serve a gestire la visualizzazione dell'azienda lato eht.
     */
    @Input() eht_admin!: boolean | null;

    /**
    * EndPoint della chiamata API per popolare la tabella. Usato solo dal dettaglio azienda lato EHT
    */
    @Input() eht_endpoint!: string | null;

    /**
   * EndPoint della chiamata API per popolare la tabella. Usato solo dal dettaglio azienda lato EHT
   */
    @Input() customPagination: number = 10;

    //Azioni per datatable
    public actions = { download: true, info: false, modify: true, trash: true, accept: false, refuse: false, upload: false };

    deletedValue!: string;
    currentRoute!: string;
    searchForm!: FormGroup;
    search: any;
    searchSubject: Subject<string> = new Subject();
    unsubscribe: Subject<void> = new Subject();
    displayedColumns = ['']
    displayedFilters = [
        'Servizio', 'Data', 'Modalità'
    ]
    endpointPdf: string = 'pdf'
    endpoint: string = "typology/index"
    params: any = [];
    lastUpdate: any;
    title: string = 'Le tue certificazioni';
    /*
    Colonne della datatable certificazioni
    */
    private certificationsDataTableColumns: string[] = [];
    private certificationsDataTableColumnsForMobile: string[] = ['Certificazione', 'azioni'];

    constructor(
        private certService: CertificationService,
        private modalService: NzModalService,
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService) {
    }

    ngOnInit(): void {
        this.searchForm = this.fb.group({
            search: ['']
        });

        this.searchForm.get('search')?.valueChanges
            .pipe(
                debounceTime(1000),  // Imposta il delay in millisecondi
                distinctUntilChanged(),  // Evita duplicati consecutivi
                takeUntil(this.unsubscribe)  // Interrompe l'osservazione quando il componente viene distrutto
            )
            .subscribe(value => {
                this.onSearch(value);
            });

        this.currentRoute = this.router.url;
        Object.assign(this.params, { 'data': localStorage.getItem('user_id') });

        //Invio l'id dell'azienda da visualizzare e rimuovo i pulsanti che eht non può cliccare
        if (this.eht_admin) {
            Object.assign(this.params, { 'data': this.azienda_id });
            this.actions = { download: true, info: false, modify: false, trash: false, accept: false, refuse: false, upload: false };
        }
        if (this.currentRoute == '/profilo/azienda') {
            this.endpoint = "azienda/certificazioni/dataTable";
            this.endpointPdf = `azienda/certificazioni/download`;
        } else {
            this.endpoint = "risorse/certificazioni/index";
        }

        if (this.eht_endpoint != null) {
            this.endpoint = this.eht_endpoint;
        }

        /*
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                // imposto il sottotitolo di ogni datatable di certificazioni
                if (this.eht_endpoint === 'azienda/certificazioni/dataTable') {
                    this.title = 'Certificazioni aziendali';
                } else if (this.eht_endpoint === 'risorse/certificazioni/index') {
                    this.title = 'Certificazioni delle risorse';
                }

                // in base se sono in modalità mobile o meno, imposto le datatable dedicate per ogni display
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.certificationsDataTableColumns)) {
                    if (this.currentRoute === '/profilo/azienda') {
                        this.certificationsDataTableColumns = ['Certificazione', 'Tipologia', 'Data di emissione', 'Data di scadenza', 'Allegato', 'Template', 'azioni'];
                    } else if (this.eht_endpoint === 'azienda/certificazioni/dataTable') {
                        this.certificationsDataTableColumns = ['Certificazione', 'Tipologia', 'Data di scadenza', 'Allegato', 'azioni'];
                    } else if (this.eht_endpoint === 'risorse/certificazioni/index') {
                        this.certificationsDataTableColumns = ['Certificazione', 'Numero di Risorse Competenti', 'Allegati', 'azioni'];
                    } else {
                        this.certificationsDataTableColumns = ['Certificazione', 'Numero di Risorse Competenti', 'Allegati', 'azioni'];
                    }


                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.certificationsDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.certificationsDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.certificationsDataTableColumnsForMobile));
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSearch(searchValue: string): void {
        this.search = searchValue;
    }

    lastElement(value: any): void {
        this.lastUpdate = value;
    }

    onAddElement(): void {
        if (this.currentRoute !== '/profilo/azienda' && this.currentRoute !== '/profilo/risorse') {
            return;
        }

        const modalConfig: ModalOptions = {
            nzContent: undefined,
            nzData: {
                data: ''
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '640px'
        }

        if (this.currentRoute == '/profilo/azienda') {
            modalConfig.nzContent = ModalCertAziendaComponent;
        } else if (this.currentRoute == '/profilo/risorse') {
            modalConfig.nzContent = ModalCertRisorseComponent;
        }

        this.modalService.create(modalConfig);
    }

    onEditedElement(value: any): void {
        if (this.currentRoute !== '/profilo/azienda' && this.currentRoute !== '/profilo/risorse') {
            return;
        }

        const modalConfig: ModalOptions = {
            nzContent: undefined,
            nzData: {
                data: value
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '640px'
        }

        if (this.currentRoute == '/profilo/azienda') {
            modalConfig.nzContent = ModalCertAziendaComponent;
        } else if (this.currentRoute == '/profilo/risorse') {
            modalConfig.nzContent = ModalCertRisorseComponent;
            modalConfig.nzData = {
                data: value,
                update: true
            };
        }

        this.modalService.create(modalConfig);
    }

    onDeletedElement(value: any): void {
        if (this.currentRoute == '/profilo/azienda') {
            this.certService.deleteCertAziendale(value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: (res) => {
                        this.certService.updateDataTable();
                        this.commonService.openToastAlert(res?.message, true, {
                            position: 'top-end',
                            icon: 'success',
                            iconColor: '#0dd30d',
                        });
                    },
                    error: (err) => {
                        this.commonService.openToastAlert(err?.message, true, {
                            position: 'top-end',
                            icon: 'error',
                            iconColor: '#f03506',
                        });
                    }
                })
        } else {
            this.certService.deleteCertRisorse(value)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: (res) => {
                        this.certService.updateDataTable();
                        this.commonService.openToastAlert(res?.message, true, {
                            position: 'top-end',
                            icon: 'success',
                            iconColor: '#0dd30d',
                        });
                    },
                    error: (err) => {
                        this.commonService.openToastAlert(err?.error?.message, true, {
                            position: 'top-end',
                            icon: 'error',
                            iconColor: '#f03506',
                        });
                    }
                })
        }
    }
}
