// Angular
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpContextToken
} from '@angular/common/http';
// RxJS
import { Observable, throwError} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpContextConfig } from "../models/http-context-config";
import { ActivatedRoute, Router } from '@angular/router';
/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig())

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true
      });
    }

    // Check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner && request.url.indexOf('autoCompleteComuni') === -1 && request.url.indexOf('getAziendeDatatable') === -1 && request.url.indexOf('getMatchingCerts') === -1 && request.url.indexOf('getMatchingSkills') === -1) {
        this.loaderService.setLoading(true, request.url);
      }
    }


    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.loaderService.setLoading(false, request.url);
        }
      }),
      catchError((err) => {
        this.loaderService.setLoading(false, request.url);

        let restore = localStorage.getItem('recover');
        if (err.status === 401 && restore != 'true') {
          this.router.navigate(['/login']);
        }

        console.error('Error Response:', err); // Log error response
        return throwError(() => err.error);
      })
    );
  }
}
