import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { finalize, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { DocumentazioneService } from 'src/app/shared/services/documentazione.service';

@Component({
  selector: 'app-modal-create-documentation',
  templateUrl: './modal-create-documentation.component.html',
  styleUrls: []
})
export class ModalCreateDocumentationComponent implements OnInit, OnDestroy {
  docForm: FormGroup;
  buttonDisabled: boolean = true;
  isLoading: boolean = false;
  unsubscribe: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private docService: DocumentazioneService,
    private commonService: CommonService,
    @Inject(NZ_MODAL_DATA) public data: any,
  ) {
    this.docForm = this.fb.group({
      nome: new FormControl(null, Validators.required),
      callId: new FormControl(this.data),
    });
  }

  ngOnInit(): void {

    // Abilita il pulsante 'salva' se 'nome' non è vuoto
    this.docForm.get('nome')?.valueChanges.subscribe((value: string) => {
      this.buttonDisabled = value != null && value.trim().length > 0;
      this.buttonDisabled = !this.buttonDisabled;
    });

    this.buttonDisabled = true;

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  save(): void {
    if (!this.docForm?.valid) {
      this.commonService.openToastAlert("Inserisci un nome per la documentazione", true, {
        position: 'top-end',
        icon: 'error',
        iconColor: '#f03506',
      });
      return;
    }

    this.isLoading = true;
    this.docService.storeDocumentazioneFacoltativa(this.docForm.value)
      .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
      .subscribe({
        next: (res: any) => {
          this.commonService.openToastAlert("Documentazione creata", true, {
            position: 'top-end',
            icon: 'success',
            iconColor: '#0dd30d',
          });

          this.docService.updateDataTable();
          this.modal.destroy()
        },
        error: (err: any) => {
          this.commonService.openToastAlert(err?.message, true, {
            position: 'top-end',
            icon: 'error',
            iconColor: '#f03506',
          });
        }
      });
  }

  onInputChange(): void {
    this.buttonDisabled = !(this.docForm.controls['nome'].value != '' || this.docForm.controls['nome'].value != null);
  }

  handleCancel(): void {
    this.modal.destroy();
  }
}
