import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { finalize, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ParticipationService } from 'src/app/shared/services/participation.service';

@Component({
  selector: 'app-modal-participation-note',
  templateUrl: './modal-participation-note.component.html',
  styleUrls: ['./modal-participation-note.component.scss']
})
export class ModalParticipationNoteComponent implements OnInit, OnDestroy {

  noteForm: FormGroup;
  characterCount = 0;
  visualizzazione_eht: boolean = false;
  isLoading: boolean = false;
  isLoadingNote: boolean = false;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);

  // Variabile dedicata alla gestione dei subscribe al destroy
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private participationService: ParticipationService,
    private commonService: CommonService
  ) {
    this.noteForm = this.fb.group({
      nota: ['', Validators.required],
      proposta_id: [this.nzModalData.id]
    });
  }

  ngOnInit(): void {
    //Se si clicca su "visualizza note" arriva il flag eht per gestire la visualizzazione della nota
    if (this.nzModalData.flag == 'eht') {
      this.visualizzazione_eht = true;
    }

    //recupero la nota e patcho il form
    this.isLoadingNote = true;
    this.participationService.getParticipationNote(this.nzModalData.id)
      .pipe(takeUntil(this.unsubscribe$), finalize(() => this.isLoadingNote = false))
      .subscribe({
        next: (res) => {
          if (res.data != null) {
            this.noteForm.patchValue({
              nota: res.data,
            });
            this.visualizzazione_eht = true;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleCancel(action: number) {
    if (action == 0) { //Proposta rifiutata
      if (this.noteForm.controls['nota'].value.length > 1000) {
        this.commonService.openToastAlert('Nota troppo lunga', true, {
          position: 'top-end',
          icon: 'error',
          iconColor: '#f03506',
        });
        return null;
      }

      if (this.noteForm.valid) {
        this.isLoading = true;
        this.participationService.saveParticipationNote(this.noteForm.value)
          .pipe(takeUntil(this.unsubscribe$), finalize(() => this.isLoading = false))
          .subscribe({
            next: (res) => {
              this.modal.close(action);
            },
            error: (err) => {
              this.commonService.openToastAlert(err?.message, true, {
                position: 'top-end',
                icon: 'error',
                iconColor: '#f03506',
              });
            }
          });
      } else {
        this.commonService.openToastAlert('Inserisci una nota', true, {
          position: 'top-end',
          icon: 'error',
          iconColor: '#f03506',
        });
      }
    } else {
      // Modale chiusa
      this.modal.close(action);
    }

    return true;
    
  }
}
