import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormattedResponse } from '../models/formatted-response';
import { environment } from 'src/environments/environment';

const ProdottoURL = `${environment.ApiURL}/products`;

@Injectable({
    providedIn: 'root'
})
export class ProdottoService {

    private reloadSubject = new Subject<void>();

    reload$ = this.reloadSubject.asObservable();

    constructor(private http: HttpClient) { }

    updateDataTable() {
        this.reloadSubject.next();
    }

    storeProdotto(params: any): Observable<FormattedResponse<any>> {
        return this.http.post<FormattedResponse<any>>(ProdottoURL, params);
    }

    showProdotto(id: string): Observable<FormattedResponse<any>> {
        return this.http.get<FormattedResponse<any>>(ProdottoURL + '/' + id);
    }

    updateProdotto(id: string, params: any): Observable<FormattedResponse<any>> {
        return this.http.put<FormattedResponse<any>>(ProdottoURL + '/' + id, params);
    }

    deleteProdotto(id: string): Observable<FormattedResponse<any>> {
        return this.http.delete<FormattedResponse<any>>(ProdottoURL + '/' + id);
    }
}
