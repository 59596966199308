import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfiloAziendaComponent } from './profilo/profilo-azienda/profilo-azienda.component';
import { ProfiloRisorseComponent } from './profilo/profilo-risorse/profilo-risorse.component';
import {DettaglioOpportunitaComponent} from "./dashboard/dettaglio-opportunita/dettaglio-opportunita.component";
import { InsertCallComponent } from './eht-dashboard/insert-call/insert-call.component';
import { OpportunitaPersonaliComponent } from './profilo/opportunita-personali/opportunita-personali.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { RecoveryPswComponent } from './auth/recovery/recovery-psw/recovery-psw.component';
import { ProdottiDettaglioComponent } from './profilo/prodotti-dettaglio/prodotti-dettaglio.component';
import { SezioneAnagraficaComponent } from './eht-anagrafica/sezione-anagrafica/sezione-anagrafica.component';
import { InsertAziendaComponent } from './eht-anagrafica/insert-azienda/insert-azienda.component';

const routes: Routes = [
    {
        path: '', redirectTo: 'dashboard', pathMatch: 'full' ,
    },
    {
        path:'login',
        component:LoginComponent,
    },
    {
      path:'recupero-password',
      component:RecoveryPswComponent,

    },
    {
      path:'recupero-password/:uuid',
      component:RecoveryPswComponent,

    },
    {
        path:'dashboard',
        component:DashboardComponent,
    },
    {
        path:'le-tue-opportunita',
        component: OpportunitaPersonaliComponent,
    },
    {
        path:'nuova-opportunita',
        component:InsertCallComponent,
        canActivate: [AuthGuard]
    },
    {
        path:'modifica-opportunita/:id',
        component:InsertCallComponent,
        canActivate: [AuthGuard]
    },
    {
        path:'profilo/azienda',
        component:ProfiloAziendaComponent,
    },
    {
        path:'profilo/azienda/prodotto',
        component:ProdottiDettaglioComponent,
    },
    {
        path:'profilo/azienda/prodotto/:prodotto_id',
        component:ProdottiDettaglioComponent,
    },
    {
        path:'profilo/risorse',
        component: ProfiloRisorseComponent,
    },
    {
        path: 'opportunita/:opportunita_id',
        component: DettaglioOpportunitaComponent
    },
    {
      path: 'anagrafica',
      component: SezioneAnagraficaComponent
    },
    {
      path: 'anagrafica/azienda',
      component: InsertAziendaComponent
    },
    {
      path: 'anagrafica/azienda/:id',
      component: InsertAziendaComponent
    },
    {
      path: 'anagrafica/azienda/:azienda_id/prodotto/:prodotto_id',
      component: ProdottiDettaglioComponent
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
