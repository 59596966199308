<div class="container modal-participation-note">
  <h1 *ngIf="!visualizzazione_eht" class="title">Vuoi davvero rifiutare la proposta?</h1>
  <h1 *ngIf="visualizzazione_eht" class="title">Hai rifiutato questa proposta</h1>
  <p *ngIf="!visualizzazione_eht" class="content">Inserisci nota</p>
  <form nz-form [formGroup]="noteForm" nzLayout="vertical">
    <div class="form-content">
      <app-pcall-loader [showLoader]="isLoading || isLoadingNote">
        <div *ngIf="!visualizzazione_eht">
          <nz-form-item class="mb-0">
            <nz-form-control>
              <nz-textarea-count [nzMaxCharacterCount]="1000">
                <textarea rows="4" formControlName="nota" nz-input placeholder="Inserisci qui"></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </div>
        <p *ngIf="visualizzazione_eht && !isLoadingNote">
          {{noteForm.controls['nota'].value}}
        </p>
        <nz-skeleton *ngIf="visualizzazione_eht && isLoadingNote" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
      </app-pcall-loader>
    </div>

    <div class="ant-modal-body-cta">
      <ng-container *ngIf="!visualizzazione_eht">
        <button nz-button class="btn btn-primary-refuse" (click)="handleCancel(0)" [nzLoading]="isLoading || isLoadingNote">Rifiuta</button>
        <button nz-button nzType="default" type="button" (click)="handleCancel(1)" [nzLoading]="isLoading || isLoadingNote">Chiudi</button>
      </ng-container>
      <ng-container *ngIf="visualizzazione_eht">
        <button nz-button class="w-100" nzType="default" type="button" (click)="handleCancel(1)" [nzLoading]="isLoading || isLoadingNote">Chiudi</button>
      </ng-container>
    </div>
  </form>
</div>