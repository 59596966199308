import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {FormattedResponse} from "../models/formatted-response";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class SkillService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    getStaticCompetenze(): Observable<FormattedResponse<any>> {
        return this.http.get<FormattedResponse<any>>(`${environment.ApiURL}/azienda/competenze/staticCompetenze`);
    }

    addSkillToOwnCompany(params?: any): Observable<FormattedResponse<any>> {
        params.company_id = this.authService.currentUser.value.company.id;
        params.static_competenze_id = params.competenza;
        return this.http.post<FormattedResponse<any>>(`${environment.ApiURL}/azienda/competenze/store`, params);
    }

    deleteSkill(id: string) {
        return this.http.delete<FormattedResponse<any>>(`${environment.ApiURL}/azienda/competenze/destroy/` + id);
    }

    update(id: string, params?: any): Observable<FormattedResponse<any>> {
        params.static_competenze_id = params.competenza;
        params.company_id = this.authService.currentUser.value.company.id;
        return this.http.put<FormattedResponse<any>>(`${environment.ApiURL}/azienda/competenze/update/`+id, params);
    }
}
