<div class="modal-upload-documentation container">
    <h1 class="title">Carica la documentazione</h1>
    <form>
        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <nz-upload nzType="drag" nzListType="picture" [nzFileList]="fileList" [nzAccept]="this.nzAccept "
                    nzLimit="0" [nzMultiple]="true" [nzBeforeUpload]="beforeUpload"
                    [nzShowUploadList]="{ showRemoveIcon: true, showPreviewIcon: true }"
                    (nzChange)="handleChange($event)">
                    <img class="mb-3" src="assets/icons/cloud-add.svg" style="font-size: 27px;" alt="cloud">
                    <div class="ant-upload-text title upload-title">Scegli o trascina i file qui</div>
                    <div class="ant-upload-hint upload-subtitle">Puoi caricare soltanto file
                        <ng-container *ngFor="let ext of fileExtensions.value">{{ext['label']}}, </ng-container>
                        non superiore a 50 MB
                    </div>
                    <button class="button choose-file-btn" nz-button>Scegli file</button>
                </nz-upload>
            </app-pcall-loader>
        </div>

        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" [disabled]="disableButton" [nzLoading]="isLoading" (click)="sendFile()">Salva</button>
            <button nz-button nzType="default" type="button" [nzLoading]="isLoading" (click)="handleCancel()">Chiudi</button>
        </div>
    </form>
</div>