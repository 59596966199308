<div class="recovery-psw" [class.recovery-psw--background-color]="!templateRecoveryPsw">
    <div class="recovery-psw__content" *ngIf="templateRecoveryPsw; else templateEmailSendingNotice">
        <img class="img-content img-content--desktop-with-tablet"
            [src]="(uuid == null) ? '../../../assets/img/cerotto.svg' : '../../../assets/img/banner-modifica-password.svg'"
            alt="Recupero password">
        <img class="img-content img-content--mobile-without-tablet img-content--no-border img-content--background-color"
            [src]="(uuid == null) ? '../../../assets/img/cerotto-mobile.svg' : '../../../assets/img/banner-modifica-password-mobile.svg'"
            alt="Recupero password">
        <div class="content">
            <p class="content__title">{{title}}</p>
            <p class="content__description text-mute">{{subtitle}}</p>
            <form class="content__form" [formGroup]="formCreateRecover" *ngIf="uuid == null">
                <input nz-input type="text" id="mail" placeholder="Email" formControlName="mail"
                    [ngClass]="{'input-error': formCreateRecover.get('mail')?.invalid && formCreateRecover.get('mail')?.touched}">
                <div *ngIf="formCreateRecover.get('mail')?.invalid && formCreateRecover.get('mail')?.touched"
                    class="input-error-text mb-3 mt-0">
                    <div *ngIf="formCreateRecover.get('mail')?.errors?.['required']">campo
                        obbligatorio</div>
                </div>
            </form>
            <form class="content__form" [formGroup]="formCreateModify" *ngIf="uuid != null">
                <div class="mb-3">
                    <input nz-input class="form-control p-2" type="text" id="password" placeholder="Inserisci password"
                        formControlName="password"
                        [ngClass]="{'input-error': formCreateModify.get('password')?.invalid && formCreateModify.get('password')?.touched}">
                    <div *ngIf="formCreateModify.get('password')?.invalid && formCreateModify.get('password')?.touched"
                        class="input-error-text mb-3 mt-0">
                        <div *ngIf="formCreateModify.get('password')?.errors?.['required']">
                            campo obbligatorio</div>
                    </div>
                </div>
                <div>
                    <input nz-input class="form-control p-2" type="text" id="confirm" placeholder="Conferma password"
                        formControlName="confirm"
                        [ngClass]="{'input-error': formCreateModify.get('confirm')?.invalid && formCreateModify.get('confirm')?.touched}">
                    <div *ngIf="formCreateModify.get('confirm')?.invalid && formCreateModify.get('confirm')?.touched"
                        class="input-error-text mb-3 mt-0">
                        <div *ngIf="formCreateModify.get('confirm')?.errors?.['required']">
                            campo obbligatorio</div>
                    </div>
                </div>
            </form>

            <div class="content__cta">
                <div class="w-100">
                    <button nz-button class="btn btn-primary w-100" type="submit" (click)="recover()">Salva</button>
                </div>
                <div class="w-100">
                    <button nz-button class="w-100" nzType="default" type="button"
                        (click)="navigateTo()">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateEmailSendingNotice>
    <div class="recovery-psw__content-email-sending">
        <img class="img-content img-content--desktop-with-tablet img-content--background-color"
            [src]="'../../../assets/img/sendmail.svg'" alt="Email Inviata!">
        <img class="img-content img-content--mobile-without-tablet img-content--no-border img-content--background-color"
            [src]="'../../../assets/img/sendmail-mobile.svg'" alt="Email Inviata!">
        <div class="content">
            <p class="content__title">
                Email Inviata!
            </p>
            <p class="content__description">
                Controlla la tua casella di posta elettronica per proseguire con la procedura di modifica password
            </p>
        </div>
    </div>
</ng-template>