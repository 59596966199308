<div class="dati-generali">
    <div nz-row [nzGutter]="24" class="dati-generali__header">
        <div nz-col [nzSpan]="24">
            <h2 class="dati-generali-title">{{ company['ragione_sociale'] }}</h2>
            <p class="dati-generali-description">Data di ultimo aggiornamento: {{ company['updated_at'] |
                date:'dd/MM/yyyy HH:mm' }}</p>
        </div>
    </div>
    <div class="dati-generali__content-form">
        <p class="campi-obbligatori">* Campi obbligatori</p>
        <form [formGroup]="datiGeneraliForm" (ngSubmit)="onSubmit()" class="input-group">
            <div nz-row [nzGutter]="24">

                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="ragione_sociale" class="form-label">Ragione Sociale *</label>
                    <input nz-input class="form-control" type="text" id="ragione_sociale"
                        formControlName="ragione_sociale" placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('ragione_sociale')?.invalid && datiGeneraliForm.get('ragione_sociale')?.touched">
                    <div *ngIf="datiGeneraliForm.get('ragione_sociale')?.invalid && datiGeneraliForm.get('ragione_sociale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('ragione_sociale')?.errors?.['required']">La ragione sociale è
                            richiesta</div>
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="email" class="form-label">E-mail *</label>
                    <input nz-input class="form-control" type="email" id="email" formControlName="email"
                        placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('email')?.invalid && datiGeneraliForm.get('email')?.touched">
                    <div *ngIf="datiGeneraliForm.get('email')?.invalid && datiGeneraliForm.get('email')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('email')?.errors?.['required']">L'e-mail è richiesta</div>
                        <div *ngIf="datiGeneraliForm.get('email')?.errors?.['email']">L'e-mail non è in un formato
                            valido
                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="partita_iva" class="form-label">Partita IVA *</label>
                    <input nz-input class="form-control" type="text" id="partita_iva" formControlName="partita_iva"
                        placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('partita_iva')?.invalid && datiGeneraliForm.get('partita_iva')?.touched">
                    <div *ngIf="datiGeneraliForm.get('partita_iva')?.invalid && datiGeneraliForm.get('partita_iva')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('partita_iva')?.errors?.['required']">La partita IVA è
                            richiesta
                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="codice_fiscale" class="form-label">Codice fiscale</label>
                    <input nz-input class="form-control" type="text" id="codice_fiscale"
                        formControlName="codice_fiscale" placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('codice_fiscale')?.invalid && datiGeneraliForm.get('codice_fiscale')?.touched">
                    <div *ngIf="datiGeneraliForm.get('codice_fiscale')?.invalid && datiGeneraliForm.get('codice_fiscale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('codice_fiscale')?.errors?.['required']">Il codice fiscale è richiesto
                        </div>
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="numero_dipendenti" class="form-label">Numero dipendenti *</label>
                    <input nz-input class="form-control mb-0" type="number" id="numero_dipendenti"
                        formControlName="numero_dipendenti" placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('numero_dipendenti')?.invalid && datiGeneraliForm.get('numero_dipendenti')?.touched">
                    <div *ngIf="datiGeneraliForm.get('numero_dipendenti')?.invalid && datiGeneraliForm.get('numero_dipendenti')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('numero_dipendenti')?.errors?.['required']">Il numero di
                            dipendenti è richiesto</div>
                    </div>
                </div>
                <div nz-col [nzSpan]="24">
                    <h3 class="section_title">Sede legale</h3>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="indirizzo" class="form-label">Indirizzo</label>
                    <input nz-input class="form-control" type="text" id="indirizzo"
                        formControlName="indirizzo_sede_legale" placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('indirizzo_sede_legale')?.invalid && datiGeneraliForm.get('indirizzo_sede_legale')?.touched">
                    <div *ngIf="datiGeneraliForm.get('indirizzo_sede_legale')?.invalid && datiGeneraliForm.get('indirizzo_sede_legale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('indirizzo_sede_legale')?.errors?.['required']">L'indirizzo è
                            richiesto
                        </div>
                    </div>
                </div>

                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label for="provincia" class="form-label">Nazione</label>
                    <input nz-input class="form-control" type="text" id="provincia" formControlName="nazione"
                        placeholder="Inserisci qui" readonly [class.no-margin-bottom]="datiGeneraliForm.get('nazione')?.invalid && datiGeneraliForm.get('nazione')?.touched">
                    <div *ngIf="datiGeneraliForm.get('nazione')?.invalid && datiGeneraliForm.get('nazione')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('nazione')?.errors?.['required']">La provincia è
                            richiesta</div>
                    </div>
                </div>

                <div nz-col [nzSpan]="24" [nzMd]="8">
                    <label for="comune" class="form-label">Comune</label>
                    <input nz-input class="form-control" type="text" id="comune" formControlName="comune_sede_legale"
                        placeholder="Inserisci qui" [nzAutocomplete]="auto" [class.no-margin-bottom]="datiGeneraliForm.get('comune_sede_legale')?.invalid && datiGeneraliForm.get('comune_sede_legale')?.touched">
                    <nz-autocomplete #auto (selectionChange)="onOptionSelected($event)">
                        <nz-auto-option *ngFor=" let option of comuni" [nzValue]="option" [nzLabel]="option">
                            {{ option }}
                        </nz-auto-option>
                    </nz-autocomplete>
                    <div *ngIf="datiGeneraliForm.get('comune_sede_legale')?.invalid && datiGeneraliForm.get('comune_sede_legale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('comune_sede_legale')?.errors?.['required']">Il comune è
                            richiesto
                        </div>
                    </div>
                </div>

                <div nz-col [nzSpan]="24" [nzMd]="8">
                    <label for="provincia" class="form-label">Provincia</label>
                    <input nz-input class="form-control" type="text" id="provincia"
                        formControlName="provincia_sede_legale" placeholder="Inserisci qui" readonly [class.no-margin-bottom]="datiGeneraliForm.get('provincia_sede_legale')?.invalid && datiGeneraliForm.get('provincia_sede_legale')?.touched">
                    <div *ngIf="datiGeneraliForm.get('provincia_sede_legale')?.invalid && datiGeneraliForm.get('provincia_sede_legale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('provincia_sede_legale')?.errors?.['required']">La provincia è
                            richiesta</div>
                    </div>
                </div>

                <div nz-col [nzSpan]="24" [nzMd]="8">
                    <label for="cap" class="form-label">CAP</label>
                    <input nz-input class="form-control mb-0" type="text" id="cap" formControlName="cap_sede_legale" [nzAutocomplete]="autoCap"
                        placeholder="Inserisci qui" [class.no-margin-bottom]="datiGeneraliForm.get('cap_sede_legale')?.invalid && datiGeneraliForm.get('cap_sede_legale')?.touched">
                    <nz-autocomplete #autoCap>
                        <nz-auto-option *ngFor=" let option of available_cap" [nzValue]="option">
                            {{ option }}
                        </nz-auto-option>
                    </nz-autocomplete>
                    <div *ngIf="datiGeneraliForm.get('cap_sede_legale')?.invalid && datiGeneraliForm.get('cap_sede_legale')?.touched"
                        class="mt-0 input-error-box">
                        <div *ngIf="datiGeneraliForm.get('cap_sede_legale')?.errors?.['required']">Il CAP è richiesto
                        </div>
                    </div>
                </div>

                <!-- MULTISELECT SETTORI -->
                <div nz-col [nzSpan]="24">
                    <h3 class="section_title">Settori di interesse</h3>
                </div>
                <div nz-col [nzSpan]="24">
                    <app-multiselect
                        [data]="settori.value"
                        [dataControl]="settoriControl"
                        [(selected_data)]="settori_selezionati"
                        [icon]="'icon_sector.svg'"
                    ></app-multiselect>
                </div>

                <div nz-col [nzSpan]="24">
                    <div class="col">
                        <h3 class="section_title mb-2 mt-4">Modifica dati di accesso</h3>
                    </div>
                </div>

                <div nz-col [nzSpan]="12">
                    <label  for="indirizzo" class="form-label">Nuova Password</label>
                    <input (ngModelChange)="setValidators($event)" nz-input class="form-control" type="text" id="indirizzo" formControlName="password" placeholder="●●●●●●●●"
                    [nzStatus]="datiGeneraliForm.get('password')?.invalid && datiGeneraliForm.get('password')?.touched ? 'error' : ''">
                    <div>
                        <ng-container *ngIf="(datiGeneraliForm.get('password')?.invalid && datiGeneraliForm.get('password')?.touched)">
                            <div class="alert-form" *ngIf="datiGeneraliForm.get('password')?.errors?.['required']">• Campo obbligatorio</div>
                        </ng-container>

                        <ng-container *ngIf="datiGeneraliForm.get('password')?.value != null">
                            <div class="alert-form" [ngClass]="datiGeneraliForm.get('password')?.errors?.['minlength'] ? 'validation-error' : 'validation-success'">• La password deve essere lunga almeno 8 caratteri</div>
                            <div class="alert-form" [ngClass]="datiGeneraliForm.get('password')?.errors?.['uppercase'] ? 'validation-error' : 'validation-success'">• Deve contenere almeno una lettera maiuscola</div>
                            <div class="alert-form" [ngClass]="datiGeneraliForm.get('password')?.errors?.['lowercase'] ? 'validation-error' : 'validation-success'">• Deve contenere almeno una lettera minuscola</div>
                            <div class="alert-form" [ngClass]="datiGeneraliForm.get('password')?.errors?.['number'] ? 'validation-error' : 'validation-success'">• Deve contenere almeno un numero</div>
                            <div class="alert-form" [ngClass]="datiGeneraliForm.get('password')?.errors?.['symbol'] ? 'validation-error' : 'validation-success'">• Deve contenere un carattere speciale: $&#64;*!%?&</div>
                        </ng-container>
                    </div>
                </div>

                <div nz-col [nzSpan]="12">
                    <label for="password_confirmation" class="form-label">Conferma Password</label>
                    <input nz-input class="form-control" type="text" id="password_confirmation" formControlName="password_confirmation"
                        placeholder="●●●●●●●●" (ngModelChange)="passwordCheckhValidator($event)" [nzStatus]="datiGeneraliForm.get('password_confirmation')?.invalid && datiGeneraliForm.get('password_confirmation')?.touched ? 'error' : ''">
                    <div *ngIf="(datiGeneraliForm.get('password_confirmation')?.invalid && datiGeneraliForm.get('password_confirmation')?.touched) || checkPassword == false"
                         class="mb-3 mt-0 alert-form">
                        <div *ngIf="datiGeneraliForm.get('password_confirmation')?.errors?.['required']">• Campo obbligatorio</div>
                        <div *ngIf="!checkPassword" >• Le due password non coincidono</div>
                    </div>
                </div>

                <div nz-col [nzOffset]="20" [nzSpan]="24" class="d-flex justify-content-end m-0 save-cta">
                    <button type="submit" [disabled]="datiGeneraliForm.invalid" class="btn btn-primary">Salva</button>
                </div>
            </div>
        </form>
    </div>
</div>
