<div class="sezione-anagrafica">
    <!-- begin: Sezione hero header mobile (escluso tablet) -->
    <section class="hero-section-registry-mobile">
        <div class="hero-section-registry-mobile__title-box">
            <div class="header-title">
                <img src="../../../assets/img/hero-logo.png" alt="Portale delle opportunità" />
                <div>
                    <p class="mb-1">Ciao</p>
                    <h5 class="current-user m-0">{{(authService.currentUser | async)?.name}}</h5>
                </div>
            </div>
        </div>
        <div class="hero-section-registry-mobile__registry">
            <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="isAdmin">
                <div>
                    <h2 class="title text-white m-0">Inserisci una nuova<br />azienda</h2>
                </div>
                <button class="btn custom-button" (click)="goTo('anagrafica/azienda', $event)"
                    (keydown)="goTo('anagrafica/azienda', $event)">Inserisci</button>
            </div>

        </div>
    </section>
    <!-- end: Sezione hero header mobile (escluso tablet) -->

    <section class="section-content">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 mb-4 p-0 header-info">
                <div class="card intestation col-8">
                    <div class="card-body d-flex p-0 align-items-center">
                        <div class="ms-3">
                            <div class="d-flex flex-row align-items-center">
                                <img src="../../../assets/img/hero-logo.png" alt="Portale delle opportunità"
                                    class="me-3" height="48" />
                                <div>
                                    <p class="card-text text-white m-0">Ciao</p>
                                    <h5 class="secondary_intestation m-0">{{(authService.currentUser | async)?.name}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container col-4 " style="padding: 0 0 0 1.5rem;">
                    <div
                        class="card insert-company custom-card d-flex flex-row flex-wrap justify-content-between align-items-center">
                        <div>
                            <h2 class="title text-white m-0">Inserisci <br />una nuova <br />azienda</h2>
                        </div>
                        <button class="btn custom-button" (click)="goTo('anagrafica/azienda', $event)"
                            (keydown)="goTo('anagrafica/azienda', $event)">Inserisci</button>
                    </div>
                </div>

            </div>
            <div class="px-0 mb-3">
                <p class="title mb-3">Elenco delle aziende consorziate</p>
                <form [formGroup]="searchForm">
                    <div class="advanced-filters">
                        <div class="advanced-filters__input">
                            <nz-input-group [nzPrefix]="prefixIconSearch">
                                <input type="text" nz-input placeholder="Cerca per nome" formControlName="search" />
                            </nz-input-group>
                            <ng-template #prefixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                        <div class="advanced-filters__cta">
                            <a (click)="filtri_avanzati = !filtri_avanzati" class="filter-link-cta">
                                <img class="filter-link-cta__filter-icon" src="assets/icons/settings.svg"
                                    alt="Icona filtri avanzati">
                                <p class="filter-link-cta__filter-text">Filtri</p>
                                <img class="filter-link-cta__arrow-icon" src="assets/icons/arrow-down-blue.svg"
                                    alt="Freccia in giù" *ngIf="!filtri_avanzati">
                                <img class="filter-link-cta__arrow-icon" src="assets/icons/arrow-up.svg"
                                    alt="Freccia in su" *ngIf="filtri_avanzati">
                            </a>
                        </div>
                    </div>
                    <div class="accordion-container" *ngIf="filtri_avanzati">
                        <div nz-row [nzGutter]="24">
                            <div nz-col [nzSpan]="24" class="accordion-title ps-0">Filtri</div>
                        </div>

                        <!-- Search for provinces -->
                        <div nz-row [nzGutter]="24" class="w-100">
                            <div nz-col [nzSpan]="24" class="input-wrapper ps-0 pe-0">
                                <app-multiselect [data]="provinces.value" [dataControl]="provincesControl"
                                    [(selected_data)]="provinces_selezionati" [placeholder]="'Inserisci qui'"
                                    [label]="'Provincia'" class="w-100"></app-multiselect>
                            </div>
                        </div>

                        <!-- Search for settori -->
                        <div nz-row [nzGutter]="24" class="w-100">
                            <div nz-col [nzSpan]="24" class="input-wrapper ps-0 pe-0">
                                <app-multiselect [data]="settori.value" [dataControl]="settoriControl"
                                    [(selected_data)]="settori_selezionati" [placeholder]="'Inserisci qui'"
                                    [label]="'Settore di interesse'" class="w-100"></app-multiselect>
                            </div>
                        </div>

                        <!-- Search for company documents -->
                        <div nz-row [nzGutter]="24" class="w-100">
                            <div nz-col [nzSpan]="24" class="input-wrapper ps-0 pe-0">
                                <app-multiselect [data]="documents.value" [dataControl]="documentsControl"
                                    [(selected_data)]="documents_selezionati" [placeholder]="'Inserisci qui'"
                                    [label]="'Documenti aziendali'" class="w-100"></app-multiselect>
                            </div>
                        </div>

                        <!-- Search for employee certifications -->
                        <div nz-row [nzGutter]="24" class="w-100">
                            <div nz-col [nzSpan]="24" class="input-wrapper ps-0 pe-0">
                                <app-multiselect [data]="certifications.value" [dataControl]="certificationsControl"
                                    [(selected_data)]="certifications_selezionati" [placeholder]="'Inserisci qui'"
                                    [label]="'Certificazioni delle risorse'" class="w-100"></app-multiselect>
                            </div>
                        </div>

                        <!-- Search for employee skills -->
                        <div nz-row [nzGutter]="24" class="w-100">
                            <div nz-col [nzSpan]="24" class="input-wrapper ps-0 pe-0">
                                <app-multiselect [data]="skills.value" [dataControl]="skillsControl"
                                    [(selected_data)]="skills_selezionati" [placeholder]="'Inserisci qui'"
                                    [label]="'Competenze delle risorse'" class="w-100"></app-multiselect>
                            </div>
                        </div>

                        <!-- Range eployee number -->
                        <div nz-row [nzGutter]="24" class="w-100 range-employee-num-box">
                            <div nz-col [nzSpan]="24" [nzMd]="12" class="input-wrapper">
                                <label class="input-label" for="minNDipendenti">Numero min di dipendenti</label>
                                <nz-input-number [(ngModel)]="minNDipendenti" [nzMin]="1"
                                    [nzMax]="(maxNDipendenti != null && maxNDipendenti != '') ? maxNDipendenti : infinity"
                                    (ngModelChange)="onValue1Change($event)" formControlName="minNDipendenti"
                                    nzPlaceHolder="Inserisci qui" class="w-100"></nz-input-number>
                            </div>
                            <div nz-col [nzSpan]="24" [nzMd]="12" class="input-wrapper">
                                <label class="input-label" for="maxNDipendenti">Numero max di dipendenti</label>
                                <nz-input-number [(ngModel)]="maxNDipendenti"
                                    [nzMin]="(minNDipendenti != null && minNDipendenti != '') ? minNDipendenti : 1"
                                    (ngModelChange)="onValue2Change($event)" formControlName="maxNDipendenti"
                                    nzPlaceHolder="Inserisci qui" class="w-100"></nz-input-number>
                            </div>
                        </div>

                        <div nz-row [nzGutter]="24" class="w-100 employee-skill-box">
                            <div nz-col [nzSpan]="24" [nzLg]="8" class="input-wrapper">
                                <label class="input-label" for="skillLow">Dipendenti con competenza bassa</label>
                                <nz-input-number [nzMin]="0" formControlName="skillLow"
                                    [nzPlaceHolder]="'Inserisci qui'" class="w-100"></nz-input-number>
                            </div>
                            <div nz-col [nzSpan]="24" [nzLg]="8" class="input-wrappe">
                                <label class="input-label" for="skillMid">Dipendenti con competenza media</label>
                                <nz-input-number [nzMin]="0" formControlName="skillMid"
                                    [nzPlaceHolder]="'Inserisci qui'" class="w-100"></nz-input-number>
                            </div>
                            <div nz-col [nzSpan]="24" [nzLg]="8" class="input-wrapper">
                                <label class="input-label" for="skillHigh">Dipendenti con competenza alta</label>
                                <nz-input-number [nzMin]="0" formControlName="skillHigh"
                                    [nzPlaceHolder]="'Inserisci qui'" class="w-100"></nz-input-number>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <drw-data-table class="companies-table" [displayedColumns]="displayedColumns"
                [endpointDataSource]="endpoint" [search]="search" [actions]="true" [actionsButton]="actionsButton"
                [isFilter]="false" [enableNavigation]="true" (editedElement)="onEditedElement($event)"
                (deletedElement)="onDeleteElement($event)">
            </drw-data-table>
        </div>
    </section>
</div>