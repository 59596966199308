import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company } from "../../shared/models/company.model";
import { CompaniesService } from "../../shared/services/companies.service";
import { FormattedResponse } from "../../shared/models/formatted-response.model";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, debounceTime, distinctUntilChanged, switchMap, BehaviorSubject, takeUntil } from 'rxjs';
import { Settore } from 'src/app/shared/models/settore';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-insert-azienda',
  templateUrl: './insert-azienda.component.html',
  styleUrls: ['./insert-azienda.component.scss']
})


export class InsertAziendaComponent implements OnInit, OnDestroy {
  datiGeneraliForm: FormGroup;
  comuni: any;
  searchTerms = new Subject<string>();
  settori: BehaviorSubject<Settore[]>;
  company: Company = {
    id: '',
    ragione_sociale: '',
    email: '',
    partita_iva: '',
    numero_dipendenti: 0
  };
  settori_selezionati: any[] = [];
  azienda_id: string | null = null;
  checkPassword: boolean | null = null;
  placeholderString: string = 'Inserisci qui';

  // proprietà dedicata alla gestione dei subscribe al destroy
  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companiesService: CompaniesService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.datiGeneraliForm = this.createForm();
    this.settori = new BehaviorSubject<Settore[]>([]);
  }

  ngOnInit(): void {
    this.azienda_id = this.route.snapshot.paramMap.get('id');
    if (this.azienda_id) {
      this.getCompany(this.azienda_id);
      this.removePasswordValidators();
      this.placeholderString = "●●●●●●●●";
    }

    this.loadSettori();
    this.setupSearchTerms();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createForm(): FormGroup {
    return this.fb.group({
      ragione_sociale: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      partita_iva: ['', Validators.required],
      codice_fiscale: '',
      numero_dipendenti: [0, Validators.required],
      indirizzo_sede_legale: '',
      nazione: [{ value: 'Italia', disabled: false }],
      comune_sede_legale: null,
      provincia_sede_legale: [{ value: '', disabled: false }],
      cap_sede_legale: '',
      settori: [[]],
      password: new FormControl(null, [
        Validators.required,
        this.passwordStrengthValidator
      ]),
      confirm: ['', Validators.required],
    });
  }

  private removePasswordValidators() {
    this.datiGeneraliForm.get('password')?.clearValidators();
    this.datiGeneraliForm.get('confirm')?.clearValidators();
    this.datiGeneraliForm.get('password')?.updateValueAndValidity();
    this.datiGeneraliForm.get('confirm')?.updateValueAndValidity();
  }

  private loadSettori() {
    this.companiesService.getSettori()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any): void => {
          this.settori.next(res.data);
        }
      });
  }

  private setupSearchTerms() {
    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => this.companiesService.getAutoCompleteComuni({ search: term, skip: 0, take: 100 })),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      this.comuni = res.data;
    });

    this.datiGeneraliForm.get('comune_sede_legale')?.valueChanges.subscribe(term => {
      this.searchTerms.next(term);
    });
  }

  goTo(uri: string, event: Event) {
    if ((event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter')) || (event instanceof MouseEvent && event?.button === 0)) {
      event.preventDefault();
      this.router.navigate([uri]);
    }
  }

  getCompany(azienda_id: string) {
    this.companiesService.getCompanyOwnedById(azienda_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: FormattedResponse): void => {
        this.company = <Company>res['data'];
        this.datiGeneraliForm.patchValue(this.company);
        this.datiGeneraliForm.patchValue({
          settori: res.data.settore.map((sk: any) => Number(sk.settore_id)),
        });
        this.settori_selezionati = this.datiGeneraliForm.get('settori')?.value;
      });
  }

  changeSelection(selectedId: number, updatedData: any): void {
    const index = updatedData.indexOf(selectedId);
    if (index !== -1) {
      updatedData.splice(index, 1);
    }
  }

  getSelection(data: any, updatedData: any) {
    return data.value.filter((selected: any) => updatedData.includes(selected.id))
      .sort((a: any, b: any) => (a.Nome || a.name).localeCompare((b.Nome || b.name)));
  }

  onSubmit() {
    if (this.datiGeneraliForm.valid) {
      if (this.azienda_id == null) {
        this.createAzienda();
      } else {
        this.updateAzienda();
      }
    } else {
      this.showErrorToast("Compila tutti i campi obbligatori");
    }
  }

  private createAzienda() {
    this.companiesService.createAzienda(this.datiGeneraliForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: FormattedResponse): void => {
          this.showSuccessToast(res.message);
          this.router.navigate(['anagrafica']);
        },
        error: (err: HttpErrorResponse) => {
          this.showErrorToast(err.message);
        }
      });
  }

  private updateAzienda() {
    this.companiesService.update(this.azienda_id!, this.datiGeneraliForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: FormattedResponse): void => {
          this.showSuccessToast(res.message);
          this.router.navigate(['anagrafica']);
        },
        error: (err: HttpErrorResponse) => {
          let error_list = '';
          Object.values(err.message).forEach(msg => {
            error_list += `${msg}\n`;
          });
          this.showErrorToast(error_list);
        }
      });
  }

  private showSuccessToast(message: string) {
    this.commonService.openToastAlert(message, true, {
      position: 'top-end',
      icon: 'success',
      iconColor: '#0dd30d',
    });
  }

  private showErrorToast(message: string) {
    this.commonService.openToastAlert(message, true, {
      position: 'top-end',
      icon: 'error',
      iconColor: '#f03506',
    });
  }

  passwordStrengthValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value || '';
    let error: { [key: string]: boolean } = {};
    if (!/[A-Z]/.test(value)) { // Controlla per una lettera maiuscola
      error = { ...error, uppercase: true };
    }
    if (!/[a-z]/.test(value)) { // Controlla per una lettera minuscola
      error = { ...error, lowercase: true };
    }
    if (!/\d/.test(value)) { // Controlla per un numero
      error = { ...error, number: true };
    }
    if (!/[$@*!%?&]/.test(value)) { // Controlla per un simbolo
      error = { ...error, symbol: true };
    }
    if (value.length < 8) {
      error = { ...error, minlength: true };
    }

    return Object.keys(error).length ? error : null;
  }

  passwordCheckhValidator(event: any) {
    const passwordControl = this.datiGeneraliForm.get('password')?.value;
    if (!passwordControl) return;
    this.checkPassword = this.datiGeneraliForm.get('password')?.value === event;
  }

  setValidators(event: any) {
    const passwordControl = this.datiGeneraliForm.get('password') as FormControl;
    const confirmControl = this.datiGeneraliForm.get('confirm') as FormControl;
    if (!event) {
      this.clearValidators(passwordControl, confirmControl);
    } else {
      this.addValidators(passwordControl, confirmControl);
    }
  }

  onOptionSelected(option: any): void {
    this.companiesService.obtainCap(option.nzValue)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.datiGeneraliForm.patchValue({
          provincia_sede_legale: res.data.sigla
        })
      });
  }

  private clearValidators(passwordControl: FormControl | null, confirmControl: FormControl | null) {
    passwordControl?.clearValidators();
    confirmControl?.clearValidators();
    passwordControl?.updateValueAndValidity();
    confirmControl?.updateValueAndValidity();
  }

  private addValidators(passwordControl: FormControl | null, confirmControl: FormControl | null) {
    passwordControl?.addValidators([Validators.required, this.passwordStrengthValidator]);
    confirmControl?.addValidators([Validators.required]);
    passwordControl?.updateValueAndValidity();
    confirmControl?.updateValueAndValidity();
  }

  get settoriControl(): FormControl {
    return this.datiGeneraliForm.get('settori') as FormControl;
  }

}
