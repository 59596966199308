export const config = {
  header: {
    active: false,
    exception: ["/login", "/recupero-password"]
  },
  sidebar: {
    active: false,
    exception: ["/login", "/recupero-password"]
  },
  footer: {
    active: false,
    exception: ["/login", "/recupero-password"]
  },
}
