import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SwalToastCustom } from '../models/swal-toast-custom.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    /*
    BehaviorSubject per verifica dimensioni display per mobile (smartphone e tablet)
    */
    private isMobileWithTabletSubject = new BehaviorSubject<boolean>(false);
    isMobileWithTablet$ = this.isMobileWithTabletSubject.asObservable();

    /*
    BehaviorSubject per verifica dimensioni display per mobile (SOLO smartphone esclusi tablet)
    */
    private isMobileWithoutTabletSubject = new BehaviorSubject<boolean>(false);
    isMobileWithoutTablet$ = this.isMobileWithoutTabletSubject.asObservable();

    constructor() {
        /*
        Inizializzo gli stati di controllo se si tratta di un display per mobile
        */
        this.checkIsMobileWithTabletDisplay();
        this.checkIsMobileWithoutTabletDisplay();

        /*
        Inizializzo l'evento per poter aggiornare lo stato di controllo (ad ogni resize della window)
        se si tratta di un display per mobile
        */
        window.addEventListener('resize', () => {
            this.checkIsMobileWithTabletDisplay();
            this.checkIsMobileWithoutTabletDisplay();
        });
    }

    /**
     * Apre uno swal alert in modalità popup/toast
     * 
     * @param message Messaggio del popup/toast
     * @param enableTimer Abilita/disabilita il timer di permanenza del popup/toast
     * @param settings Opzioni del popup/toast (vedere l'interfaccia per le opzioni utilizzabili con questo metodo)
     */
    public openToastAlert(message?: string, enableTimer?: boolean, settings?: SwalToastCustom): void {
        const swalOptions: SweetAlertOptions = {
            toast: true,
            position: settings?.position ?? 'top-end',
            showConfirmButton: false,
            icon: settings?.icon,
            iconColor: settings?.iconColor,
            title: message ?? ''
        }

        /*
        Se viene abilitato il timer di permanenza del popup/toast, lo imposto a 2 secondi e mezzo abilitando anche la
        progress bar e passo la callback che lo stoppa al passaggio del mouse sopra la stessa popup/toast
        */
        if (enableTimer) {
            swalOptions.timer = 2500;
            swalOptions.timerProgressBar = true;
            swalOptions.didOpen = (toast: HTMLElement) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            };
        }

        // apro il popup/toast
        Swal.fire(swalOptions);
    }

    /**
     * Aggiorna il booleano che verifica se è entro la soglia di dimensione display mobile (smartphone e tablet)
     * @returns 
     */
    private checkIsMobileWithTabletDisplay(): void {
        this.isMobileWithTabletSubject.next(window.innerWidth <= 992);
    }

    /**
     * Aggiorna il booleano che verifica se è entro la soglia di dimensione display mobile (SOLO smartphone esclusi tablet)
     * @returns 
     */
    private checkIsMobileWithoutTabletDisplay(): void {
        this.isMobileWithoutTabletSubject.next(window.innerWidth <= 767);
    }
}