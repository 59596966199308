import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CertificationService } from 'src/app/shared/services/certification.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-modal-cert-azienda',
    templateUrl: './modal-cert-azienda.component.html',
    styleUrls: ['./modal-cert-azienda.component.scss']
})
export class ModalCertAziendaComponent implements OnInit, OnDestroy {
    certificationCompanyForm: FormGroup;
    fileList: NzUploadFile[] = [];
    availableCertification: any;
    reset: boolean = false;
    action! : string;
    signatureFlag:boolean = false;
    newCertificationId: number | null = null;
    certificationCompanyFlag: boolean = false;
    oldCertificationName: string = '';
    isLoading: boolean = false;
    unsubscribe: Subject<void> = new Subject();
    customLabel: string = '';
    validity_days: number|null = null;

    // serve a controllare l'attuale stringa scritta nella barra di ricerca della select Certificazione
    searchTermCompany: string = '';

    // serve ad aggiungere l'option personalizzata dopo le operazioni di filtro di nz-select
    filteredCertification: any[] = [];

    constructor(
        private fb: FormBuilder,
        private modal: NzModalRef,
        private certService: CertificationService,
        private commonService: CommonService,
        @Inject(NZ_MODAL_DATA) public data: any
    ) {
        this.certificationCompanyForm = this.fb.group({
            id_certificazione: [null, Validators.required],
            data_di_rilascio: [null, Validators.required],
            data_fine_validita: [null, Validators.required],
            file_certification: [null, Validators.required],
            nome: [null],
        });
    }

    ngOnInit(): void {
        this.action = this.data.data == '' ? 'Aggiungi' : 'Modifica';

        this.getAvailableCertification(this.data.data.id_certificazione ?? '');
        this.certificationCompanyForm.patchValue({
            id_certificazione: this.data?.data?.id_certificazione,
            data_di_rilascio: this.data?.data?.['Data di emissione'],
            data_fine_validita: this.data?.data?.['Data di scadenza'],
        })
        this.validity_days = this.data?.data?.validity_days;
        this.checkValidity();
        if (this.data?.data?.file_storage?.name) {
            this.fileList = [
                {
                    uid: '-1',
                    name: this.data.data.file_storage.name + '.pdf',
                    status: 'done',
                    url: '' // URL del file se disponibile
                }
            ];
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAvailableCertification(id_selected_certification: string): void {
        this.isLoading = true;
        this.certService.getAvailableCertification({ selectedCert: id_selected_certification })
            .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
            .subscribe({
                next: (res) => {
                    this.availableCertification = res.data;
                    this.alertSignature(this.data.data.id_certificazione)
                }
            });
    }

    handleSearchChange(value: any): void {
        this.searchTermCompany = value;
        this.customLabel = '<span class="custom-label">'+value+' (Personalizzata)</span>';
    }

    checkValidity(reset?: boolean) {
        if (this.data.data.file_storage?.name && this.certificationCompanyForm.value.file_certification == null && !this.reset) {
            this.certificationCompanyForm.controls['file_certification'].clearValidators();
            this.certificationCompanyForm.controls['file_certification'].updateValueAndValidity();
        } else {
            this.certificationCompanyForm.controls['file_certification'].setValidators(Validators.required);
            this.certificationCompanyForm.controls['file_certification'].updateValueAndValidity();
        }
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = [file];
        this.reset = false;

        this.certificationCompanyForm.patchValue({ file_certification: file });
        this.checkValidity(this.reset);
        return false;  // Prevent automatic upload
    };

    handleChange(info: { file: NzUploadFile }): void {

        this.certificationCompanyForm.patchValue({ file_certification: null });
        this.reset = true;
        this.checkValidity(this.reset);
    }

    handleUpload(): void {
        if (this.certificationCompanyForm.valid) {
            const formData = new FormData();
            formData.append('id_certificazione', this.certificationCompanyForm.get('id_certificazione')?.value);

            // formattazione della data in quanto ngzorrodata_rilascio
            // fornisce un formato non valido
            const dataRilascio = this.certificationCompanyForm.get('data_di_rilascio')?.value;
            const dataRilascioFormatted = moment(dataRilascio).format('YYYY-MM-DD');

            formData.append('data_di_rilascio', dataRilascioFormatted);

            const dataFineValidita = this.certificationCompanyForm.get('data_fine_validita')?.value;
            const dataFormatted = moment(dataFineValidita).format('YYYY-MM-DD');

            formData.append('data_fine_validita', dataFormatted);
            formData.append('nome', this.certificationCompanyForm.get('nome')?.value);

            if (!this.data.data) {
                formData.append('file_certification', this.fileList[0] as any);
                this.isLoading = true;
                this.certService.addAziendaCertification(formData)
                    .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
                    .subscribe({
                        next: (res) => {
                            this.certService.updateDataTable();
                            this.modal.destroy();
                            this.commonService.openToastAlert(res?.message, true, {
                                position: 'top-end',
                                icon: 'success',
                                iconColor: '#0dd30d',
                            });
                        },
                        error: (err: HttpErrorResponse) => {
                            this.commonService.openToastAlert(err?.message, true, {
                                position: 'top-end',
                                icon: 'error',
                                iconColor: '#ff0000',
                            });
                        }
                    });
            }
            else {
                if (this.certificationCompanyForm.value.file_certification != null) formData.append('file_certification', this.fileList[0] as any);
                this.isLoading = true;
                this.certService.updateAziendaCertification(this.data.data.id, formData)
                    .pipe(takeUntil(this.unsubscribe), finalize(() => this.isLoading = false))
                    .subscribe({
                        next: (res) => {
                            this.certService.updateDataTable();
                            this.modal.destroy();
                            this.commonService.openToastAlert(res?.message, true, {
                                position: 'top-end',
                                icon: 'success',
                                iconColor: '#0dd30d',
                            });
                        },
                        error: (err: HttpErrorResponse) => {
                            this.commonService.openToastAlert(err?.message, true, {
                                position: 'top-end',
                                icon: 'error',
                                iconColor: '#ff0000',
                            });
                        }
                    });
            }
        }
    }

    handleCancel(): void {
        this.modal.destroy();
    }

    //Setta la variabile signatureFlag a true/false per gestire la visualizzazione dell'alert firma digitale
    alertSignature(event:any): void {
        const result = this.availableCertification.find((cert:any) => cert.id === event);

        if(result?.firma_digitale){
            this.signatureFlag = result.firma_digitale;
        }
    }

    saveNewCompanyCertification(event: any): void {
        if(event === -1 && !this.certificationCompanyFlag) {
            this.certificationCompanyFlag = true;
            this.certificationCompanyForm.patchValue({
                id_certificazione: -1,
                nome: this.searchTermCompany
            });
        } else if(event != -1) {
            this.certificationCompanyFlag = false;
        }
    }

    filterOptionCompany = (input: string, option: any): boolean => {
        if (option.nzLabel === null) {
            if (this.searchTermCompany !== input) {
                this.searchTermCompany = input;
            }
            return true;
        }
        return option.nzLabel?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    }

    /**
     * Imposta il valore corretto di data_fine_validita
     */
    setFineValidita() {
        if(this.validity_days !== null) {
            const data_di_rilascio = (this.certificationCompanyForm.controls['data_di_rilascio'].value !== null)?(new Date(this.certificationCompanyForm.controls['data_di_rilascio'].value)):(null);
            this.certificationCompanyForm.patchValue({
                data_fine_validita: (data_di_rilascio !== null)?(data_di_rilascio.setDate(data_di_rilascio.getDate() + this.validity_days)):null
            });
        }
    }

    /**
     * Setta il parametro validity_days con il valore della certificazione attuale
     * @param string event
     * @return void
     */
    setValidityDays(event:string): void {
        this.getAvailableCertification(event);
        let aux = this.availableCertification.find((cert:any) => cert.id === event);
        this.validity_days = aux.validity_days;
    }
}
