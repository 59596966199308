import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CallsService } from 'src/app/shared/services/calls.service';

@Component({
    selector: 'app-dashboard-aside',
    templateUrl: './dashboard-aside.component.html',
    styleUrls: ['./dashboard-aside.component.scss']
})
export class DashboardAsideComponent {

    opportunities: any;
    newOpportunities: any;
    currentOpportunityIndex: number = 0;
    currentNewOpportunityIndex: number = 0;
    currentOpportunity: any;

    constructor(private router: Router, private callService: CallsService) { }

    yourCall(): void {
        this.callService.yourCall().subscribe({
            next: (res) => {
                this.opportunities = res.data.personal_calls;
                this.newOpportunities = res.data.last_calls;
            }
        });
    }

    goTo(uri: string, id?: any, event?: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        this.router.navigate(((id) ? [uri + id] : [uri]));
    }

    prev() {
        if (this.currentOpportunityIndex > 0) {
            this.currentOpportunityIndex -= 2;
        }
    }

    next() {
        if (this.currentOpportunityIndex < this.opportunities.length - 2) {
            this.currentOpportunityIndex += 2;
        }
    }

    prevPersonal() {
        if (this.currentNewOpportunityIndex > 0) {
            this.currentNewOpportunityIndex -= 1;
        }
    }

    nextPersonal() {
        if (this.currentNewOpportunityIndex < this.newOpportunities.length - 1) {
            this.currentNewOpportunityIndex += 1;
        }
    }
}

