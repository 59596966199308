<div class="certificazioni">
    <div class="row align-items-center certificazioni__header">
        <!-- Testo -->
        <div class="col-md-10 header-box-text">
            <p class="header-box-text__title">{{title}}</p>
            <p class="header-box-text__description"> Data di ultimo aggiornamento: {{lastUpdate}}</p>
        </div>

        <!-- Pulsante -->
        <div class="col-md-2 d-flex justify-content-end header-box-cta" *ngIf="!eht_admin">
            <button type="button" class="btn btn-outline-primary" (click)="onAddElement()">Aggiungi</button>
        </div>
    </div>

    <form [formGroup]="searchForm" class="certificazioni__content-form">
        <nz-input-group [nzPrefix]="prefixIconSearch">
            <input type="text" nz-input placeholder="Cerca certificazione..." formControlName="search" />
        </nz-input-group>
        <ng-template #prefixIconSearch>
            <span nz-icon nzType="search"></span>
        </ng-template>
    </form>

    <drw-data-table class="certificazioni__table" [displayedColumns]="displayedColumns" [endpointDataSource]="endpoint"
        [additionalParams]="params.data" [actions]="true"
        [customPageSize]="customPagination"
        [actionsButton]="actions" [isFilter]="false"
        [endpointPdf]="endpointPdf" (editedElement)="onEditedElement($event)"
        (deletedElement)="onDeletedElement($event)" (lastUpdate)="lastElement($event)" [search]="search">
    </drw-data-table>
</div>