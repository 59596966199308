<div class="insert-call">
    <div class="insert-call__header">
        <button class="go-back label gap-1 align-items-center d-flex" (click)="goTo('dashboard', $event)"
            (keydown)="goTo('dashboard', $event)">
            <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna indietro
        </button>
    </div>

    <section class="insert-call__tab-section">
        <form [formGroup]="dataForm">
            <div nz-col [nzSpan]="24">
                <p class="call-header">{{title}}</p>
                <p class="campi-obbligatori">* Campi obbligatori</p>
            </div>
            <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label class="form-label" for="nomeOpportunita">Nome opportunità *</label>
                    <input nz-input placeholder="Inserisci qui" type="text" formControlName="nome"
                        [ngClass]="{'input-error': !dataForm.controls['nome'].valid && dataForm.controls['nome'].touched}"
                        style="border-radius: 8px;" />
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['nome'].touched && dataForm.controls['nome'].hasError('required')">
                        {{"Il nome dell'opportunità è richiesto" | translate}}
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label class="form-label" for="nomeOpportunita">Codice iniziativa *</label>
                    <input nz-input placeholder="Inserisci qui" type="text" formControlName="codice"
                        [ngClass]="{'input-error': !dataForm.controls['codice'].valid && dataForm.controls['codice'].touched}"
                        style="border-radius: 8px;" />
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['codice'].touched && dataForm.controls['codice'].hasError('required')">
                        {{"Il codice iniziativa è richiesto" | translate}}
                    </div>
                </div>
            </div>

            <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label class="form-label" for="application_date">Data candidatura *</label><br>
                    <nz-range-picker style="width: 100%; border-radius: 8px;" formControlName="application_date"
                        nzFormat="dd/MM/YYYY"
                        [ngClass]="{'input-error': !dataForm.controls['application_date'].valid && dataForm.controls['application_date'].touched}"
                        [nzPlaceHolder]="['Inserisci qui', 'Inserisci qui']"
                        (ngModelChange)="onValue1Change($event)"></nz-range-picker>
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['application_date'].touched && dataForm.controls['application_date'].hasError('required')">
                        {{"Entrambe le date del periodo di partecipazione sono richieste" | translate}}
                    </div>
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['application_date'].touched && dataForm.controls['application_date'].hasError('invalidRange')">
                        {{"Il periodo di partecipazione dev'essere precedente al periodo di caricamento dei documenti" |
                        translate}}
                    </div>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label class="form-label" for="nomeOpportunita">Data caricamento documentazione *</label>
                    <nz-range-picker style="width: 100%; border-radius: 8px;" formControlName="upload_date"
                        nzFormat="dd/MM/YYYY"
                        [ngClass]="{'input-error': !dataForm.controls['upload_date'].valid && dataForm.controls['upload_date'].touched}"
                        [nzPlaceHolder]="['Inserisci qui', 'Inserisci qui']"
                        (ngModelChange)="onValue2Change($event)"></nz-range-picker>
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['upload_date'].touched && dataForm.controls['upload_date'].hasError('required')">
                        {{"Entrambe le date del periodo di caricamento dei documenti sono richieste" | translate}}
                    </div>
                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['upload_date'].touched && dataForm.controls['upload_date'].hasError('invalidRange')">
                        {{"Il periodo di partecipazione dev'essere precedente al periodo di caricamento dei documenti" |
                        translate}}
                    </div>
                </div>
            </div>

            <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                    <label class="form-label" for="nomeOpportunita">Tipologia *</label>

                    <nz-select class="custom-border-radius" style="width: 100%;" formControlName="tipologia"
                        nzPlaceHolder="Sceleziona una tipologia"
                        [ngClass]="{'input-error-2': !dataForm.controls['tipologia'].valid && dataForm.controls['tipologia'].touched}">
                        <nz-option *ngFor="let tipologia of tipologie" [nzValue]="tipologia.key"
                            [nzLabel]="tipologia.value"></nz-option>
                    </nz-select>

                    <div class="text-sm text-danger" style="font-size: 11px;"
                        *ngIf="dataForm.controls['tipologia'].touched && dataForm.controls['tipologia'].hasError('required')">
                        {{"La tipologia è richiesta" | translate}}
                    </div>
                </div>
            </div>

            <!-- begin: Multiselect Settori -->
            <div>
                <p class="call-subtitle">Settori</p>
                <app-multiselect [data]="settoriList" [dataControl]="settoriControl"
                    [(selected_data)]="settori_selezionati" [icon]="'icon_sector.svg'"
                    [placeholder]="'Inserisci qui'"></app-multiselect>
            </div>
            <!-- end: Multiselect Settori -->

            <div>
                <p class="call-subtitle">Certificazioni richieste</p>
                <app-multiselect [data]="certificationsList" [dataControl]="certificationsControl"
                    [(selected_data)]="certifications_selezionati" [name_key]="'Nome'" [placeholder]="'Inserisci qui'"
                    (selected_dataChange)="onCertificationsChange($event)"></app-multiselect>
            </div>

            <drw-data-table *ngIf="this.matchingCount > 0" [displayedColumns]="displayedColumns"
                [stickyDisplayedColumns]="stickyDisplayedColumns" [additionalParams]='certificationsParamsForDatatable'
                [endpointDataSource]="endpoint" [actions]="false"
                [actionsButton]="{ download: false, info: false, modify: false, trash: false, accept: false, refuse: false, upload: false  }"
                [isFilter]="false" [enableNavigation]="true" class="data-table-custom data-table-custom--purplish">
            </drw-data-table>


            <div>
                <p class="call-subtitle">Competenze richieste</p>
                <app-multiselect [data]="skillsList" [dataControl]="skillsControl"
                    [(selected_data)]="skills_selezionati" [placeholder]="'Inserisci qui'"
                    (selected_dataChange)="onSkillsChange($event)"></app-multiselect>
            </div>

            <drw-data-table *ngIf="this.matchingCount2 > 0" [displayedColumns]="displayedColumns2"
                [stickyDisplayedColumns]="stickyDisplayedColumns" [additionalParams]='skillsParamsForDatatable'
                [endpointDataSource]="endpoint2" [actions]="false"
                [actionsButton]="{ download: false, info: false, modify: false, trash: false, accept: false, refuse: false, upload: false  }"
                [isFilter]="false" [enableNavigation]="true" class="data-table-custom">
            </drw-data-table>

            <p class="call-subtitle">Carica dei file</p>

            <div>
                <nz-upload nzType="drag" nzListType="picture" [nzFileList]="fileList" nzAccept=".pdf" nzLimit="0"
                    [nzMultiple]="true" [nzBeforeUpload]="beforeUpload"
                    [nzShowUploadList]="{ showRemoveIcon: true, showPreviewIcon: false }"
                    (nzChange)="handleChange($event)" style="border-radius: 8px;">
                    <img class="mb-3 cloud-img" src="assets/icons/cloud-add.svg" alt="cloud">
                    <div class="ant-upload-text title upload-title">Scegli o trascina i file qui</div>
                    <div class="ant-upload-hint upload-subtitle">Puoi caricare soltanto file PDF non superiori a 50 MB
                    </div>
                    <button class="button choose-file-btn" nz-button>Scegli file</button>
                </nz-upload>
            </div>

            <div>
                <p class="call-subtitle" style="font-weight: 700;">Descrizione dell'opportunità *</p>
                <angular-editor formControlName="descrizione" [config]="editorConfig"
                    style="border-radius: 8px;"></angular-editor>
                <div class="text-sm text-danger" style="font-size: 11px;"
                    *ngIf="dataForm.controls['descrizione'].touched && dataForm.controls['descrizione'].hasError('required')">
                    {{"La descrizione è richiesta" | translate}}
                </div>
            </div>

            <div class="mb-4 ">
                <p class="call-subtitle" style="font-weight: 700;">Regole di partecipazione *</p>
                <angular-editor formControlName="regole" [config]="editorConfig"
                    style="border-radius: 8px;"></angular-editor>
                <div class="text-sm text-danger" style="font-size: 11px;"
                    *ngIf="dataForm.controls['regole'].touched && dataForm.controls['regole'].hasError('required')">
                    {{"La regole di partecipazione sono richieste" | translate}}
                </div>
            </div>
        </form>

        <div nz-col class="d-flex justify-content-end save-cta">
            <button type="submit" class="btn btn-primary" [disabled]="dataForm.invalid" (click)="save()">Salva</button>
        </div>
    </section>
</div>