import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProdottoService } from 'src/app/shared/services/prodotto.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.scss']
})
export class ProdottiComponent {

    /**
    * EndPoint della chiamata API per popolare la tabella.
    */
    @Input() azienda_id!: string | null;

    /**
    * Se è true, serve a gestire la visualizzazione dell'azienda lato eht.
    */
    @Input() eht_admin!: boolean | null;

    deletedValue!: string;
    currentRoute!: string;
    searchForm!: FormGroup;
    search: any;
    searchSubject: Subject<string> = new Subject();
    unsubscribe: Subject<void> = new Subject();
    displayedColumns = ['']
    endpointPdf: string = 'pdf'
    endpoint: string = "products"
    params: any = [];
    lastUpdate: any;

    /*
    Colonne della datatable prodotti
    */
    private productsDataTableColumns: string[] = ['Nome prodotto', 'Settore di interesse', 'Allegati', 'azioni'];
    private productsDataTableColumnsForMobile: string[] = ['Nome prodotto', 'azioni'];

    //Azioni per datatable
    public actions = {
        download: true,
        info: false,
        modify: true,
        trash: true,
        accept: false,
        refuse:false, 
        upload: false
      };

    constructor(
        private prodottoService: ProdottoService,
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService) {
    }

    ngOnInit(): void {
        this.searchForm = this.fb.group({
            search: ['']
        });

        this.searchForm.get('search')?.valueChanges
            .pipe(
                debounceTime(1000),  // Imposta il delay in millisecondi
                distinctUntilChanged(),  // Evita duplicati consecutivi
                takeUntil(this.unsubscribe)  // Interrompe l'osservazione quando il componente viene distrutto
            )
            .subscribe(value => {
                this.onSearch(value);
            });

        this.currentRoute = this.router.url;
        Object.assign(this.params, { 'data': localStorage.getItem('user_id') });

        //Invio l'id dell'azienda da visualizzare e rimuovo i pulsanti che eht non può cliccare
        if(this.eht_admin) {
            Object.assign(this.params, { 'data': this.azienda_id });

        this.actions = {
            download: true,
            info: true,
            modify: false,
            trash: false,
            accept: false,
            refuse:false,
            upload: false
          };
        }

        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.productsDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.productsDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.productsDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.productsDataTableColumnsForMobile));
                }
            });
    }

    onSearch(searchValue: string): void {
        this.search = searchValue;
    }

    lastElement(value: any): void {
        this.lastUpdate = value;
    }

    goToNewProductSection(event?: Event): void {
        const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
        const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
        const isTouchEvent = event instanceof TouchEvent;
        if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
            return;
        }
        event.preventDefault();
        this.router.navigate(['profilo/azienda/prodotto']);
    }

    goToEditProductSection(dataToEdit?: any): void {
        this.router.navigate([`profilo/azienda/prodotto/${dataToEdit?.id || ''}`]);
    }

    onDeletedElement(value: any) {
        this.prodottoService.deleteProdotto(value).subscribe({
            next: (res) => {
                this.prodottoService.updateDataTable();
                this.commonService.openToastAlert(res?.message, true, {
                    position: 'top-end',
                    icon: 'success',
                    iconColor: '#0dd30d',
                });
            },
            error: (err) => {
                this.commonService.openToastAlert(err?.error?.message, true, {
                    position: 'top-end',
                    icon: 'error',
                    iconColor: '#f03506',
                });
            }
        })
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
