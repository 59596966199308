import { Component, Input } from '@angular/core';
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {ModalCompetenzeComponent} from "./modal-competenze/modal-competenze.component";
import {SkillService} from "../../shared/services/skill.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-competenze',
    templateUrl: './competenze.component.html',
    styleUrls: ['./competenze.component.scss']
})
export class CompetenzeComponent {

    /**
        * EndPoint della chiamata API per popolare la tabella.
        */
    @Input() azienda_id!: string | null;

    /**
     * Se è true, serve a gestire la visualizzazione dell'azienda lato eht.
     */
    @Input() eht_admin!: boolean | null;


    displayedColumns = [''];

    displayedFilters =[
        'Servizio','Data','Modalità'
    ]

    endpoint: string = "azienda/competenze/index";
    currentRoute!: string;
    searchForm!: FormGroup;
    search: any;
    unsubscribe: Subject<void> = new Subject();
    lastUpdate: any;
    params: any = [];
    /*
    Colonne della datatable competenze/skills
    */
    private skillsDataTableColumns: string[] = ['competenza', 'basse', 'medie', 'alte', 'azioni'];
    private skillsDataTableColumnsForMobile: string[] = ['competenza completa', 'azioni'];

    public actions = { download: false, info: false, modify: true, trash: true, accept: false, refuse: false, upload: false  }

    constructor(
        private router: Router,
        private modalService: NzModalService,
        private skillService: SkillService,
        private fb: FormBuilder,
        private commonService: CommonService
    ) {
        /* 
        Verifico se sono nella modalità mobile (SOLO smartphone) o desktop (tablet inclusi)
        */
        this.commonService.isMobileWithoutTablet$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isMobileWithoutTablet: boolean) => {
                if (!isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.skillsDataTableColumns)) {
                    // se sono nella modalità desktop (tablet inclusi), provvedo a copiare l'array dedicato per mostrare tutte le colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.skillsDataTableColumns));
                } else if (isMobileWithoutTablet && JSON.stringify(this.displayedColumns) !== JSON.stringify(this.skillsDataTableColumnsForMobile)) {
                    // se sono nella modalità mobile (SOLO smartphone), provvedo a copiare l'array dedicato per mostrare solo alcune colonne della datatable
                    this.displayedColumns = JSON.parse(JSON.stringify(this.skillsDataTableColumnsForMobile));
                }
            });
    }

    ngOnInit(): void {

        //Se siamo in visualizzazione dettaglio azienda lato eht, invio l'id dell'azienda da visualizzare
       if(this.eht_admin){
        Object.assign(this.params, { 'data': this.azienda_id });
        this.actions = { download: false, info: false, modify: false, trash: false, accept: false, refuse: false, upload: false  }
       }

       this.currentRoute = this.router.url;
        this.searchForm = this.fb.group({
            search: ['']
        });

        this.searchForm.get('search')?.valueChanges.pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                takeUntil(this.unsubscribe)
            )
            .subscribe(value => {
                this.search = value;
            });
    }

    onAddElement(): void {
        if (this.currentRoute === '/profilo/risorse') {
            this.modalService.create({
                nzContent: ModalCompetenzeComponent,
                nzData: null,
                nzFooter: null,
                nzMaskClosable: false,
                nzWidth: '872px'
            });
        }
    }

    onEditedElement(element:any) {
        element.competenza = element.competenza[0].id;
        this.modalService.create({
            nzContent: ModalCompetenzeComponent,
            nzData: element,
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '872px'
        });
    }

    onDeletedElement(id:any) {
        this.skillService.deleteSkill(id).subscribe({
            next: (res) => {
                this.commonService.openToastAlert(res?.message, true, {
                    position: 'top-end',
                    icon: 'success',
                    iconColor: '#0dd30d',
                });
            },
            error: (err) => {
                this.commonService.openToastAlert(err?.error?.message, true, {
                    position: 'top-end',
                    icon: 'error',
                    iconColor: '#f03506',
                });
            }
        });
    }

    lastElement(value: any) {
        this.lastUpdate = value;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
