<div class="dettaglio-opportunita page-wrapper">
    <!-- begin: Sezione hero header mobile (escluso tablet) -->
    <section class="hero-section-opportunita-mobile">
        <div class="hero-section-opportunita-mobile__title-box">
            <button class="go-back go-back--mobile label" (click)="goTo('dashboard', $event)"
                (keydown)="goTo('dashboard', $event)">
                <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna alla dashboard
            </button>
            <h5 class="title">{{ opportunita.name }}</h5>
        </div>
        <div class="hero-section-opportunita-mobile__content">
            <p class="content-text"><span class="content-label">Codice
                    iniziativa</span>{{opportunita.codice_iniziativa}}</p>
            <p class="content-text" *ngIf="opportunita?.application_date?.[0] && opportunita?.application_date?.[1]"><span class="content-label">Finestra di candidabilità</span>dal
                {{opportunita.application_date[0] | date: 'd/M/Y' }} al {{opportunita.application_date[1] | date:
                'd/M/Y'}}</p>
            <p class="content-text" *ngIf="opportunita?.upload_date?.[0] && opportunita?.upload_date?.[1]"><span class="content-label">Finestra di presentazione
                    documentazione</span> dal {{opportunita.upload_date[0] | date: 'd/M/Y' }} al
                {{opportunita.upload_date[1] | date: 'd/M/Y'}}</p>
            <p class="content-text"><span class="content-label">Tipologia</span>{{opportunita.tipologia}}</p>
            <p class="content-text"><span class="content-label">Stato</span>{{opportunita.stato}}</p>
        </div>
        <div class="hero-section-opportunita-mobile__cta-box" *ngIf="role_id!==1 && !participationFlag && opportunita.stato == 'Aperta'">
            <button class="cta-participation" nz-button nzType="default" type="button"
                [disabled]="opportunita.mandatory_company_certifications != '' || !opportunita.is_enrollable"
                (click)="participate()">
                Partecipa
            </button>
        </div>
    </section>
    <!-- end: Sezione hero header mobile (escluso tablet) -->

    <section class="section-content">
        <div class="header">
            <button class="go-back go-back--desktop label" (click)="goTo('dashboard', $event)"
                (keydown)="goTo('dashboard', $event)">
                <img src="../../../assets/icons/go_back.svg" alt="go-back"> Torna alla dashboard
            </button>


            <div class="card text-white w-100">
                <img class="card-img" src="assets/img/banner-dettaglio-opportunita.svg" alt="card-img">
                <div class="card-img-overlay d-flex flex-column justify-content-center">
                    <div class="justify-content-between" nz-row [nzGutter]="24">
                        <p class="ps-0 card-title" nz-col [nzSpan]="18">{{ opportunita.name }}</p>
                        <button class="cta-participation" nz-button nzType="default" type="button" nz-col
                            *ngIf="role_id!==1 && !participationFlag && opportunita.stato == 'Aperta'" [nzSpan]="6" (click)="participate()"
                            [disabled]="opportunita.mandatory_company_certifications != '' || !opportunita.is_enrollable">
                            Partecipa
                        </button>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div class="ps-0" nz-col [nzSpan]="24">
                            <p class="card-text"><span class="card-label">Codice
                                    iniziativa</span>&nbsp;&nbsp;{{opportunita.codice_iniziativa}}</p>
                            <p class="card-text"
                                *ngIf="opportunita?.application_date?.[0] && opportunita?.application_date?.[1]"><span
                                    class="card-label">Finestra di candidabilità</span> dal
                                {{opportunita.application_date[0] | date: 'd/M/Y' }} al
                                {{opportunita.application_date[1] | date: 'd/M/Y'}}
                            </p>
                            <p class="card-text" *ngIf="opportunita?.upload_date?.[0] && opportunita?.upload_date?.[1]">
                                <span class="card-label">Finestra di presentazione
                                    documentazione</span> dal {{opportunita.upload_date[0] | date: 'd/M/Y' }} al
                                {{opportunita.upload_date[1] | date: 'd/M/Y'}}</p>
                            <p class="card-text"><span
                                    class="card-label">Tipologia</span>&nbsp;&nbsp;{{opportunita.tipologia}}
                            </p>
                            <p class="card-text"><span class="card-label">Stato</span>&nbsp;&nbsp;{{opportunita.stato}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="opportunity-details">
            <nz-tabset class="tab-content">
                <nz-tab nzTitle="Dati generali">
                    <!-- begin: Sezione dati generali -->
                    <div class="details-wrapper">
                        <div class="alert-hig" [ngClass]="{
                                'alert-hig--success': participationInfo.is_accepted == 1,
                                'alert-hig--warning': participationInfo.is_accepted == null || participationInfo.is_accepted == undefined,
                                'alert-hig--error': participationInfo.is_accepted == 0
                            }" *ngIf="role_id !== 1 && participationInfo && participationFlag">
                            <div class="alert-hig__logo"></div>
                            <div class="alert-hig__content">
                                <p *ngIf="participationInfo.is_accepted == 1">
                                    Complimenti! La tua richiesta è stata <b>approvata</b>
                                </p>
                                <p
                                    *ngIf="participationInfo.is_accepted == null || participationInfo.is_accepted == undefined">
                                    Hai aderito a questa opportunità e sei in attesa di una nostra risposta. Sono
                                    richiesti dei documenti obbligatori da caricare <b
                                        *ngIf="opportunita?.upload_date?.[0] && opportunita?.upload_date?.[1]">a partire
                                        dalla data {{opportunita.upload_date[0] | date: 'd/M/Y'}} fino al
                                        {{opportunita.upload_date[1] | date: 'd/M/Y'}}</b>
                                </p>
                                <ng-container *ngIf="participationInfo.is_accepted == 0">
                                    <p>Ci dispiace, la tua richiesta è stata <b>rifiutata</b><span
                                            *ngIf="participationInfo.nota">.</span></p>
                                    <p *ngIf="participationInfo.nota">"{{participationInfo.nota}}"</p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="detail">
                            <h5 class="section-title">Descrizione dell'opportunità</h5>
                            <p class="detail-text" [innerHTML]="opportunita.description"></p>
                        </div>
                        <div class="detail">
                            <h5 class="section-title">Regole di partecipazione</h5>
                            <p class="detail-text" [innerHTML]="opportunita.info_partecipation_call"></p>
                        </div>
                        <div class="dt-wrapper-container" nz-row [nzGutter]="24">
                            <div nz-col [nzSpan]="24" [nzMd]="12" class="dt-wrapper">
                                <h5 class="section-title">Certificazioni richieste</h5>
                                <drw-data-table [displayedColumns]="certificazioniDisplayedColumns"
                                    [endpointDataSource]="opportunita.certifications"></drw-data-table>
                            </div>
                            <div nz-col [nzSpan]="24" [nzMd]="12" class="dt-wrapper">
                                <h5 class="section-title">Competenze richieste</h5>
                                <drw-data-table [displayedColumns]="competenzeDisplayedColumns"
                                    [endpointDataSource]="opportunita.skills"></drw-data-table>
                            </div>
                        </div>
                        <div class="detail">
                            <h5 class="section-title">Allegati</h5>
                            <p *ngIf="opportunita.files.length === 0; else printAttachments">Nessun allegato presente!
                            </p>
                            <ng-template #printAttachments>
                                <div class="alert info m-0 d-flex mt-2 gap-3 align-items-center"
                                    *ngFor="let file of opportunita.files" role="alert">
                                    <img class="" src="assets/icons/pdf.svg" alt="">
                                    <a (click)="openLink(file)" target="blank">{{ file.name }}</a>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <!-- end: Sezione dati generali -->
                </nz-tab>
                <nz-tab nzTitle="Aziende" *ngIf="visualizzazione_eht">
                    <drw-data-table class="opportunity-table" [displayedColumns]="risposteDisplayedColumns"
                        [endpointDataSource]="opportunita.responses" [actions]="true" [actionsButton]="actionsButton"
                        [isFilter]="false" [search]="search" [enableNavigation]="true"
                        (manageParticipation)="manageParticipation($event)" (openNoteModal)="openNoteModal($event)" (openShowDocModal)="openShowDocModal($event)">
                    </drw-data-table>
                </nz-tab>
                <nz-tab nzTitle="Documentazione" *ngIf="participationFlag && !visualizzazione_eht">

                    <div class="details-wrapper mb-4">
                        <div class="alert-hig" [ngClass]="{
                                'alert-hig--success': participationInfo.is_accepted == 1,
                                'alert-hig--warning': participationInfo.is_accepted == null || participationInfo.is_accepted == undefined,
                                'alert-hig--error': participationInfo.is_accepted == 0
                            }" *ngIf="role_id !== 1 && participationInfo && participationFlag">
                            <div class="alert-hig__logo"></div>
                            <div class="alert-hig__content">
                                <p *ngIf="participationInfo.is_accepted == 1">
                                    Complimenti! La tua richiesta è stata <b>approvata</b>
                                </p>
                                <p
                                    *ngIf="participationInfo.is_accepted == null || participationInfo.is_accepted == undefined">
                                    Hai aderito a questa opportunità e sei in attesa di una nostra risposta. Sono
                                    richiesti dei documenti obbligatori da caricare <b
                                        *ngIf="opportunita?.upload_date?.[0] && opportunita?.upload_date?.[1]">a partire
                                        dalla data {{opportunita.upload_date[0] | date: 'd/M/Y'}} fino al
                                        {{opportunita.upload_date[1] | date: 'd/M/Y'}}</b>
                                </p>
                                <ng-container *ngIf="participationInfo.is_accepted == 0">
                                    <p>Ci dispiace, la tua richiesta è stata <b>rifiutata</b><span
                                            *ngIf="participationInfo.nota">.</span></p>
                                    <p *ngIf="participationInfo.nota">"{{participationInfo.nota}}"</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <app-documentazione-azienda
                    [callId]="callId"
                    ></app-documentazione-azienda>
                </nz-tab>
            </nz-tabset>
        </section>


    </section>
</div>