import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, Subject } from "rxjs";
import { FormattedResponse } from "../models/formatted-response.model";

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    private reloadSubject = new Subject<void>();

    reload$ = this.reloadSubject.asObservable();

    constructor(private http: HttpClient) { }

    getCompany(id: string): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/companies/${id}`);
    }

    getCompanies(): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/companies/`);
    }

    getCompanyOwnedByCurrentUser(): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/getCompanyOwnedByCurrentUser`);
    }

    getCompanyOwnedById(id : any): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/getCompanyOwnedById/${id}`);
    }

    update(id: string, payload: any): Observable<FormattedResponse> {
        return this.http.put<any>(`${environment.ApiURL}/companies/${id}`, payload);
    }

    getAutoCompleteComuni(term: any): Observable<FormattedResponse> {
        return this.http.post<any>(`${environment.ApiURL}/autoCompleteComuni`, term);
    }

    getProvinces(): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/provinces`);
    }

    obtainCap(term: any): Observable<FormattedResponse> {
        return this.http.post<any>(`${environment.ApiURL}/obtainCap`, { comune: term });
    }

    getSettori(): Observable<FormattedResponse> {
        return this.http.get<any>(`${environment.ApiURL}/settori`);
    }

    createAzienda(params: any): Observable<FormattedResponse> {
        return this.http.post<any>(`${environment.ApiURL}/storeAzienda`, params);
    }

    destroyAzienda(id: any): Observable<FormattedResponse> {
        return this.http.delete<any>(`${environment.ApiURL}/companies/${id}`);
    }

    updateDataTable() {
        this.reloadSubject.next();
    }
}
