<div class="legenda-competenze">
    <div class="alert-hig">
        <div class="alert-hig__logo" *ngIf="showHigLogo"></div>
        <div class="alert-hig__content">
            <div class="row righe-legenda ms-0">
                <div class="col-auto ms-3">
                    <div class="row">
                        <div class="col-auto"><b>Bassa:</b></div>
                        <div class="col-auto">da meno di due anni</div>
                    </div>
                </div>
                <div class="col-auto ms-3">
                    <div class="row">
                        <div class="col-auto"><b>Media:</b></div>
                        <div class="col-auto">dai due ai quattro anni</div>
                    </div>
                </div>
                <div class="col-auto ms-3">
                    <div class="row">
                        <div class="col-auto"><b>Alta:</b></div>
                        <div class="col-auto">da più di quattro anni</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>