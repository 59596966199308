import { Component, OnInit } from '@angular/core';
import { Company } from "../../shared/models/company.model";
import { CompaniesService } from "../../shared/services/companies.service";
import { FormattedResponse } from "../../shared/models/formatted-response.model";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, BehaviorSubject } from 'rxjs';
import { Settore } from 'src/app/shared/models/settore';
import { CertificationService } from 'src/app/shared/services/certification.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-dati-generali',
    templateUrl: './dati-generali.component.html',
    styleUrls: ['./dati-generali.component.scss']
})
export class DatiGeneraliComponent implements OnInit {
    datiGeneraliForm: FormGroup;
    comuni: any;
    filteredOptions: any;
    searchTerms = new Subject<string>();
    settori: BehaviorSubject<Settore[]>;
    company: Company = {
        id: '',
        ragione_sociale: '',
        email: '',
        partita_iva: '',
        numero_dipendenti: 0
    };
    settori_selezionati: any[] = [];
    checkPassword: boolean | null = null;
    available_cap: Array<string> = [];

    constructor(
        private companiesService: CompaniesService,
        private certificationService: CertificationService,
        private commonService: CommonService,
        private fb: FormBuilder) {
        this.datiGeneraliForm = this.fb.group({
            ragione_sociale: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            partita_iva: ['', Validators.required],
            codice_fiscale: '',
            numero_dipendenti: [0, Validators.required],
            indirizzo_sede_legale: '',
            nazione: [{ value: 'Italia', disabled: false }],
            comune_sede_legale: null,
            provincia_sede_legale: [{ value: '', disabled: false }],
            cap_sede_legale: '',
            settori: [[]],
            password: new FormControl(null),
            password_confirmation: [''],
        });
        this.settori = new BehaviorSubject<Settore[]>([]);
    }

    ngOnInit(): void {

        this.companiesService.getSettori().subscribe({
            next: (res: any): void => {
                this.settori.next(res.data);
            }
        });

        this.getCompany();
        this.datiGeneraliForm.get('comune_sede_legale')?.valueChanges.subscribe(term => {
            this.companiesService.getAutoCompleteComuni({ search: term, skip: 0, take: 100 })
                .subscribe(res => {
                    this.comuni = res.data;
                });
        });
    }


    onOptionSelected(option: any): void {
        this.companiesService.obtainCap(option.nzValue).subscribe(res => {
            this.available_cap = res.data.cap;
            this.datiGeneraliForm.patchValue({
                provincia_sede_legale: res.data.sigla,
                cap_sede_legale: ''
            })
        })
    }

    getComuni() {
        this.companiesService.getAutoCompleteComuni({ search: null, skip: 0, take: 100 }).subscribe(res => {
            this.comuni = res.data;
        })
    }


    getCompany() {
        this.companiesService.getCompanyOwnedByCurrentUser().subscribe((res: FormattedResponse): void => {
            this.company = <Company>res['data'];
            this.datiGeneraliForm.patchValue(this.company);

            this.datiGeneraliForm.patchValue({
                settori: res.data.settore.map((sk: any) => Number(sk.settore_id)),
            })

            this.settori_selezionati = this.datiGeneraliForm.get('settori')?.value;
        });
    }

    onSubmit() {
        this.datiGeneraliForm.patchValue({
            comune_sede_legale: this.datiGeneraliForm.get('comune_sede_legale')?.value
        });
        this.companiesService.update(this.company.id, this.datiGeneraliForm.value).subscribe({
            next: (res: FormattedResponse): void => {
                this.certificationService.updateDataTable();
                this.commonService.openToastAlert('Profilo aziendale modificato correttamente', true, {
                    position: 'top-end',
                    icon: 'success'
                });
                this.getCompany();
            },
            error: (err: HttpErrorResponse) => {
                let title: string;
                if (typeof err.message === 'string') {
                    title = err.message;
                } else if (typeof err.message === 'object' && err.message !== null) {
                    // Handling if message is an object with arrays of strings
                    title = Object.values(err.message)
                        .flatMap((messages) => messages)
                        .join('\n');
                } else {
                    title = 'Unknown error';
                }
                this.commonService.openToastAlert(title, true, {
                    position: 'top-end',
                    icon: 'error'
                });
            }
        });
    }

    /**
 *
 * @param data // indicare l'array principale che viene ciclato, tra skills e certifications
 * @param updatedData  // indicare l'array d'appoggio di cui si vuole fare l'update (updateCertifications, updateSkills, updateSettori)
 * @returns
 */
    getSelection(data: any, updatedData: any) {
        return data.value.filter((selected: any) => updatedData.includes(selected.id))
            .sort((a: any, b: any) => (a.Nome || a.name).localeCompare((b.Nome || b.name)));
    }


    /**
     *
     * @param selectedId // indicare l'id dell'elemento da eliminare dai selezionati
     * @param updatedData // indicare l'array d'appoggio di cui si vuole fare l'update (updateCertifications, updateSkills, updateSettori)
     */
    changeSelection(selectedId: number, updatedData: any): void {
        const index = updatedData.indexOf(selectedId);
        if (index !== -1) {
            updatedData.splice(index, 1);
        }
    }


    //Controlla se la password rispetta il formato richiesto
    passwordStrengthValidator(control: FormControl): { [key: string]: any } | null {
        const value = control.value || '';
        let error: { [key: string]: boolean } = {};
        if (!/(?=.*[A-Z])/.test(value)) { // Controlla per una lettera maiuscola
            error = { ...error, uppercase: true };
        }
        if (!/(?=.*[a-z])/.test(value)) { // Controlla per una lettera minuscola
            error = { ...error, lowercase: true };
        }
        if (!/(?=.*\d)/.test(value)) { // Controlla per un numero
            error = { ...error, number: true };
        }
        if (!/[$@*!%?&]/.test(value)) { // Controlla per un simbolo
            error = { ...error, symbol: true };
        }
        if (value.length < 8) {
            error = { ...error, minlength: true };
        }

        return error;
    }

    //Controlla se le due password inserite coincidono
    passwordCheckhValidator(event: any) {

        const passwordControl = this.datiGeneraliForm.get('password')?.value;

        if (!passwordControl) {
            return;
        }

        if (this.datiGeneraliForm.get('password')?.value != event) {
            this.checkPassword = false;
        } else {
            this.checkPassword = true;
        }
    }

    //Setta o rimuove i validator dal campo password in caso di update azienda
    setValidators(event: any) {

        //se la password è stata cancellata dall input, resetto i form e rimuovo i validator
        if (event == null || event == '') {

            this.datiGeneraliForm.patchValue({
                password: null,
                password_confirmation: null,
            })

            this.checkPassword = true;

            this.datiGeneraliForm.get('password')?.clearValidators()
            this.datiGeneraliForm.get('password_confirmation')?.clearValidators()

            this.datiGeneraliForm.get('password')?.updateValueAndValidity()
            this.datiGeneraliForm.get('password_confirmation')?.updateValueAndValidity()

        } else {
            this.datiGeneraliForm.get('password')?.addValidators([Validators.required, this.passwordStrengthValidator]);
            this.datiGeneraliForm.get('password_confirmation')?.addValidators([Validators.required]);

            this.datiGeneraliForm.get('password')?.updateValueAndValidity()
            this.datiGeneraliForm.get('password_confirmation')?.updateValueAndValidity()
        }
    }

    get settoriControl(): FormControl {
        return this.datiGeneraliForm.get('settori') as FormControl;
    }

}
