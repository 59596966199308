import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-profilo-azienda',
  templateUrl: './profilo-azienda.component.html',
  styleUrls: ['./profilo-azienda.component.scss']
})
export class ProfiloAziendaComponent implements OnInit {
    protected current_tab: number = 0;

    ngOnInit() {
        let savedTab = localStorage.getItem('current_tab');
        this.current_tab = savedTab !== null ? parseInt(savedTab, 10) : 0;
        localStorage.setItem('current_tab', '0');
    }

}
