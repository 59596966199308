<div class="dashboard-opportunita">
    <!-- begin: Sezione hero header mobile (escluso tablet) -->
    <section class="hero-section-dashboard-mobile">
        <div class="hero-section-dashboard-mobile__title-box">
            <div class="header-title">
                <img src="../../../assets/img/hero-logo.png" alt="Portale delle opportunità" />
                <div>
                    <p class="mb-1">Ciao</p>
                    <h5 class="current-user m-0">{{(authService.currentUser | async)?.name}}</h5>
                </div>
            </div>

            <!-- begin: Sezione Notifiche (da implementare) -->
            <div class="alert alert-warning" role="alert" *ngIf="false">
                <img src="assets/icons/Notification_Alarm.svg" alt="">
                <p class="notification">Hai delle notifiche da leggere nella sezione "Le tue opportunità"
                </p>
            </div>
            <!-- end: Sezione Notifiche (da implementare) -->
        </div>
        <div class="hero-section-dashboard-mobile__opportunity">
            <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="isAdmin">
                <div>
                    <h2 class="title text-white m-0">Inserisci una nuova<br />opportunità</h2>
                </div>
                <button class="btn custom-button" (click)="goTo('nuova-opportunita', $event)"
                    (keydown)="goTo('nuova-opportunita', $event)">Inserisci</button>
            </div>
            <ng-container *ngIf="!isAdmin">
                <div class="self-opportunity">
                    <h2 class="aside-title m-0">Le tue opportunità</h2>
                    <a class="view-all" *ngIf="countPersonalCall.value != 0"
                        (click)="goTo('le-tue-opportunita', $event)">Visualizza tutte</a>
                </div>
                <div class="alert info" role="alert">
                    <img src="assets/icons/info.svg" alt="Avviso opportunità">
                    <p class="notification">
                        {{ (countPersonalCall.value && countPersonalCall.value != 0) ? 'Hai aderito a ' +
                        countPersonalCall.value + ' opportunità' : 'Nessuna opportunità' }}
                    </p>
                </div>
            </ng-container>
        </div>
    </section>
    <!-- end: Sezione hero header mobile (escluso tablet) -->

    <section class="section-content">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 mb-4 p-0 header-info">
                <div class="card intestation col-8">
                    <div class="card-body d-flex p-0 align-items-center">
                        <div class="ms-3">
                            <div class="d-flex flex-row align-items-center">
                                <img src="../../../assets/img/hero-logo.png" alt="Portale delle opportunità"
                                    class="me-3" height="48" />
                                <div>
                                    <p class="card-text text-white m-0">Ciao</p>
                                    <h5 class="secondary_intestation m-0">{{(authService.currentUser | async)?.name}}
                                    </h5>
                                </div>
                            </div>

                            <!-- begin: Sezione Notifiche (da implementare) -->
                            <div class="alert alert-warning m-0 mt-2" role="alert" *ngIf="false">
                                <img src="assets/icons/Notification_Alarm.svg" alt="">
                                <p class="notification">Hai delle notifiche da leggere nella sezione "Le tue
                                    opportunità"
                                </p>
                            </div>
                            <!-- end: Sezione Notifiche (da implementare) -->
                        </div>
                    </div>
                </div>
                <div *ngIf="isAdmin" class="container col-4 " style="padding: 0 0 0 1.5rem;">
                    <div
                        class="card insert-opportunity custom-card d-flex flex-row flex-wrap justify-content-between align-items-center">
                        <div>
                            <h2 class="title text-white m-0">Inserisci <br />una nuova <br />opportunità</h2>
                        </div>
                        <button class="btn custom-button" (click)="goTo('nuova-opportunita', $event)"
                            (keydown)="goTo('nuova-opportunita', $event)">Inserisci</button>
                    </div>
                </div>
                <div *ngIf="!isAdmin" class="container col-4" style="padding: 0 0 0 1.5rem;">
                    <div class="card insert-opportunity custom-card justify-content-center">
                        <div class="self-opportunity d-flex justify-content-between text-white flex-wrap">
                            <h2 class="aside-title m-0">Le tue opportunità</h2>
                            <a class="notification text-white" *ngIf="countPersonalCall.value != 0"
                                (click)="goTo('le-tue-opportunita', $event)"
                                (keydown)="goTo('le-tue-opportunita', $event)">Visualizza tutte</a>
                        </div>

                        <div class="alert info m-0 mt-2 px-2" role="alert">
                            <img src="assets/icons/info.svg" alt="">
                            <p class="notification mb-0 px-2">{{ (countPersonalCall.value && countPersonalCall.value !=
                                0) ? 'Hai aderito a ' + countPersonalCall.value + ' opportunità' : 'Nessuna
                                opportunità'}}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="px-0 mb-4">
                <p class="title mb-3">Elenco delle opportunità proposte</p>
                <form [formGroup]="searchForm">
                    <div class="row align-items-center">
                        <div class="col-md-5 d-flex">
                            <nz-input-group [nzPrefix]="prefixIconSearch" class="w-100">
                                <input type="text" nz-input placeholder="Cerca per nome..." formControlName="search" />
                            </nz-input-group>
                            <ng-template #prefixIconSearch>
                                <span nz-icon nzType="search"></span>
                            </ng-template>
                        </div>
                        <div class="col-md-4 d-flex">
                            <nz-select nzShowSearch nzAllowClear="true" nzPlaceHolder="Tipologia"
                                formControlName="tipologia" class="w-100">
                                <nz-option *ngFor="let typology of typologies" [nzLabel]="typology.label"
                                    [nzValue]="typology.value"></nz-option>
                            </nz-select>
                        </div>
                        <div class="col-md-3">
                            <nz-select nzShowSearch nzAllowClear="true" nzPlaceHolder="Stato" formControlName="stato"
                                class="w-100">
                                <nz-option *ngFor="let status of status" [nzLabel]="status.label"
                                    [nzValue]="status.value"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                </form>
            </div>

            <div class="approval-legend pb-2">
                <small class="label">
                    <img class="icon-size" src="assets/icons/approved.svg" alt="Approvata">
                    Approvata
                </small>
                <small class="label">
                    <img class="icon-size2" src="assets/icons/rejected.svg" alt="Non approvata">
                    Non approvata
                </small>
            </div>
            <drw-data-table class="opportunity-table" [displayedColumns]="displayedColumns"
                [endpointDataSource]="endpoint" [actions]="true" [actionsButton]="actionsButton" [isFilter]="false"
                [endpointPdf]="endpointPdf" [search]="search" [enableNavigation]="true"
                (editedElement)="editCall($event)" (deletedElement)="deleteCall($event)"
                (countPersonalCall)="countPersonalCallElement($event)">
            </drw-data-table>
        </div>
    </section>
</div>