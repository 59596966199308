<div class="modal-cert-azienda container">
    <h1 class="title">{{action}} certificazione</h1>
    <p class="campi-obbligatori">* Campi obbligatori</p>
    <form [formGroup]="certificationCompanyForm">
        <div class="form-content">
            <app-pcall-loader [showLoader]="isLoading">
                <div class="d-flex flex-column">
                    <div class="alert-hig alert-hig--success" *ngIf="certificationCompanyFlag">
                        <div class="alert-hig__logo"></div>
                        <div class="alert-hig__content">
                            <p>La certificazione “{{certificationCompanyForm.get('nome')?.value}}” è stata aggiunta come
                                certificazione personalizzata</p>
                        </div>
                    </div>
                    <label class="label" for="id_certificazione">Certificazione *</label>
                    <nz-select [nzDisabled]="data.data.Obbligatoria && data.data.Obbligatoria == 'Si'" class="w-100" nzShowSearch nzAllowClear="false"
                        nzPlaceHolder="Seleziona una certificazione" formControlName="id_certificazione"
                        (nzOnSearch)="handleSearchChange($event)"
                        (ngModelChange)="alertSignature($event); setValidityDays($event); saveNewCompanyCertification($event); setFineValidita()"
                        [nzFilterOption]="filterOptionCompany">
                        <nz-option *ngFor="let certification of availableCertification" [nzLabel]="certification.Nome"
                            [nzValue]="certification.id"></nz-option>
                        <nz-option *ngIf="searchTermCompany != ''" [nzValue]="-1" [nzCustomContent]="true"
                            [nzLabel]="searchTermCompany">
                            <div [innerHTML]="customLabel"></div>
                        </nz-option>
                    </nz-select>
                    <div *ngIf="certificationCompanyForm.get('id_certificazione')?.invalid && certificationCompanyForm.get('id_certificazione')?.touched; else Span"
                        class="mb-2 mt-0 camp-validation">
                        <div *ngIf="certificationCompanyForm.get('id_certificazione')?.errors?.['required']">La
                            certificazione è richiesta</div>
                    </div>
                    <ng-template #Span>
                        <span class=" mb-3"></span>
                    </ng-template>
                </div>

                <div class="alert-hig alert-hig--error mb-3 mt-2" *ngIf="validity_days">
                    <div class="alert-hig__logo"></div>
                    <div class="alert-hig__content">
                        <p>La data di scadenza viene calcolata automaticamente dal sistema</p>
                    </div>
                </div>

                <div class="d-flex justify-content-between" nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="24" [nzMd]="12" class="d-flex flex-column">
                        <label class="label" for="data_di_rilascio">Data di emissione *</label>
                        <nz-date-picker formControlName="data_di_rilascio" nzFormat="dd/MM/YYYY"
                            nzPlaceHolder="Seleziona una data" (ngModelChange)="setFineValidita()"></nz-date-picker>
                        <div *ngIf="certificationCompanyForm.get('data_di_rilascio')?.invalid && certificationCompanyForm.get('data_di_rilascio')?.touched; else Span"
                            class="mb-2 mt-0 camp-validation">
                            <div *ngIf="certificationCompanyForm.get('data_di_rilascio')?.errors?.['required']">La data
                                di
                                emissione è
                                richiesta
                            </div>
                        </div>
                        <ng-template #Span>
                            <span class="mb-4"></span>
                        </ng-template>
                    </div>

                    <div nz-col [nzSpan]="24" [nzMd]="12" class="d-flex flex-column">
                        <label class="label" for="data_fine_validita">Data di scadenza *</label>
                        <nz-date-picker formControlName="data_fine_validita" nzFormat="dd/MM/YYYY"
                            nzPlaceHolder="Seleziona una data" [nzDisabled]="validity_days !== null"></nz-date-picker>
                        <div *ngIf="certificationCompanyForm.get('data_fine_validita')?.invalid && certificationCompanyForm.get('data_fine_validita')?.touched; else Span"
                            class="mb-2 mt-0 camp-validation">
                            <div *ngIf="certificationCompanyForm.get('data_fine_validita')?.errors?.['required']">La
                                data di
                                scadenza è
                                richiesta
                            </div>
                        </div>
                        <ng-template #Span>
                            <span class="mb-4"></span>
                        </ng-template>
                    </div>
                </div>
                
                <div [ngClass]="signatureFlag ? 'mb-2' : 'mb-0'">

                    <div class="alert-hig alert-hig--error mb-4" *ngIf="signatureFlag">
                        <div class="alert-hig__logo"></div>
                        <div class="alert-hig__content">
                            <p>Questa certificazione richiede un file firmato digitalmente</p>
                        </div>
                    </div>

                    <nz-upload nzType="drag" nzListType="picture" [nzFileList]="fileList" nzAccept=".pdf, .p7m"
                        nzLimit="1" [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="{ showRemoveIcon: true}"
                        (nzChange)="handleChange($event)">
                        <img class="mb-3 cloud-img" src="assets/icons/cloud-add.svg" alt="cloud">
                        <div class="ant-upload-text title upload-title">Scegli o trascina un file qui *</div>
                        <div class="ant-upload-hint upload-subtitle">Puoi caricare soltanto un PDF non superiore a 50 MB
                        </div>
                        <button class="button choose-file-btn" nz-button>Scegli un file</button>
                    </nz-upload>
                </div>
                
            </app-pcall-loader>
        </div>

        <div class="ant-modal-body-cta">
            <button nz-button class="btn btn-primary" [disabled]="certificationCompanyForm.invalid"
                (click)="handleUpload()" [nzLoading]="isLoading">Salva</button>
            <button nz-button nzType="default" type="button" (click)="handleCancel()"
                [nzLoading]="isLoading">Chiudi</button>
        </div>
    </form>
</div>
