import { Component } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    hide: boolean = false;
    nzSpanValue: number;

    constructor(private authService : AuthService) {
      this.hide = (this.authService.currentUser?.value?.roles?.['0']?.id == 1);
      this.nzSpanValue = this.hide ? 32 : 17;
    }
}
