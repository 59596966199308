import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, Subject } from "rxjs";
import { FormattedResponse } from "../models/formatted-response.model";

@Injectable({
  providedIn: 'root'
})
export class DocumentazioneService {

  private reloadSubject = new Subject<void>();
  reload$ = this.reloadSubject.asObservable();

  constructor(private http: HttpClient) { }

  updateDataTable() {
      this.reloadSubject.next();
  }

  checkUploadData(call_id: number): Observable<FormattedResponse> {
    return this.http.get<any>(`${environment.ApiURL}/documentazione/checkUploadData/${call_id}`);
  }

  storeDocumentazione(params: any): Observable<FormattedResponse> {
    return this.http.post<FormattedResponse>(`${environment.ApiURL}/documentazione/storeDocumentazione`, params);
  }

  updateDocumentazione(id: any, params: any,): Observable<FormattedResponse> {
    return this.http.patch<FormattedResponse>(`${environment.ApiURL}/documentazione/updateDocumentazione/${id}`, params);
  }

  storeDocumentazioneFacoltativa(params: any): Observable<FormattedResponse> {
    return this.http.post<FormattedResponse>(`${environment.ApiURL}/documentazione/storeDocumentazioneFacoltativa`, params);
  }

  deleteDocumentazioneFacoltativa(id: any): Observable<FormattedResponse> {
    return this.http.delete<FormattedResponse>(`${environment.ApiURL}/documentazione/destroy/${id}`);
  }

}
